import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';
import WeekPicker from '../week/WeekPicker';
import daySelectorStyles from '../styles/Dayselector.module.css';
import styles from './PersonStatistics.module.css';
import meetingAHStyles from '../styles/MeetingAH.module.css';
import selectStyles from '../styles/ThreeWaySelect.module.css';

import { WeekProvider } from '../contexts/WeekContext';
import { odv, log, weekDays, calculateDayTotalHours, calculateDayTotalHoursActual, timeToFloat, odvsw, floatToTime } from '../utils/Utils';
import { getDayAdditionalHourLength, getEffectiveWorkHour } from '../utils/DataCalculator';
import RoundedDiv from '../assets/assets/rounded div/RoundedDiv';
import Modal from '../modal/Modal';
import ModalLight from '../modal/ModalLight';
import { MeetingAndAHAndWH } from './MeetingAndAHAndWH';
import IndividualStatistics from './IndividualStatistics';

const PersonStatistics = () => {
  const { state, dispatch } = useAppContext();
  const { people, weeks, days, departments, selectedPerson, selectedWeekId, selectedWeekNr, workHours, meetings, additionalHours,
          selectedDepartment, selectedDay, selectedStatisticsButton, numberOfWeeks, selectedSchoolStatisticsButton } = state
  

  const [hoveredPersonId, setHoveredPersonId] = useState(null);

  const weekNumbers = Array.from({ length: numberOfWeeks }, (_, i) => i + 1);
  const minWidthPx = '65px'


  const selectDepartment = (departmentId) => {
    dispatch({
      type: 'SELECT_DEPARTMENT',
      payload: { departmentId: departmentId},
      meta: { localOnly: true }
    });
  };

  const selectPerson = (personId) => {
    dispatch({
      type: 'SELECT_PERSON',
      payload: { personId: personId},
      meta: { localOnly: true }
    });
  }

  
  return (
    <div style={{}}>
       <p>Statistik </p>
      
       <div style={{marginLeft:'10px', marginRight:'10px', paddingRight:'30px', display:'flex'}}>
          <div style={{marginTop:'10px'}}>
            <p style={{fontWeight:'400', fontSize:'30px'}}>Avdelning</p>
            {
              odv(departments).map(dept => {
                const selected = selectedDepartment === dept.id
                const color = selected ? '#fff' : ''
                const isHovered = hoveredPersonId === dept.id
                return (
                  <div 
                    onClick={() => selectDepartment(dept.id)} 
                    style={{marginBottom:'10px', cursor: 'pointer'}}
                    className={`${styles.personSelectContainer} ${selected ? styles.selected : ''}`}
                    >
                        <div style={{display: 'flex', alignItems: 'center', textAlign:'center', width:'200px', height:'50px', overflow:'auto'}}>
                          <p style={{color:color, fontSize:'19px', fontWeight:'500', margin:'0', padding:'0'}}>{dept.name}</p>
                        </div>
                  </div>
                  )
                }
              )
            }
          </div>
          <div style={{marginTop:'10px', marginLeft:'20px', paddingRight:'20px', borderRight:'2px solid #c1c1c1',}}>
            <p style={{fontWeight:'400', fontSize:'30px'}}>Person</p>
            {
              odv(people).filter(person => person.departmentId === selectedDepartment).map(person => {
                const peopleObjectsWithSameName = odv(people).filter(pers => pers.name === person.name)
                const selected = state.selectedPerson === person.id
                const color = selected ? '#fff' : ''
                const isHovered = hoveredPersonId === person.id
                
                // color:color,

                return (
                  <div 
                    // onMouseEnter={() => handleMouseEnter(person.id)}
                    // onMouseLeave={handleMouseLeave}
                    onClick={() => selectPerson(person.id)} 
                    // style={{marginBottom:'10px', cursor: 'pointer'}}
                    style={{
                      '--isEven': `${1 % 2 === 0 ? '#f1f1f1' : '#fff'}`,
                    }}
                    className={`${styles.personSelectContainer} ${selected ? styles.selected : ''}`}
                    >
                        <div style={{display: 'flex', alignItems: 'center', textAlign:'center', width:'200px', height:'50px', overflow:'auto'}}>
                          <p style={{ fontSize:'19px', fontWeight:'500', margin:'0', padding:'0'}}>{person.name}</p>
                        </div>
                        
                  </div>
                  )
                }
              )
            }
          </div>
          <div style={{marginLeft:'20px'}}>
            <IndividualStatistics></IndividualStatistics>
          {/* <p style={{fontWeight:'400', fontSize:'30px'}}>Statistik {people[selectedPerson].name}</p>
            <div style={{display:'flex'}}>
              <div>
                <p>Total arbetstid: </p>
              </div>
              <div>
                <p>{}</p>
              </div>
            </div>
            <div style={{display:'flex'}}>
              <div>
                <p>Total mötestid: </p>
              </div>
            </div>
            <div style={{display:'flex'}}>
              <div>
                <p>Total barnfri tid: </p>
              </div>
            </div>


            <div style={{display:'flex', justifyContent:'space-between', marginLeft: `calc(max(${107}px, ${8}%))`}}>
            {
              // width: `calc(max(${107}px, ${200}%))`,
              weekNumbers.map(weekNr => (
                <div style={{ display:'flex', justifyContent:'center', width:'8%', minWidth:minWidthPx,}}>
                  <p>v.{weekNr}</p>
                </div>
              ))
            }
            </div>

            <div>

          
              <div style={{marginTop:'20px'}}>
                <div style={{display:'flex'}}>
                  <div>
                    <p>Arbetstid: </p>
                  </div>
                </div>
                <div style={{display:'flex'}}>
                  <div>
                    <p>Möten: </p>
                  </div>
                </div>
                <div style={{display:'flex'}}>
                  <div>
                    <p>Barnfri tid: </p>
                  </div>
                </div>
              </div>

            </div> */}

          </div>
        </div>
      
    </div>
  )
}

export default PersonStatistics