import React, { useState, useRef, useEffect } from 'react';
import { useAppContext, initialStatee } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';
import selectStyles from '../styles/StyledSelect.module.css';
import { log } from '../utils/Utils';
import { CompressAndEncodeState, DecompressAndDecodeState, CompressState, DecompressState } from '../utils/Compressor';
import axios from 'axios';
// import { encode, decode } from '@msgpack/msgpack';
// import pako from 'pako';
import CryptoJS from 'crypto-js';
import { shortenContext } from '../utils/Compressor';
// import * as msgpack from 'msgpack-lite';
// import lzma from 'lzma/js';
// var my_lzma = require("lzma");
// var my_lzma = require("lzma").LZMA();
// import { compress, decompress } from 'compress-json'

import * as msgpack from 'msgpack-lite';
import pako from 'pako';
import { encode as base64Encode, decode as base64Decode } from 'base64-arraybuffer';

export const sendStateToServer = async (state, userId, token) => {
  try {
    const result = await axios.post('https://eg2ch5owxsfpz66bxdoayeoswe0jqelt.lambda-url.eu-north-1.on.aws/', {
      body:'body',
      action: 'upload',
      userId: userId,
      token: token,
      data: CompressAndEncodeState(state)
    });
    if (result.data['success'] ===  true) {
      // setSuccess("success")
      // dispatch({ type: 'SET_LOGGEDIN', payload: {
      //   loggedIn: true
      // } })
    } else {
      // setSuccess("fail")

    }
  } catch (err) {
    log('Error calling Lambda function: ' + err.message);
    // setResponse(null);
  }
}

const LoginForm = () => {
  const { state, dispatch } = useAppContext();
  const [userId, setUserId] = useState(state.userId);
  const [token, setToken] = useState(state.token);
  const [finaluserId, setfinalUserId] = useState('-');
  const [finaltoken, setfinalToken] = useState('-');
  const [success, setSuccess] = useState('-');
  const userIdRef = useRef(null);
  const tokenRef = useRef(null);

  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const login = async () => {
    // Sends a login reuqest to the lambda. the success in the body means if a user with that token exists or not
    try {
      const result = await axios.post('https://eg2ch5owxsfpz66bxdoayeoswe0jqelt.lambda-url.eu-north-1.on.aws/', {
        body:'body',
        action: 'login',
        userId: finaluserId,
        token: finaltoken
      });
      setResponse(result.data);
      setError(null);
      if (result.data['success'] ===  true) {
        setSuccess("success")
        dispatch({ type: 'SET_LOGGEDIN', payload: {
          loggedIn: true
        } })
      } else {
        setSuccess("fail")

      }
    } catch (err) {
      setError('Error calling Lambda function: ' + err.message);
      setResponse(null);
    }
  };

  const choseOption = async (option) => {
    if (option === 1) {
      try {
        const result = await axios.post('https://eg2ch5owxsfpz66bxdoayeoswe0jqelt.lambda-url.eu-north-1.on.aws/', {
          body:'body',
          action: 'download',
          userId: finaluserId,
          token: finaltoken
        });
        if (result.data['success'] === true) {
          dispatch({ type: 'SET_STATE', payload: {
            state: DecompressAndDecodeState(result.data['data'])
          } })
          dispatch({ type: 'SET_AUTOSYNC', payload: {
            autosync: 'på'
          } })
        }
        // setResponse(result.data);
        // setError(null);
        // setSuccess(result.data['success'])
      } catch (err) {
        setError('Error calling Lambda function: ' + err.message);
        setResponse(null);
      }
    } else {
      try {
        const result = await axios.post('https://eg2ch5owxsfpz66bxdoayeoswe0jqelt.lambda-url.eu-north-1.on.aws/', {
          body:'body',
          action: 'upload',
          userId: finaluserId,
          token: finaltoken,
          data: CompressAndEncodeState(state)
        });
        if (result.data['success'] === true) {
          dispatch({ type: 'SET_AUTOSYNC', payload: {
            autosync: 'på'
          } })
          // dispatch({ type: 'SET_STATE', payload: {
          //   state: result.data['data']
          // } })
        } else {
          setError('Lyckades inte ladda upp');
        }
        // setResponse(result.data);
        // setError(null);
        // setSuccess(result.data['success'])
      } catch (err) {
        setError('Error calling Lambda function: ' + err.message);
        setResponse(null);
      }
    }
    
  }

  useEffect(() => {
    setfinalUserId(state.userId)
    setfinalToken(state.token)
  }, [state.token, state.userId])

  const handleDecode = () => {
    const compressed = Uint8Array.from(atob(userId), c => c.charCodeAt(0));
    const decompressed = pako.inflate(compressed);
    const decoded = new TextDecoder().decode(decompressed);
    const dejsoned = JSON.parse(decoded);
    // navigator.clipboard.writeText(dejsoned)

    const RestructuredState = DecompressState(dejsoned)
    dispatch({ type: 'SET_STATE', payload: {
      state: RestructuredState
    } })

  }


  const handleDeleteData = () => {

  }

  return (
    <div>
      {
        state.loggedIn ? (
          (state.autosync === 'på') ? (
            <div>
              <p style={{fontSize:'24px'}}>Inloggad</p>
              <p>
                Automatisk synkning: {<span style={{ color: state.autosync === 'av' ? 'red' : state.autosync === 'på' ? 'green' : 'black', fontSize:'24px', fontWeight:'500'}}>{state.autosync}</span>}
              </p>
              <br />
              <p>
                Alla ändringar du gör sparas i ditt konto.
              </p>
              <button style={{marginLeft:'0px'}} className={buttonStyles['button-30']} onClick={() => {
                  dispatch({ type: 'SET_LOGGEDIN', payload: {
                    loggedIn: false
                  } })
                  dispatch({ type: 'SET_AUTOSYNC', payload: {
                    autosync: 'av'
                  } })
                }
                }>Logga ut</button>
            </div>
          ) : (
            <div>
              <p style={{fontSize:'24px'}}>Inloggad</p>
              <p>
                Automatisk synkning: {<span style={{ color: state.autosync === 'av' ? 'red' : state.autosync === 'på' ? 'green' : 'black', fontSize:'24px', fontWeight:'500'}}>{state.autosync}</span>}
              </p>
              <button style={{marginLeft:'0px'}} className={buttonStyles['button-30']} onClick={() => {
                  dispatch({ type: 'SET_LOGGEDIN', payload: {
                    loggedIn: false
                  } })
                  dispatch({ type: 'SET_AUTOSYNC', payload: {
                    autosync: 'av'
                  } })
                }
                }
                >Logga ut</button>

              <div>
                <br/>
                <p>Du har nu två val:</p>
                <br/>
                <p>1. Vill du hämta det som finns på kontot och skriva över det som finns lokalt?</p>
                <p>2. Eller vill du ladda upp det du har lokalt till ditt konto?</p>
                <br />
                <p>Efter du har gjort ditt val så sätts automatisk synkning på. Allt du gör lokalt kommer då att sparas i ditt konto.</p>
                <br></br>
              </div>

              <div style={{marginBottom:'0px', marginTop:'00px', display:'flex', alignItems:'center'}}>
                <button style={{marginLeft:'0px', marginRight:'10px', marginTop:'0px'}}className={buttonStyles['button-30']}  onClick={() => choseOption(1)}>1. Hämta från konto</button>
                <button style={{marginLeft:'0px', marginTop:'0px'}}className={buttonStyles['button-30']}  onClick={() => choseOption(2)}>2. Ladda upp till konto</button>
              </div>

              {/* <div>
                {success === 'success' && <div>
                  <br/>
                  <p>Du har nu två val:</p>
                  <br/>
                  <p>1. Vill du hämta det som finns på kontot och skriva över det som finns lokalt?</p>
                  <p>2. Eller vill du ladda upp det du har lokalt till ditt konto?</p>
                  <br />
                  <p>Efter du har gjort ditt val så sätts automatisk synkning på. Allt du gör lokalt kommer att sparas i ditt konto.</p>
                  </div>
                }
              </div>

              {
                success == 'success' && <>
                  <div style={{marginBottom:'0px', marginTop:'00px', display:'flex', alignItems:'center'}}>
                    <button style={{marginLeft:'0px', marginTop:'0px'}}className={buttonStyles['button-30']}  onClick={() => choseOption(1)}>1. Hämta från konto</button>
                    <button style={{marginLeft:'0px', marginTop:'0px'}}className={buttonStyles['button-30']}  onClick={() => choseOption(2)}>2. Ladda upp till konto</button>
                  </div>
                  <br/>
                  <p>
                    Automatisk synkning: {<span style={{ color: state.autosync === 'av' ? 'red' : state.autosync === 'på' ? 'green' : 'black', fontSize:'24px'}}>{state.autosync}</span>}
                  </p>

                </>
              } */}
            </div>
          )


          
        ) : (
          <div>
            <p style={{fontSize:'24px'}}>Logga in för att spara ditt schema</p>
            <br></br>
            <p>Fyll i mejl och lösenord nedan så får du valet att hämta data från ditt konto eller ladda upp det du har lokalt.<br/>
              Alla dina ändringar efter det kommer att sparas automatiskt på ditt konto.</p>
            <br/>

            <div style={{marginBottom:'30px', marginTop:'20px', display:'flex', alignItems:'center'}}>
              <input
                ref={userIdRef}
                className={inputStyles['input-30']}
                value={state.userId}
                onChange={(e) => dispatch({ 
                  type: 'SET_USERID',
                  payload: { userId: e.target.value },
                  meta: { localOnly: true }
                 }) }
                placeholder="Mejl/användarnamn"
                style={{width:'300px', backgroundColor:'#e8e8e8'}}
              />
              {/* <p>{finaluserId}</p> */}
              {/* <button className={buttonStyles['button-30']} onClick={() => dispatch({ type: 'SET_USERID', payload: {
                userId: userIdRef.current.value
              } })}>Ok</button> */}

            </div>
            <div style={{marginBottom:'30px', marginTop:'20px', display:'flex', alignItems:'center'}}>
              <input
                ref={tokenRef}
                className={inputStyles['input-30']}
                value={state.token}
                onChange={(e) => dispatch({ 
                  type: 'SET_TOKEN',
                  payload: { token: e.target.value },
                  meta: { localOnly: true }
                 }) } //setToken(e.target.value)
                placeholder="Lösenord"
                style={{width:'300px', backgroundColor:'#e8e8e8'}}
              />
              {/* <p>{finaltoken}</p>
              <button className={buttonStyles['button-30']} onClick={() => dispatch({ type: 'SET_TOKEN', payload: {
                token: tokenRef.current.value
              } })}>Ok</button> */}

            </div>

            <button style={{marginLeft:'0px', marginTop:'0px'}}className={buttonStyles['button-30']}  onClick={login}>Logga in</button>
            {
              success === 'fail' && <>
                <p>Inloggning misslyckades</p>
              </>
            }
          </div>

          
        )
      }
      {/* <p style={{fontSize:'24px'}}>Logga in för att spara ditt schema</p>
      <br></br>
      <p>Genom att fylla i mejl och access-token så hämtas data från ditt konto och sparas kontinuerligt medan du jobbar.</p>
      <br/>
    
      <div style={{marginBottom:'30px', marginTop:'20px', display:'flex', alignItems:'center'}}>
        <input
          ref={userIdRef}
          className={inputStyles['input-30']}
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          placeholder="Mejl/användarnamn"
          style={{width:'300px', backgroundColor:'#e8e8e8'}}
        />
        <p>{finaluserId}</p>
        <button className={buttonStyles['button-30']} onClick={() => dispatch({ type: 'SET_USERID', payload: {
          userId: userIdRef.current.value
        } })}>Ok</button>

      </div>
      <div style={{marginBottom:'30px', marginTop:'20px', display:'flex', alignItems:'center'}}>
        <input
          ref={tokenRef}
          className={inputStyles['input-30']}
          value={token}
          onChange={(e) => setToken(e.target.value)}
          placeholder="Lösenord"
          style={{width:'300px', backgroundColor:'#e8e8e8'}}
        />
        <p>{finaltoken}</p>
        <button className={buttonStyles['button-30']} onClick={() => dispatch({ type: 'SET_TOKEN', payload: {
          token: tokenRef.current.value
        } })}>Ok</button>

      </div>
      <div style={{marginBottom:'0px', marginTop:'00px', display:'flex', alignItems:'center'}}>
      <button style={{marginLeft:'0px', marginTop:'0px'}}className={buttonStyles['button-30']}  onClick={login}>Logga in</button>
        {error && <div>Error: {error}</div>}
        {success === 'success' && <div>
          <p>Inloggning lyckades!</p>
          </div>
        }
        {success === 'fail' && <div>
          <p>Kunde inte hitta en användare med det lösenordet, försök igen</p>
          </div>
        }
      </div>

    <div>
      
      {success === 'success' && <div>
        <br/>
        <p>Du har nu två val:</p>
        <br/>
        <p>1. Vill du hämta det som finns på kontot och skriva över det som finns lokalt?</p>
        <p>2. Eller vill du ladda upp det du har lokalt till ditt konto?</p>
        <br />
        <p>Efter du har gjort ditt val så sätts automatisk synkning på. Allt du gör lokalt kommer att sparas i ditt konto.</p>
        </div>
      }
    </div>

    {
      success == 'success' && <>
        <div style={{marginBottom:'0px', marginTop:'00px', display:'flex', alignItems:'center'}}>
          <button style={{marginLeft:'0px', marginTop:'0px'}}className={buttonStyles['button-30']}  onClick={() => choseOption(1)}>1. Hämta från konto</button>
          <button style={{marginLeft:'0px', marginTop:'0px'}}className={buttonStyles['button-30']}  onClick={() => choseOption(2)}>2. Ladda upp till konto</button>
        </div>
        <br/>
        <p>
          Automatisk synkning: {<span style={{ color: state.autosync === 'av' ? 'red' : state.autosync === 'på' ? 'green' : 'black', fontSize:'24px'}}>{state.autosync}</span>}
        </p>

      </>
    } */}

    </div>
  )
}

export default LoginForm