import DepartmentPicker from "../department/DepartmentPicker"
import { useState } from "react";
import AddDepartmentButton from "../people/AddDepartmentButton"
import { useAppContext } from '../contexts/AppContext';
import styles from '../styles/PeopleOverview.module.css';
import selectStyles from '../styles/ThreeWaySelect.module.css';
import daySelectorStyles from '../styles/Dayselector.module.css';
import meetingAHStyles from '../styles/MeetingAH.module.css';
import addMeetingAHButtonStyles from '../styles/AddMeetingAHButton.module.css';
import { odv, log, odvs, timeToFloat, floatToTime, calculateDayTotalHours, weekDays, threeLetterWeekDays, findPeopleWithSameName } from "../utils/Utils";
import AddPersonButton from "../people/AddPersonButton";
import RoundedDiv from "../assets/assets/rounded div/RoundedDiv";
// import WeekSelector from "./WeekSelector";
import Week from "../week/Week";
import { WeekProvider } from "../contexts/WeekContext";
import AddMeetingAHButton from "./AddMeetingAHButton";

export const MeetingAndAHMinimal = ({type, selectedOption, setSelectedOption}) => {
  const { state, dispatch } = useAppContext();
  const { people, weeks, days, departments, selectedPerson, selectedWeekId, selectedWeekNr, workHours, meetings, additionalHours,
          selectedDepartment, selectedDay, selectedButton, 
   } = state
  const relevantDays = odv(days).filter(day => day.weekId === selectedWeekId)
  // const [selectedOption, setSelectedOption] = useState(2);

  
  const selected = selectedButton === type

  const isMeeting = state.selectedButton === 'Möten'
  const isAH = state.selectedButton === 'Barnfri tid'
  const titleWording = isMeeting ? 'möten' : (isAH ? 'barnfri tid' : '')

  return (
    <div style={{display:'flex', flexDirection:'column'}}>
     {/* <p>{type}</p>  */}
      {/* <div style={{display:'flex', justifyContent:'center', margin:'10px'}}>
        <div style={{display:'flex', alignItems:'center', marginRight:'10px'}}>
          <p>Visar {titleWording} under v.{selectedWeekNr} för: </p>
        </div>
        <div style={{display:'flex', flexDirection: 'row'}}>
          <div className={`${selectStyles.selectOption}
                           ${selectedOption === 1 ? selectStyles.selected : ''}
                           ${selectStyles.leftItem}`}
                onClick={() => setSelectedOption(1)}>
            <p>Alla avdelningar</p>
          </div>
          <div className={`${selectStyles.selectOption}
                           ${selectedOption === 2 ? selectStyles.selected : ''}`}
                onClick={() => setSelectedOption(2)}>
            <p>{departments[selectedDepartment]?.name}</p>
          </div>
          <div className={`${selectStyles.selectOption}
                           ${selectedOption === 3 ? selectStyles.selected : ''}
                           ${selectStyles.rightItem}`}
                onClick={() => setSelectedOption(3)}>
            <p>{people[selectedPerson]?.name}</p>
          </div>
        </div>
      </div> */}
      <div style={{borderTop:'1.5px solid #ccc',}}>
        {/* <div style={{display:'flex', justifyContent:'space-between'}}>
          {
            Object.keys(weekDays).map(weekDay => {
              const a = 1
              return (
                <div style={{display:'flex', flexDirection:'column', width:'20%', alignItems:'center'}}>
                  {weekDay}
                </div>
                )
              })
          }
        </div> */}
        <div style={{display:'flex', justifyContent:'space-between', overflowY:'auto',  marginTop:'0px'}}>
          {/* height:'264px', */}
          {
            Object.values(weekDays).filter(weekDay => weekDay === state.selectedDay).map(weekDay => {
              // log("weekday: ", weekDay, )
              let foundObjects = {}
              if (type === 'Möten') {
                switch (selectedOption) {
                  case 1: // Alla avdelningar
                    log("case 1a")
                    odv(meetings).forEach(meeting => {
                      // log("meeting is: ", meeting)
                      if (meeting.weekNr === selectedWeekNr && meeting.weekDay === weekDay) {
                        foundObjects[meeting.id] = meeting
                      }
                    })
                    break
                  case 2: // Kotten
                    log("case 2a")
                    // Take every person in this department, and get people objects with same name, and go over each people object and check if they are part of a meeting

                    odv(people).filter(person => person.departmentId === selectedDepartment).forEach(departmentPerson => {
                      const peopleObjectsWithSameName = findPeopleWithSameName(state, departmentPerson)

                      odv(meetings).forEach(meeting => {
                        if (meeting.weekNr === selectedWeekNr && meeting.weekDay === weekDay) {
                          odv(peopleObjectsWithSameName).forEach(pO => {
                            if (meeting.peopleIds.includes(pO.id)){
                              if (!foundObjects[meeting.id]) {
                                foundObjects[meeting.id] = meeting
    
                              }
                            }
                          })
                        }
                      })
                    })

                    break
                  case 3: // Agnes
                    log("case 3a")
                    const peopleObjectsWithSameNamee = findPeopleWithSameName(state, people[selectedPerson])

                    odv(meetings).forEach(meeting => {
                      // if (meeting.weekNr === selectedWeekNr && meeting.peopleIds.includes(selectedPerson) && meeting.weekDay === weekDay) {
                      //   foundObjects[meeting.id] = meeting
                      // }
                      if (meeting.weekNr === selectedWeekNr && meeting.weekDay === weekDay) {
                        odv(peopleObjectsWithSameNamee).forEach(pO => {
                          if (meeting.peopleIds.includes(pO.id)){
                            if (!foundObjects[meeting.id]) {
                              foundObjects[meeting.id] = meeting
  
                            }
                          }
                        })
                      }
                    })
                    break
                }
              } else if (type === 'Barnfri tid'){
                switch (selectedOption) {
                  case 1: // Alla avdelningar
                    log("case 1b")
                    odv(additionalHours).forEach(ah => {
                      const day = days[ah.dayId]
                      if (day.weekDay === weekDay 
                          && weeks[day.weekId].weekNr === selectedWeekNr) {
                        foundObjects[ah.id] = ah
                      }
                    })
                    break
                  case 2: // Kotten

                    odv(people).filter(person => person.departmentId === selectedDepartment).forEach(departmentPerson => {
                      const peopleObjectsWithSameName = findPeopleWithSameName(state, departmentPerson)

                      odv(additionalHours).forEach(ah => {
                        const day = days[ah.dayId]

                        odv(peopleObjectsWithSameName).forEach(pO => {
                          if (day.weekDay === weekDay 
                            && weeks[day.weekId].weekNr === selectedWeekNr
                            && weeks[day.weekId].personId === pO.id) {
                              foundObjects[ah.id] = ah
                          }
                        })


                        // if (day.weekDay === weekDay 
                        //     && weeks[day.weekId].weekNr === selectedWeekNr
                        //     && people[weeks[day.weekId].personId].departmentId === selectedDepartment) {
                        //   foundObjects[ah.id] = ah
                        // }
                      })
                    })

                    log("case 2b")
                    odv(additionalHours).forEach(ah => {
                      const day = days[ah.dayId]
                      if (day.weekDay === weekDay 
                          && weeks[day.weekId].weekNr === selectedWeekNr
                          && people[weeks[day.weekId].personId].departmentId === selectedDepartment) {
                        foundObjects[ah.id] = ah
                      }
                    })
                    break
                  case 3: // Agnes
                    log("case 3b")
                    odv(additionalHours).forEach(ah => {
                      const day = days[ah.dayId]
                      if (day.weekDay === weekDay 
                          && weeks[day.weekId].weekNr === selectedWeekNr
                          && weeks[day.weekId].personId === selectedPerson) {
                        foundObjects[ah.id] = ah
                      }
                    })

                    const peopleObjectsWithSameNamee = findPeopleWithSameName(state, people[selectedPerson])
                    const peopleIDsWithSameName = peopleObjectsWithSameNamee.map(person => person.id)

                    odv(additionalHours).forEach(ah => {
                      const day = days[ah.dayId]
                      if (day.weekDay === weekDay 
                          && weeks[day.weekId].weekNr === selectedWeekNr
                          && peopleIDsWithSameName.includes(weeks[day.weekId].personId)) {
                        foundObjects[ah.id] = ah
                      }
                    })

                    // odv(additionalHours).forEach(ah => {


                    //   if (meeting.weekNr === selectedWeekNr && meeting.weekDay === weekDay) {
                    //     odv(peopleObjectsWithSameNamee).forEach(pO => {
                    //       if (meeting.peopleIds.includes(pO.id)){
                    //         if (!foundObjects[meeting.id]) {
                    //           foundObjects[meeting.id] = meeting
  
                    //         }
                    //       }
                    //     })
                    //   }
                    // })

                    break
                }
              }

              // borderLeft:'1px solid #eee', borderRight:'1px solid #eee',
              return ( 
                <div style={{display:'flex', flexDirection:'row',justifyContent:'center', }}
                className={meetingAHStyles.dayColumn}
                >
                  {/* <div onClick={() => {dispatch({
                      type: 'SELECT_DAY',
                      payload: { day: weekDay}
                    })}}
                    style={{marginBottom:'5px'}}
                    className={`${meetingAHStyles.dayContainer} ${weekDay === selectedDay ? meetingAHStyles.dayContainerSelected : ''}`}>
                      {threeLetterWeekDays[weekDay]}
                  </div> */}
                  {
                    odvs(foundObjects).map(object => {
                      if (type === 'Möten') {
                        return (
                          <div onClick={() => {
                            dispatch({ type: 'SET_PRESSED_DAY_BUTTON', payload: { newValue: weekDay } });
                            dispatch({ type: 'TOGGLE_SHOW_MEETINGAH_POPUP', payload: { setToShow: true, objectId: object.id } });
                            dispatch({
                              type: 'SELECT_BUTTON',
                              payload: { button: 'Möten'}
                            })}}
                            className={meetingAHStyles.container}>
                              {/* <p>{object.weekDay}</p> */}
                              {/* <p style={{fontWeight:'500'}}>Möte {threeLetterWeekDays[object.weekDay]}</p> */}
                              <p style={{fontWeight:'500'}}>{object.time} - {floatToTime(timeToFloat(object.time) + timeToFloat(object.length))}</p>
                              {
                                object.peopleIds.map(personId => (
                                  <div>
                                    <p>- {people[personId].name}</p>
                                  </div>
                                ))
                              }
                          </div>
                        )
                      } else {
                        return(
                          <div className={meetingAHStyles.container}
                          onClick={() => {
                            dispatch({ type: 'SET_PRESSED_DAY_BUTTON', payload: { newValue: weekDay } });
                            dispatch({ type: 'TOGGLE_SHOW_MEETINGAH_POPUP', payload: { setToShow: true, objectId: object.id} });
                            dispatch({
                              type: 'SELECT_BUTTON',
                              payload: { button: 'Barnfri tid'}
                            })}}>
                              <p style={{fontWeight:'500'}}>{object.category}</p>
                              <p>{people[weeks[days[object.dayId].weekId].personId].name}</p>
                              <p >{object.start} - {floatToTime(timeToFloat(object.start) + timeToFloat(object.length))}</p>
                          </div>
                        )
                      }
                      
                    })
                  }
                  <div>

                  <AddMeetingAHButton type={type}></AddMeetingAHButton>

                    {/* <button 
                      className={addMeetingAHButtonStyles.addButton}
                      title={`Lägg till ${type === 'Möten' ? 'möte' : 'barnfri tid'}`} 
                      onClick={() => {
                                      dispatch({ type: 'SET_PRESSED_DAY_BUTTON', payload: { newValue: weekDay } });
                                      dispatch({ type: 'TOGGLE_SHOW_ADD_MEETINGAH', payload: { setToShow: true } });
                                      }}
                      // style={{border:'0px', marginTop:'10px', marginBottom:'20px', backgroundColor:'#abeb96', borderRadius:'20px', fontSize:'20px', paddingLeft:'10px', paddingRight:'10px', paddingTop:'2px', paddingBottom:'2px'}}>
                    >
                      <div style={{display:'flex', alignItems:'center'}}>
                        <svg width="18" height="18" viewBox="0 0 24 24" id="_24x24_On_Light_Plus" data-name="24x24/On Light/Plus" xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000">
                          <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                          <g id="SVGRepo_iconCarrier"> <rect id="view-box" width="24" height="24" fill="#141124" opacity="0"/> <path id="Shape" d="M5,10.75V6.5H.75A.75.75,0,0,1,.75,5H5V.75a.75.75,0,0,1,1.5,0V5h4.25a.75.75,0,1,1,0,1.5H6.5v4.25a.75.75,0,1,1-1.5,0Z" transform="translate(6 6)" fill="#141124"/> </g>
                        </svg>
                      </div>
                    </button> */}
{/* 
                    <button 
                      className={addMeetingAHButtonStyles.addButton}
                      title={`Lägg till ${type === 'Möten' ? 'möte' : 'barnfri tid'}`} 
                      onClick={() => {
                                      dispatch({ type: 'SET_PRESSED_DAY_BUTTON', payload: { newValue: weekDay } });
                                      dispatch({ type: 'TOGGLE_SHOW_ADD_MEETINGAH', payload: { setToShow: true } });
                                      }}
                      // style={{border:'0px', marginTop:'10px', marginBottom:'20px', backgroundColor:'#abeb96', borderRadius:'20px', fontSize:'20px', paddingLeft:'10px', paddingRight:'10px', paddingTop:'2px', paddingBottom:'2px'}}>
                    >
                      +
                    </button> */}
                  </div>
                </div>
                )
              })
          }
        </div>

      </div>
    </div>
  )
}
