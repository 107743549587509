import { timeToFloat, weekDays, log } from "./Utils";

export function calculateNewTimes(start, end, period1Start, period1Length, period2Start, period2Length) {
  const period1End = period1Start + period1Length;
  const period2End = period2Start + period2Length;

  let intervals = [{ start, end }];

  // Subtract first period
  intervals = intervals.reduce((acc, interval) => {
    if (period1Start < interval.end && period1End > interval.start) {
      if (interval.start < period1Start) {
        acc.push({ start: interval.start, end: period1Start });
      }
      if (interval.end > period1End) {
        acc.push({ start: period1End, end: interval.end });
      }
    } else {
      acc.push(interval);
    }
    return acc;
  }, []);

  // Subtract second period
  intervals = intervals.reduce((acc, interval) => {
    if (period2Start < interval.end && period2End > interval.start) {
      if (interval.start < period2Start) {
        acc.push({ start: interval.start, end: period2Start });
      }
      if (interval.end > period2End) {
        acc.push({ start: period2End, end: interval.end });
      }
    } else {
      acc.push(interval);
    }
    return acc;
  }, []);

  return intervals;
}

export function calculateOverlap(start, end, secondStart, secondLength) {
  const secondEnd = secondStart + secondLength;

  // Calculate how much of the break is within work hours
  const overlapStart = Math.max(secondStart, start);
  const overlapEnd = Math.min(secondEnd, end);
  const overlap = Math.max(0, overlapEnd - overlapStart);

  // Calculate how much of the break is outside work hours
  const outsideOverlap = secondLength - overlap;

  return {
    within: overlap,
    outside: outsideOverlap
  };
}

export const getDayAdditionalHourLength = (state, dayId) => {
  const { workHours, breakHours, additionalHours, putHours, meetings, days, people, weeks } = state

  const relevantAdditionalHours = Object.values(additionalHours).filter(aH => aH.dayId === dayId)

  if (relevantAdditionalHours && relevantAdditionalHours.length >= 1) {
    if (relevantAdditionalHours.length > 1) { // If theres multiple AH, return the sum
      let sum = 0
      relevantAdditionalHours.forEach(aH => {
        sum += timeToFloat(aH.length)
      })
      return `${sum}h`
    } else { // If theres only one return length + name
      return `${timeToFloat(relevantAdditionalHours[0].length)}h (${relevantAdditionalHours[0].category})`
    }
  } 
}

export const getEffectiveWorkHour = ( state, dayId ) => {
  const { workHours, breakHours, putHours, meetings, days, people, weeks } = state
  const workHourStart = timeToFloat(Object.values(workHours).find(workHour => workHour.dayId === dayId && workHour.type === 'start')?.time)
  const workHourEnd = timeToFloat(Object.values(workHours).find(workHour => workHour.dayId === dayId && workHour.type === 'end')?.time)

  const breakStart = timeToFloat(Object.values(breakHours).find(breakHour => breakHour.dayId === dayId && breakHour.type === 'start')?.time || 0);
  const breakLength = timeToFloat(Object.values(breakHours).find(breakHour => breakHour.dayId === dayId && breakHour.type === 'length')?.time || 0);
  
  // const putHourStart = timeToFloat(Object.values(putHours).find(putHour => putHour.dayId === dayId && putHour.type === 'start')?.time || 0);
  // const putHourLength = timeToFloat(Object.values(putHours).find(putHour => putHour.dayId === dayId && putHour.type === 'length')?.time || 0);

  const day = days[dayId]
  const week = Object.values(weeks).find(week => week.id === day.weekId)
  const personId = Object.values(people).find(person => person.id === week.personId).id

  const relevantMeetings = Object.values(meetings).filter(meeting => 
    meeting.weekNr === week.weekNr &&
    meeting.weekDay === day.weekDay // I think this version is correct.. but did not notice any bugs with version below..
    // weekDays[meeting.weekDay] === day.weekDay
     && meeting.peopleIds.includes(personId))

  if (!(workHourEnd && workHourStart)) {
    return 0 // This should fix the bug where if you only enter a start time then you get negative times back
  }
  let workHoursLength = workHourEnd - workHourStart;
  let effectiveBreakLength = 0;
  let effectivePutHours = 0;

  let effectiveWorkLength = workHoursLength - breakLength

  const putOutsideOfWorkHours = calculateOverlap(workHourStart, workHourEnd, 0, 0).outside

  effectiveWorkLength += putOutsideOfWorkHours

  relevantMeetings.forEach(meeting => effectiveWorkLength += calculateOverlap(workHourStart, workHourEnd, timeToFloat(meeting.time), timeToFloat(meeting.length)).outside)
  
  log("effe len", effectiveWorkLength)
  return effectiveWorkLength
}

// export const getPutHour = (state, dayId) => {
//   // const putHourLength = timeToFloat(Object.values(state.putHours).find(putHour => putHour.dayId === dayId && putHour.type === 'length')?.time);

//   const putHours = Object.values(state.additionalHours).filter(aH => aH.dayId === dayId && aH.category === 'PUT')
//   let totalPH = 0
//   putHours.forEach(pH => totalPH = totalPH + timeToFloat(pH.length))
//   return totalPH
// }