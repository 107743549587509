import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';
import { odv,log } from '../utils/Utils';

const RemoveAdditionalHourForm = () => {
  const { state, dispatch } = useAppContext();
  const {additionalHours, days, weeks, people, selectedDay, removeAdditionalHourId, selectedPerson} = state
  const [selectedPersonId, setSelectedPersonId] = useState(weeks[days[additionalHours[removeAdditionalHourId]?.dayId]?.weekId]?.personId || selectedPerson)

  const weekDaySettings = state.weekDaySettings || {
    'Måndag': true,
    'Tisdag': true,
    'Onsdag': true,
    'Torsdag': true,
    'Fredag': true,
  }
  
  const daySetting = weekDaySettings[selectedDay]


  const findAndRemoveAH = () => {
    log(1)
    if (daySetting) {
      const relevantDays = odv(days).filter(day => weeks[day.weekId].personId === selectedPersonId && day.weekDay === selectedDay)

      odv(relevantDays).forEach(day => {
        // Remove old AH
        const editedAH = additionalHours[removeAdditionalHourId]

        const relevantOldAH = odv(additionalHours).find(
            aH => aH.dayId === day.id 
          && aH.start === editedAH.start 
          && aH.length === editedAH.length 
          && aH.category === editedAH.category )
          
        if (relevantOldAH){
          dispatch({
            type: 'REMOVE_ADDITIONAL_HOUR',
            payload: {
            additionalHourId: relevantOldAH.id}
          })
        }

      })

      

    } else {
      dispatch({ type: 'REMOVE_ADDITIONAL_HOUR', payload: {
        additionalHourId: state.removeAdditionalHourId,
      } });
    }
    

  }

  return (
    <div>
      <p style={{fontSize:'24px'}}>Ta bort tid utanför barngrupp?</p>
      <br></br>
      <div style={{display:'flex', justifyContent:'right'}}>
        <button className={buttonStyles['secondaryButton']} style={{marginLeft:'0px'}}onClick={() => {dispatch({
                                    type: 'TOGGLE_SHOW_REMOVE_ADDITIONAL_HOUR', payload: {
                                    setToShow: false,
                                    }
                                  }) }}>
            Avbryt
          </button>
        <button 
          className={buttonStyles['button-30']}
          onClick={() => {findAndRemoveAH()
            
                                dispatch({ type: 'TOGGLE_SHOW_MEETINGAH_POPUP', payload: {
                                  setToShow: false
                                }});
                                dispatch({
                                  type: 'TOGGLE_SHOW_REMOVE_ADDITIONAL_HOUR', payload: {
                                  setToShow: false,
                                  }
                                });
                                
                                }}>
          Ta bort
        </button>
      </div>
    </div>
  )
}

export default RemoveAdditionalHourForm