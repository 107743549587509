import React, { useRef, useState
 } from 'react';
import styles from './LinkDisplay.module.css'; // Importing CSS module

const LinkDisplay = ({ link }) => {
  const linkRef = useRef(null);
  const [copyDisplay, setCopyDisplay] = useState('Kopiera')

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    setCopyDisplay('Kopierat!')
  };

  return (
    <div className={styles.linkContainer}>
      <a href={link} ref={linkRef} className={styles.publicLink} target="_blank" rel="noopener noreferrer">
        {link}
      </a>
      <button className={styles.copyButton} onClick={copyToClipboard}>
        {copyDisplay}
      </button>
    </div>
  );
};

export default LinkDisplay;
