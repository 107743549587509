import React, { useState, useEffect } from 'react';
import { useWeekContext } from '../contexts/WeekContext';
import { useDayContext } from '../contexts/DayContext';
import inputStyles from '../styles/StyledInput.module.css';
import { validateAndFormatTime, getTextColor } from '../utils/Utils';
import hoursStyles from '../styles/Hours.module.css'
import buttonStyles from '../styles/StyledButton.module.css';
import { log, autofillThirty, odv, timeToFloat, floatToTime } from '../utils/Utils';
import { useAppContext } from '../contexts/AppContext';

const WorkHourEditor = () => {
  const { state, dispatch } = useAppContext(); 
  const {days, weeks, people, workHours, breakHours, selectedPerson, selectedWeekId } = state
  const dayId = state.editWorkHourDayId
  const [startTime, setStartTime] = useState(odv(workHours).find(workHour => (
    workHour.dayId === dayId && workHour.type === 'start'))?.time)

  const [endTime, setEndTime] = useState(odv(workHours).find(workHour => (
    workHour.dayId === dayId && workHour.type === 'end'))?.time)
    

  const [breakStartTime, setBreakStartTime] = useState(Object.values(state.breakHours).find(breakHour => (
    breakHour.dayId === dayId && breakHour.type === 'start'))?.time);
  const [breakLength, setBreakLength] = useState(Object.values(state.breakHours).find(breakHour => (
    breakHour.dayId === dayId && breakHour.type === 'length'))?.time);

  useEffect(() => {
    const foundStartTime = Object.values(state.workHours).find(workHour => (workHour.dayId === dayId && workHour.type === 'start'))?.time || null
    const foundEnd = Object.values(state.workHours).find(workHour => (workHour.dayId === dayId && workHour.type === 'end'))?.time || null
    
    setStartTime(foundStartTime);
    setEndTime(foundEnd);
  }, [dayId, state.workHours, state.selectedPerson, state.selectedWeekId]);

  useEffect(() => {
    const foundStartTime = Object.values(state.breakHours).find(breakHour => (breakHour.dayId === dayId && breakHour.type === 'start'))?.time || null
    const foundBreakLength = Object.values(state.breakHours).find(breakHour => (breakHour.dayId === dayId && breakHour.type === 'length'))?.time || null
    
    setBreakStartTime(foundStartTime);
    setBreakLength(foundBreakLength);
  }, [dayId, state.breakHours]);

  const handleKeyDown = (event, type) => {
    if (event.key === 'Enter' || event.type === 'blur') {
      const formattedTime = validateAndFormatTime(event.target.value);
      if (formattedTime) {
        if (type === 'start') {
          // setFinalStartTime(formattedTime)
          dispatch({ type: 'SET_WORK_HOUR_TIME', payload: {time: formattedTime, dayId: dayId, type: type} });
        } else {
          // setFinalEndTime(formattedTime)
          dispatch({ type: 'SET_WORK_HOUR_TIME', payload: {time: formattedTime, dayId: dayId, type: type} });
        }
        // autofillThirty(state, dispatch, dayId)
      } else {
        let newTime = null
        if (type === 'start') {
          // setFinalStartTime(newTime)
          dispatch({ type: 'SET_WORK_HOUR_TIME', payload: {time: newTime, dayId: dayId, type: type} });
        } else {
          // setFinalEndTime(newTime)
          dispatch({ type: 'SET_WORK_HOUR_TIME', payload: {time: newTime, dayId: dayId, type: type} });
        }
      }
    }
  };

  const handleKeyDownBreak = (event, type) => {
    if (event.key === 'Enter' || event.type === 'blur') {
      const formattedTime = validateAndFormatTime(event.target.value);
      log("formatted time is: ", formattedTime)
      if (formattedTime) {
        // if (type === 'start'){
        //   setFinalStartTime(formattedTime)
        // } else {
        //   setFinalLength(formattedTime)
        // }
        dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: formattedTime, dayId: dayId, type: type} });
      } else {
        let newTime = null
        // if (type === 'start'){
        //   setFinalStartTime(newTime)
        // } else {
        //   setFinalLength(newTime)
        // }
        dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: newTime, dayId: dayId, type: type} });
      }
    }
  };


  const day = days[dayId]
  const week = weeks[day.weekId]
  const person = people[week.personId]

  const workHourStart = odv(workHours).find(wH => (
    wH.dayId === dayId && wH.type === 'start'))
  const workHourEnd = odv(workHours).find(wH => (
    wH.dayId === dayId && wH.type === 'end'))

  const breakHourStart = odv(breakHours).find(bH => (
    bH.dayId === dayId && bH.type === 'start'))
  const breakHourLength = odv(breakHours).find(bH => (
    bH.dayId === dayId && bH.type === 'length'))

  const titleWording = (workHourStart?.time === null && workHourEnd?.time === null && breakHourStart?.time === null && breakHourLength?.time === null) ? 'Skapa arbetstid för ' : 'Redigera arbetstid för ' 

  let dayLength = 0

  if (workHourStart?.time && workHourEnd?.time){
    dayLength = timeToFloat(workHourEnd.time) - timeToFloat(workHourStart.time)
  }

  let dayLengthActual = 0

  if (breakHourLength?.time) {
    dayLengthActual = dayLength - timeToFloat(breakHourLength.time)
  }


  return (
    <div>
      {/* <p style={{fontSize:'1.3em', marginBottom:'10px'}}>{titleWording} {person.name} på {day.weekDay} v.{week.weekNr}</p> */}
      <p style={{fontSize:'1.3em', marginBottom:'10px'}}>Redigera arbetstid {person.name} {day.weekDay} v.{week.weekNr}</p>
      <p>Arbetstid</p>
      <div className={hoursStyles.flexContainer} style={{marginLeft:'0px', marginTop:'5px'}}>
        {/* <p style={{fontWeight:'500'}}>Arbetstid</p> */}
        <div style={{backgroundColor:'#bbb', borderRadius:'25px', 
        }}>
          <input
            title='Använd formatet hh.mm, hh:mm eller h'
            className={inputStyles['input-30']}
            value={startTime}
            onChange={e => setStartTime(e.target.value)}
            onKeyDown={e => handleKeyDown(e, 'start')}
            onBlur={(e) => handleKeyDown(e, 'start')}
            placeholder="Start"
          />
          {/* <p style={{ color: getTextColor(finalStartTime), width:'65px', marginRight:'00px' }}>{finalStartTime}</p> */}
          <input
            title='Använd formatet hh.mm, hh:mm eller h'
            className={inputStyles['input-30']}
            value={endTime}
            onChange={e => setEndTime(e.target.value)}
            onKeyDown={e => handleKeyDown(e, 'end')}
            onBlur={(e) => handleKeyDown(e, 'end')}
            style={{marginLeft:'10px'}}
            placeholder="Slut"
          />
        </div>

        {
          dayLength > 0 && dayLengthActual === 0 && <div style={{marginLeft:'10px'}}>
              <p style={{fontWeight:'500'}}> = {dayLength}h</p>
            </div>
        }
        {/* {
          dayLengthActual > 0 && <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginLeft:'10px'}}>
              <p> = {dayLengthActual}h inkl. rast</p>
            </div>
        } */}
      </div>
      <p style={{marginTop:'10px'}}>Rast</p>
      <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'5px'}}>

        <div className={hoursStyles.flexContainer} style={{marginLeft:'0px', }}> {/*style={{borderRight: '1.5px solid #000'}}*/}
          {/* <p style={{fontWeight:'500'}}>Rast</p> */}
          <div style={{backgroundColor:'#bbb', borderRadius:'25px'}}>

            <input
              title='Använd formatet hh.mm, hh:mm eller h'
              className={inputStyles['input-31']}
              value={breakStartTime}
              onChange={e => setBreakStartTime(e.target.value)}
              onKeyDown={e => handleKeyDownBreak(e, 'start')}
              onBlur={(e) => handleKeyDownBreak(e, 'start')}
              placeholder="(Start)"
            />
            {/* <p style={{ color: getTextColor(finalStartTime), width:'65px', marginRight:'00px' }}>{finalStartTime}</p> */}
            <input
              title='Använd formatet hh.mm, hh:mm eller h'
              className={inputStyles['input-30']}
              value={breakLength}
              onChange={e => setBreakLength(e.target.value)}
              onKeyDown={e => handleKeyDownBreak(e, 'length')}
              onBlur={(e) => handleKeyDownBreak(e, 'length')}
              placeholder="Längd"
              style={{marginLeft:'10px'}}
            />
          </div>
          {
              dayLengthActual > 0 && <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginLeft:'10px'}}>
                  {/* <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 15H9.4A4.4 4.4 0 0 1 5 10.6V5" stroke="#000000" strokeWidth="1.5" stroke-miterlimit="10" stroke-linecap="round"/><path d="m15 19 4-4-4-4" stroke="#000000" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg> */}
                <div style={{}}>


                
                  <p style={{fontWeight:'500'}}>= {dayLengthActual}h inkl. rast</p>
                </div>
            </div>
          }
          {/* <p style={{ color: getTextColor(finalLength), paddingRight: '00px', width:'65px', marginRight:'00px' }}>{finalLength}</p> */}
      
    
            
          </div>
          <div style={{display:'flex', justifyContent:'right'}}>
              <button className={buttonStyles['secondaryButton']} onClick={() => dispatch({ type: 'TOGGLE_SHOW_WORKHOUR_EDITOR', payload: {
                  setToShow: false
                } })}>Stäng</button>
                {/* <button className={buttonStyles['button-30']} onClick={addMeetingAH}>Stäng</button> */}
            </div>
      </div>
    </div>
  );
};

export default WorkHourEditor;
