// byt ut uuids mot ints
// ta bort id: uuid från alla objekt och reparera objektet sen vid rekonstruktion
// gruppera alla objekt under samma dag. basically gör ett träd av hela strukturen? det kan du ju göra. då blir det riktigt jävla kompakt för alla weekId försvinner
// gör om tider till ints
// överallt där du har start: 1, length: 2, kör bara såhär stlength: [1,2]. Så kan du göra med varenda jävla value.
// Så hela skiten blir bara en lista av listor av listor av listor xd HAHAH
// tex [[dept1, dept2, dept3], ]
// [dept] = [[person1, person2, person3]]
// 

// "a7c1d577-cfa2-4c36-9b7f-f79dde268dc3":{"id":"a7c1d577-cfa2-4c36-9b7f-f79dde268dc3","dayId":"bcc2b988-fb38-459c-ad71-105368592c41","type":"end","time":"15:30"} 
// blir [uuid, dayid, type, time] 
// uuid = 8
// dayid = skippa för det ligger redan under en dag i hierarkin
// type = 1
// time = 7.5

// detta behöver inte ens va en lista man kan bara slå ihop värdena: [uuid, dayid, type, time]  = 08175
// så varenda objekt blir bara [08175, 08175, 08175, 08175, 08175, 08175, 08175, 08175, 08175, 08175]
// i en gigantisk hierarki. men du måste anteckna vad du gör extremt nogrant XD

// [223232, 222232, 23231, 23232, 23232, 12312]

// varje vecka har 5 dagar alltid, så kanske kör bara att weekId: [2123312312123123123123] fast idk då får man ingen hierarki
// frågan är om man ska köra hierarki eller behålla nu med listor av objekt bara

// [[id, namn, personer], [id, namn, personer[[id, namn, veckor[[id, namn, dagar[[id, namn, wh[[id,start,type]], bh[...] ]]]]]]]]

import { log } from "./Utils"
import pako from 'pako';


export const CompressAndEncodeState = (state) => {
  const stateCopy = state
  stateCopy.showPublicize = false
  log("statecopy is: ", stateCopy)
  const encoded = new TextEncoder().encode(JSON.stringify(CompressState(stateCopy)));
  const compressed = pako.deflate(encoded);
  const base64 = btoa(String.fromCharCode.apply(null, compressed));
  return(base64)
}

export const DecompressAndDecodeState = (stateString) => {
  const compressed = Uint8Array.from(atob(stateString), c => c.charCodeAt(0));
  const decompressed = pako.inflate(compressed);
  const decoded = new TextDecoder().decode(decompressed);
  const dejsoned = JSON.parse(decoded);
  const RestructuredState = DecompressState(dejsoned)
  return RestructuredState
}

export const CompressState = (state) => {
  // log("state is:", state)
  // log("shortened keys: ", shortenKeys(state))
  // log("transformed is: ", transformDictionary(shortenKeys(state), "/"))
  // log("reversed to its original form is: ", DecompressState(transformDictionary(shortenKeys(state), "/")))
  return transformDictionary(shortenKeys(state), "/")
  return state
}

// "d":{"2":{"k":2,"U":2,"q":1},"3":{"k":3,"U":2,"q":2},"4":{}, ... }

// This uses the keys "d", "k", "U", "q" in the dictionary
const transformDictionary = (data, delimiter) => {
  const parsedData = typeof data === 'string' ? JSON.parse(data) : data;
 
  const isValidValue = (value) => {
    return value !== null && value !== undefined && !Number.isNaN(value);
  };

  const transformObject = (obj, keys) => {
    return Object.entries(obj)
      .map(([key, value]) => {
        if (Object.keys(value).length === 0) return null;
        if (!keys.every(k => isValidValue(value[k]))) return null;
        return keys.map(k => value[k]).join(delimiter);
      })
      .filter(item => item !== null);
  };

  const newData = { ...parsedData };

  // Transform 'a' object
  if (newData.a) {
    newData.a = transformObject(newData.a, ['k', 'l', 'm']);
  }

  // Transform 'b' object
  if (newData.b) {
    newData.b = transformObject(newData.b, ['k', 'l', 'n']);
  }

  // Transform 'c' object
  if (newData.c) {
    newData.c = transformObject(newData.c, ['k', 'O', 'p']); //, 'l'
  }

  // Transform 'd' object
  if (newData.d) {
    newData.d = transformObject(newData.d, ['k', 'U', 'q']);
  }

  // Transform 'e', 'f', and 'g' objects
  ['e', 'f', 'g'].forEach(key => {
    if (newData[key]) {
      newData[key] = transformObject(newData[key], ['k', 's', 'X', 't']);
    }
  });

  return newData;
};


const keyMappings = {
    departments: 'a',
    people: 'b',
    weeks: 'c',
    days: 'd',
    workHours: 'e',
    breakHours: 'f',
    putHours: 'g',
    meetings: 'h',
    categories: 'i',
    additionalHours: 'j',
    id: 'k',
    name: 'l',
    color: 'm',
    departmentId: 'n',
    weekNr: 'o',
    personId: 'p',
    weekDay: 'q',
    date: 'r',
    time: 's',
    type: 't',
    selectedDepartment: 'u',
    selectedPerson: 'v',
    selectedWeekId: 'w',
    selectedWeekNr: 'x',
    numberOfWeeks: 'y',
    showAddPerson: 'z',
    showAddDepartment: 'A',
    showExport: 'B',
    showSave: 'C',
    showHelp: 'D',
    showInfo: 'E',
    showRemoveData: 'F',
    showRemoveMeeting: 'G',
    showRemoveAdditionalHour: 'H',
    selectedDay: 'I',
    selectedButton: 'J',
    removeMeetingId: 'K',
    removeAdditionalHourId: 'L',
    deptNr: 'M',
    peopleNr: 'N',
    weekNr: 'O',
    dayNr: 'P',
    whNr: 'Q',
    bhNr: 'R',
    ahNr: 'S',
    mNr: 'T',
    weekId: 'U',
    category: 'V',
    length: 'W',
    dayId: 'X',
    start: 'Y',
    peopleIds: 'Z',
    userId: 'å',
    token: 'ä',
    autosync: 'ö',
    loggedIn:'Å',
    showPersonInfo: 'Ä',
    showDepartmentInfo: 'Ö',
    showPersonSettings: 'aa',
    autofillThirty: 'ab',
    showStatistics: 'ac',
    selectedStatisticsButton: 'ad',
    showRemovePerson: 'ae',
    selectedSchoolStatisticsButton: 'af',
    showPublicize: 'ag',
    publicizedLinks: 'ah',
    targetHours: 'ai',
    removeDepartmentId:'aj',
  };

const weekDayMappings = {
    'Må': 1,
    'Ti': 2,
    'On': 3,
    'To': 4,
    'Fr': 5,
    'Måndag': 1,
    'Tisdag': 2,
    'Onsdag': 3,
    'Torsdag': 4,
    'Fredag': 5
};

const typeMappings = {
    'start': 0,
    'end': 1,
    'length': 2
}

const shortenKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(item => shortenKeys(item));
    } else if (typeof obj === 'object' && obj !== null) {
      return Object.keys(obj).reduce((acc, key) => {
        const newKey = keyMappings[key] || key;
        let value = shortenKeys(obj[key]);
        
        // Check if the key is 'weekDay' or 'q' (shortened 'weekDay') and the value is a weekday
        if ((key === 'weekDay' || key === 'q') && weekDayMappings.hasOwnProperty(value)) {
          value = weekDayMappings[value];
        }
        
        // Check if the key is 'type' or 't' (shortened 'type') and the value is a type
        if ((key === 'type' || key === 't') && typeMappings.hasOwnProperty(value)) {
          value = typeMappings[value];
        }
        
        acc[newKey] = value;
        return acc;
      }, {});
    }
    return obj;
  };
  



/////////7 Reverse of the above

export const DecompressState = (compressedState) => {
  let expandedDictionary = expandDictionary(expandKeys(compressedState))
  const transformedCategories = Object.values(expandedDictionary.categories).map(category => category.name);
  expandedDictionary.categories = transformedCategories

  expandedDictionary = transformWeekDays(expandedDictionary)
  expandedDictionary = transformWorkHours(expandedDictionary)
  expandedDictionary = transformBreakHours(expandedDictionary)
  return expandedDictionary;
};

const expandDictionary = (data) => {
  const newData = { ...data };

  const expandObject = (arr, keys) => {
    return arr.reduce((acc, item) => {
      const values = item.split('/');
      const obj = {};
      keys.forEach((key, index) => {
        let value = values[index];
        // Attempt to parse the value as an integer, fallback to the original value if parsing fails.
        obj[key] = value.includes(':') || isNaN(parseInt(value)) ? value : parseInt(value);

        // obj[key] = values[index];
      });
      acc[obj.id] = obj;
      return acc;
    }, {});
  };

  if (newData.departments) {
    newData.departments = expandObject(newData.departments, ['id', 'name', 'color']);
  }
  if (newData.people) {
    newData.people = expandObject(newData.people, ['id', 'name', 'departmentId']);
  }
  if (newData.weeks) {
    newData.weeks = expandObject(newData.weeks, ['id', 'weekNr', 'personId']); //, 'name'
  }
  if (newData.days) {
    newData.days = expandObject(newData.days, ['id', 'weekId', 'weekDay']);
  }
  if (newData.workHours) {
    newData.workHours = expandObject(newData.workHours, ['id', 'time', 'dayId', 'type']);
  }
  if (newData.breakHours) {
    newData.breakHours = expandObject(newData.breakHours, ['id', 'time', 'dayId', 'type']);
  }
  if (newData.putHours) {
    newData.putHours = expandObject(newData.putHours, ['id', 'time', 'dayId', 'type']);
  }
  if (newData.categories) {
    newData.categories = newData.categories.reduce((acc, item, index) => {
      acc[index] = { id: index.toString(), name: item };
      return acc;
    }, {});
  }

  return newData;
};

const reverseKeyMappings = Object.fromEntries(
  Object.entries(keyMappings).map(([key, value]) => [value, key])
);

const reverseWeekDayMappings = Object.fromEntries(
  Object.entries(weekDayMappings).map(([key, value]) => [value, key])
);

const reverseTypeMappings = Object.fromEntries(
  Object.entries(typeMappings).map(([key, value]) => [value, key])
);

// const expandKeys = (obj) => {
//   if (Array.isArray(obj)) {
//     return obj.map(item => expandKeys(item));
//   } else if (typeof obj === 'object' && obj !== null) {
//     return Object.keys(obj).reduce((acc, key) => {
//       const newKey = reverseKeyMappings[key] || key;
//       let value = expandKeys(obj[key]);

//       log("Value is: ", value, "typeof: ", typeof value)
//       // Check if the key is 'weekDay' and the value is a number
//       if (newKey === 'weekDay' && typeof value === 'number') {
//         value = reverseWeekDayMappings[value] || value;
//       } else if (newKey === 'weekDay' && typeof value === 'string') {
//         log("found string vlaue")
//         const numValue = Number(value);
//         value = reverseWeekDayMappings[numValue] || value;
//       }

//       // Check if the key is 'type' and the value is a number
//       if (newKey === 'type' && typeof value === 'number') {
//         value = reverseTypeMappings[value] || value;
//       } else if (newKey === 'type' && typeof value === 'string') {
//         log("found stirng vlaueee")
//         const numValue = Number(value);
//         value = reverseTypeMappings[numValue] || value;
//       }

//       acc[newKey] = value;
//       return acc;
//     }, {});
//   }
//   return obj;
// };

const expandKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(item => expandKeys(item));
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((acc, key) => {
      const newKey = reverseKeyMappings[key] || key;
      let value = expandKeys(obj[key]);

      // Check if the key is 'weekDay' and the value is a number or a string representing a number
      if (newKey === 'weekDay') {
        const numValue = parseInt(value, 10);
        if (!isNaN(numValue) && reverseWeekDayMappings.hasOwnProperty(numValue)) {
          value = reverseWeekDayMappings[numValue];
        }
      }

      // Check if the key is 'type' and the value is a number or a string representing a number
      if (newKey === 'type') {
        const numValue = parseInt(value, 10);
        if (!isNaN(numValue) && reverseTypeMappings.hasOwnProperty(numValue)) {
          value = reverseTypeMappings[numValue];
        }
      }

      acc[newKey] = value;
      return acc;
    }, {});
  }
  return obj;
};




// Helper function to clean up the incorrectly parsed days xd

function transformWeekDays(mydictionary) {
  const transformedDays = {...mydictionary.days};
  
  for (const [key, day] of Object.entries(transformedDays)) {
      if (day.weekDay in reverseWeekDayMappings) {
          day.weekDay = reverseWeekDayMappings[day.weekDay];
      }
  }
  
  return {
      ...mydictionary,
      days: transformedDays
  };
}

//Attempt at same thing as above but for workhours and breakhours
//Seems to work
function transformWorkHours(mydictionary) {
  const transformedHours = {...mydictionary.workHours};
  
  for (const [key, wH] of Object.entries(transformedHours)) {
      if (wH.type in reverseTypeMappings) {
        wH.type = reverseTypeMappings[wH.type];
      }
  }
  
  return {
      ...mydictionary,
      workHours: transformedHours
  };
}
function transformBreakHours(mydictionary) {
  const transformedHours = {...mydictionary.breakHours};
  
  for (const [key, wH] of Object.entries(transformedHours)) {
      if (wH.type in reverseTypeMappings) {
        wH.type = reverseTypeMappings[wH.type];
      }
  }
  
  return {
      ...mydictionary,
      breakHours: transformedHours
  };
}