import React from 'react';
import { useAppContext } from '../contexts/AppContext';
import styles from '../styles/AddPersonButton.module.css';
import itemStyles from '../assets/assets/item selector/ItemSelector.module.css'

const AddMeetingAHButton = ({type}) => {
  const { dispatch } = useAppContext();


  const handleClick = () => {
    dispatch({
      type: 'TOGGLE_SHOW_ADD_MEETINGAH',
      payload: {
        setToShow: true
      }
    });
  };

  return (
    <>
    {/* <div class={itemStyles.container}>
      <div class={itemStyles.bg}></div>
      <div class={itemStyles.button}><a href="https://codepen.io/sashatran/" target="_blank"><i class="fa fa-chevron-down" aria-hidden="true"></i></a></div>
  </div> */}
    <button
      className={` ${itemStyles.plusButton} ` }
      // ${itemStyles.item} ${itemStyles.rightMostItem}
      onClick={handleClick}
      style={{marginLeft:'10px', marginTop:'40px', marginBottom:'33px'}}
    >
      <div style={{display:'flex', alignItems:'center'}}>
        <svg width="18" height="18" viewBox="0 0 24 24" id="_24x24_On_Light_Plus" data-name="24x24/On Light/Plus" xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000">
          <g id="SVGRepo_bgCarrier" stroke-width="0"/>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
          <g id="SVGRepo_iconCarrier"> <rect id="view-box" width="24" height="24" fill="#141124" opacity="0"/> <path id="Shape" d="M5,10.75V6.5H.75A.75.75,0,0,1,.75,5H5V.75a.75.75,0,0,1,1.5,0V5h4.25a.75.75,0,1,1,0,1.5H6.5v4.25a.75.75,0,1,1-1.5,0Z" transform="translate(6 6)" fill="#141124"/> </g>
        </svg>
        <p style={{fontSize:'1rem'}}>Lägg till {`${type === 'Möten' ? 'möte' : 'barnfri tid'}`}</p>
      </div>

    </button>
    </>
  );
};

export default AddMeetingAHButton;