import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';
import Switch from '../assets/assets/settings icon/Switch';
import { log } from '../utils/Utils';
import ModalLight from './ModalLight';
import { CategoryViewer } from './CategoryViewer';
import meetingAHStyles from '../styles/AddMeetingAHStyles.module.css';
import styles from './PersonSettings.module.css'
import selectStyles from '../styles/ThreeWaySelect.module.css';

import vOStyles from '../visualOverhaul/RightIsland.module.css'







const PersonSettings = () => {
  const { state, dispatch } = useAppContext();
  const {categories, selectedPerson, people, additionalHours} = state

  // const [showEditCategories, setShowEditCategories] = useState(false)

  

  const removeObject = () => {
    dispatch({ type: 'TOGGLE_SHOW_REMOVE_PERSON', payload: {
      setToShow: true,
      personId: state.selectedperson,
    } })
  }



  // const [contextMenuVisible, setContextMenuVisible] = useState(false);
  // const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  // const [elements, setElements] = useState(["Element 1", "Element 2", "Element 3"]);

  // const handleContextMenu = (e, index) => {
  //   e.preventDefault();
  //   setContextMenuVisible(true);
  //   setMenuPosition({ x: e.pageX, y: e.pageY });
  //   setSelectedElement(index);
  // };

  // const handleRemoveElement = () => {
  //   setElements((prevElements) => prevElements.filter((_, i) => i !== selectedElement));
  //   setContextMenuVisible(false);
  // };

  // const [selectedElement, setSelectedElement] = useState(null);


  

  const setTargetHour = (targetType, newHours, newMode) => {

    // The logic is supposed to make 
    const targetHours = people[selectedPerson].targetHours
    let targetHour = [null, null, 1]

    if (targetHours) {
      targetHour = targetHours.find(tH => tH[0] === targetType) || [null, null, 1]
    }

    const tHours = targetHour[1]
    const tMode = targetHour[2]

    dispatch({ type: 'SET_TARGET_HOURS', payload: {
      personId: selectedPerson,
      targetType: targetType,
      newHours: newHours ?? tHours,
      newMode: newMode ?? tMode ,
    } })

  }

  const getTargetMode = (targetType) => {
    const personTargetHours = people[selectedPerson].targetHours
    const target = personTargetHours?.find(tH => tH[0] === targetType);
    const targetMode = target ? target[2] : 1; // return 1 as default here i think..?
    // const targetMode = personTargetHours.find(tH => tH[0] === targetType)?[2] || 1 
    return targetMode
  }

  const getInputValue = (targetType) => {
    const personTargetHours = people[selectedPerson].targetHours
    const target = personTargetHours?.find(tH => tH[0] === targetType);
    const targetHours = target ? target[1] : null; // return 1 as default here i think..?
    // const targetMode = personTargetHours.find(tH => tH[0] === targetType)?[2] || 1 
    return targetHours
  }

  const editPersonName = () => {
    dispatch({ type: 'TOGGLE_SHOW_EDIT_PERSON_NAME', payload: {
      setToShow: true,
      personId: selectedPerson,
    } })
  }


  return (
    <div style={{maxWidth:'900px'}}>

      {/* <ModalLight isOpen={showEditCategories} onClose={() => dispatch({ type: 'TOGGLE_SHOW_EDIT_CATEGORIES' })}>
        <CategoryViewer setShowEditCategories={setShowEditCategories}></CategoryViewer>
      </ModalLight> */}

      <div style={{display:'flex', justifyContent:'space-between', marginBottom:'10px'}}>
      {/* {state.people[state.selectedPerson].name} */}
        <p style={{fontSize:'28px'}}>Inställningar {people[selectedPerson].name} 
        <button className={vOStyles.incButton} 
        style={{marginLeft:'10px'}} 
        onClick={editPersonName}>
              <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 21H21" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.0651 7.39423L7.09967 20.4114C6.72438 20.7882 6.21446 21 5.68265 21H4.00383C3.44943 21 3 20.5466 3 19.9922V18.2987C3 17.7696 3.20962 17.2621 3.58297 16.8873L16.5517 3.86681C19.5632 1.34721 22.5747 4.87462 20.0651 7.39423Z" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.3097 5.30981L18.7274 8.72755" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <button className={vOStyles.incButton} onClick={() => removeObject()} style={{marginLeft:'5px'}}>
              <svg height="25px" width="25px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlnsXlink="http://www.w3.org/1999/xlink" enableBackground="new 0 0 512 512">
                <g>
                  <g fill="#f11" stroke="#f11" strokeWidth="3">
                    <path d="m450.9,73.5h-118.4v-52.1c0-5.8-4.7-10.4-10.4-10.4h-132.2c-5.8,0-10.4,4.7-10.4,10.4v52.1h-118.4c-5.8,0-10.4,4.7-10.4,10.4v64.9c0,5.8 4.7,10.4 10.4,10.4h24.6v264.7c0,42.5 34.6,77.1 77.1,77.1h186.4c42.5,0 77.1-34.6 77.1-77.1v-264.6h24.6c5.8,0 10.4-4.7 10.4-10.4v-65c-5.68434e-14-5.7-4.7-10.4-10.4-10.4zm-250.5-41.6h111.3v41.7h-111.3v-41.7zm205,392c0,31-25.2,56.2-56.2,56.2h-186.4c-31,0-56.2-25.2-56.2-56.2v-264.6h298.8v264.6zm35-285.5h-368.8v-44h368.9v44z"/>
                    <path d="m164.1,427c5.8,0 10.4-4.7 10.4-10.4v-193.7c0-5.8-4.7-10.4-10.4-10.4-5.8,0-10.4,4.7-10.4,10.4v193.7c0,5.7 4.7,10.4 10.4,10.4z"/>
                    <path d="M256,427c5.8,0,10.4-4.7,10.4-10.4V222.9c0-5.8-4.7-10.4-10.4-10.4s-10.4,4.7-10.4,10.4v193.7    C245.6,422.3,250.2,427,256,427z"/>
                    <path d="m347.9,427c5.8,0 10.4-4.7 10.4-10.4v-193.7c0-5.8-4.7-10.4-10.4-10.4-5.8,0-10.4,4.7-10.4,10.4v193.7c-0.1,5.7 4.6,10.4 10.4,10.4z"/>
                  </g>
                </g>
              </svg>
            </button>
        </p>
        
      </div>
      <p>Ställ in omfattning, mötestid och barnfri tid.<br /> Antingen antal timmar per vecka eller för hela schemat totalt</p>
      <div style={{display:'flex', flexDirection:'column', marginTop:'20px',}}>
        <div style={{display:'flex', marginBottom:'5px'}}>
          <p style={{minWidth:'110px'}}>Omfattning:</p>
          <input className={styles.input}
                  value={getInputValue('workHours')}
                  onChange={e => setTargetHour('workHours', e.target.value, undefined) }></input><p>h</p>
          {/* <button onClick={() => setTargetHour('workHours', undefined, 1) }>h/v</button>
          <button onClick={() => setTargetHour('workHours', undefined, 2) }>h totalt</button> */}
          <div style={{display:'flex', flexDirection: 'row', marginLeft:'10px'}}>
            <div className={`${selectStyles.selectOption}
                            ${getTargetMode('workHours') === 1 ? selectStyles.selected : ''}
                            ${selectStyles.leftItem}`}
                  onClick={() => setTargetHour('workHours', undefined, 1)}>
              <p>per vecka</p>
            </div>
            <div className={`${selectStyles.selectOption}
                            ${getTargetMode('workHours') === 2 ? selectStyles.selected : ''}
                            ${selectStyles.rightItem}`}
                  onClick={() => setTargetHour('workHours', undefined, 2)}>
              <p>totalt</p>
            </div>
          </div>
        </div>
        <div style={{display:'flex', marginBottom:'20px'}}>
          <p style={{minWidth:'110px'}}>Mötestid:</p>
          <input className={styles.input} 
            value={getInputValue('meetings')}
            onChange={e => setTargetHour('meetings', e.target.value, undefined) }></input><p>h</p>
          {/* <button>h/v</button>
          <button>h totalt</button> */}
          <div style={{display:'flex', flexDirection: 'row', marginLeft:'10px'}}>
            <div className={`${selectStyles.selectOption}
                            ${getTargetMode('meetings') === 1 ? selectStyles.selected : ''}
                            ${selectStyles.leftItem}`}
                  onClick={() => setTargetHour('meetings', undefined, 1)}>
              <p>per vecka</p>
            </div>
            <div className={`${selectStyles.selectOption}
                            ${getTargetMode('meetings') === 2 ? selectStyles.selected : ''}
                            ${selectStyles.rightItem}`}
                  onClick={() => setTargetHour('meetings', undefined, 2)}>
              <p>totalt</p>
            </div>
          </div>
        </div>
        {
          categories.map(category => {
            const a=1

            return (
              <div style={{display:'flex', marginBottom:'5px'}}>
                <p style={{minWidth:'110px'}}>{category}:</p>
                <input className={styles.input}
                  value={getInputValue(category)}
                  onChange={e => setTargetHour(category, e.target.value, undefined) }></input><p>h</p>
                {/* <button>h/v</button>
                <button>h totalt</button> */}
                <div style={{display:'flex', flexDirection: 'row', marginLeft:'10px'}}>
                  <div className={`${selectStyles.selectOption}
                                  ${getTargetMode(category) === 1 ? selectStyles.selected : ''}
                                  ${selectStyles.leftItem}`}
                        onClick={() => setTargetHour(category, undefined, 1)}>
                    <p>per vecka</p>
                  </div>
                  <div className={`${selectStyles.selectOption}
                                  ${getTargetMode(category) === 2 ? selectStyles.selected : ''}
                                  ${selectStyles.rightItem}`}
                        onClick={() => setTargetHour(category, undefined, 2)}>
                    <p>totalt</p>
                  </div>
                </div>
              </div>
            )
          })
        }
        <div style={{display:'flex', justifyContent:'left', marginTop:'10pxf'}}>
          <p className={meetingAHStyles.editText}
              style={{width:'fit-content'}}
            onClick={() => dispatch({ type: 'TOGGLE_SHOW_EDIT_CATEGORIES', payload:{setToShow:true} })}>Redigera kategorier</p>
        </div>
        
      </div>
      {/* <br></br>
      <p style={{fontSize:'1.3em'}}>Globala inställningar</p> */}
      
      
      {/* <div style={{display:'flex', marginTop:'10px'}}>
        <Switch checked={state.autofillThirty} onChange={onChange}>

        </Switch>
        <p>Visa endast personens arbetstid på denna avdelning</p>
      </div> */}

      
{/* 
      <div>
      {elements.map((element, index) => (
        <div
          key={index}
          onContextMenu={(e) => handleContextMenu(e, index)}
          style={{ padding: "10px", border: "1px solid black", margin: "10px" }}
        >
          {element}
        </div>
      ))}

      {contextMenuVisible && (
        <div
          style={{
            position: "absolute",
            top: `${menuPosition.y}px`,
            left: `${menuPosition.x}px`,
            background: "white",
            border: "1px solid black",
            zIndex: 1000,
          }}
        >
          <div onClick={handleRemoveElement} style={{ padding: "10px", cursor: "pointer" }}>
            Remove Element
          </div>
        </div>
      )}
    </div> */}

      <div style={{display:'flex', justifyContent:'right', marginTop:'0px', marginBottom:'0px', width:'100%'}}>
        <button className={buttonStyles['secondaryButton']} style={{marginLeft:'0px'}}onClick={() => dispatch({
                                  type: 'TOGGLE_SHOW_PERSON_SETTINGS', payload: {
                                  setToShow: false,
                                  }
                                })}>
          Stäng
        </button>
      </div>
        
    </div>
  )
}

export default PersonSettings