import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';

const AddPersonForm = () => {
  const { state, dispatch } = useAppContext();
  const [newPersonName, setNewPersonName] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      inputRef.current.focus();
    }, 10); // Delay the focus by 100 milliseconds
  
    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      addPerson();
    }
  };

  const addPerson = () => {
    if (newPersonName){
      dispatch({
        type: 'ADD_PERSON',
        payload: { name: newPersonName}
      });
    }
    dispatch({ type: 'TOGGLE_ADD_PERSON', payload: {
      setToShow: false
    } })
    setNewPersonName('');
  };

  return (
    <div>
      <p style={{fontSize:'24px'}}>Lägg till ny person</p>
      <p style={{maxWidth:'410px', marginTop:'10px', marginBottom:'10px'}}>Personer med identiska namn på olika avdelningar räknas som samma person</p>
      <input
        ref={inputRef}
        className={inputStyles['input-30']}
        value={newPersonName}
        onChange={(e) => setNewPersonName(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Namn på person"
        style={{width:'150px', backgroundColor:'#e8e8e8'}}
      />
      <button className={buttonStyles['button-30']} onClick={addPerson}>Skapa person</button>
    </div>
  )
}

export default AddPersonForm