import React, { useState, useRef, useEffect } from 'react';
import DepartmentPicker from "../department/DepartmentPicker"

// import AddDepartmentButton from "../people/AddDepartmentButton"
import { useAppContext } from '../contexts/AppContext';
import { odv, timeToFloat } from "../utils/Utils";
import ItemSelector from "../assets/assets/item selector/ItemSelector";
import itemStyles from '../assets/assets/item selector/ItemSelector.module.css'
import styles from './DepartmentIsland.module.css'
import PersonDropdown from '../assets/assets/dropdowns/person dropdown/PersonDropdown';
import WeekSelectorDropdown from '../assets/assets/dropdowns/week selector dropdown/WeekSelectorDropdown';
import personDropdownStyles from '../assets/assets/dropdowns/department dropdown/DepartmentDropdown.module.css';
import DepartmentDropdown from '../assets/assets/dropdowns/department dropdown/DepartmentDropdown';



const AddDepartmentButton = () => {
  const { state, dispatch } = useAppContext();
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const baseStyle = {
    // marginLeft:'10px',
    fontSize: '19px',
    padding:'0px',
    // color: '#fff',
    backgroundColor: '#fff',
    borderRadius: '0px 20px 20px 0px',
    width: '50px',
    height: '50px',
    textAlign: 'center',
    borderWidth: '0px',
    cursor: 'pointer',
    // boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.1)',
    
  };

  const hoverStyle = {
    // backgroundColor: '#555555', // Change to your desired hover color
    // boxShadow: '3px 4px 11px -4px rgb(99, 99, 99)', 
    backgroundColor:'rgb(221, 221, 221)'
  };

  const pressedStyle = {
    backgroundColor: 'rgb(221, 221, 221)' // Change to your desired pressed color
  };

  const combinedStyle = {
    ...baseStyle,
    ...(isHovered ? hoverStyle : {}),
    ...(isPressed ? pressedStyle : {})
  };
  // style={combinedStyle}

  return (
    <>
      <button 
        // onMouseEnter={() => setIsHovered(true)}
        // onMouseLeave={() => setIsHovered(false)}
        // onMouseDown={() => setIsPressed(true)}
        // onMouseUp={() => setIsPressed(false)}
        className={`${itemStyles.item} ${itemStyles.rightMostItem} ${itemStyles.plusButton}` }
        onClick={() => dispatch({ type: 'TOGGLE_ADD_DEPARTMENT', payload: {
          setToShow: true
        } })}
        title='Lägg till avdelning'>
        <span>+</span>
      </button>
    </>
  )
}


export const FilterIsland = () => {
  const { state, dispatch } = useAppContext();
  const { selectedDepartment, selectedWeekNr, selectedDay, departments, people, days, weeks, workHours } = state;

  const selectDepartment = (departmentId) => {
    dispatch({
      type: 'SELECT_DEPARTMENT',
      payload: { departmentId: departmentId},
      meta: { localOnly: true }
    });
  };

const toggleShowScheduleSettings = () => {
  dispatch({
    type: 'TOGGLE_SHOW_SCHEDULE_SETTINGS',
    payload: { setToShow: true},
    meta: { localOnly: true }
  });
}

return (
  <div>
    {/* boxShadow: '0 1px 1px rgba(0, 0, 0, 0.24)' */}

  <div style={{display:'flex', alignItems:'center', gap:'20px', backgroundColor:'#fff', borderRadius:'20px', outline: '2px solid rgb(209, 209, 209)', padding:'7px'}}>
          {/* {odv(departments).map((dept) => (
            <button
              key={dept.id}
              className={`${styles.tab} ${selectedDepartment === dept.id ? styles.selected : ''}`}
              onClick={() => selectDepartment(dept.id)}
              style={{boxShadow: '0 1px 2px rgba(0, 0, 0, 0.24)',
                position: 'relative',
            zIndex: 1 // Higher z-index

              }}
            >
              <p style={{fontWeight:'400'}}>
                {dept.name}
              </p>
              
            </button>
          ))} */}
          <div style={{display:'flex', alignItems:'center',  padding:'5px', gap:'5px'}}>
          {/* outline:'2px solid rgb(233, 233, 233)', borderRadius:'20px', backgroundColor:'#f3f3f6', */}
          <svg width="24px" height="24px" viewBox="0 0 24 24" id="_24x24_On_Light_Schedule" data-name="24x24/On Light/Schedule" xmlns="http://www.w3.org/2000/svg">
            <rect id="view-box" width="24" height="24" fill="none"/>
            <path id="Shape" d="M2.75,17.5A2.753,2.753,0,0,1,0,14.75v-10A2.754,2.754,0,0,1,2.5,2.011V.75A.75.75,0,0,1,4,.75V2h9.5V.75a.75.75,0,0,1,1.5,0V2.011A2.754,2.754,0,0,1,17.5,4.75v10a2.752,2.752,0,0,1-2.75,2.75ZM1.5,14.75A1.251,1.251,0,0,0,2.75,16h12A1.251,1.251,0,0,0,16,14.75V8H1.5ZM16,6.5V4.75A1.251,1.251,0,0,0,14.75,3.5h-12A1.251,1.251,0,0,0,1.5,4.75V6.5Z" transform="translate(3.25 3.25)" fill="#141124"/>
          </svg>
            <p style={{fontSize:'1.2em'}}>Schema</p>

            <div className={personDropdownStyles.daySelector} >
            
              <button className={personDropdownStyles.selectorButton} onClick={toggleShowScheduleSettings}>
            <div style={{display:'flex', alignItems:'center', paddingLeft:'5px', paddingRight:'0px'}}>
                <p style={{overflow:'hidden'}} className={personDropdownStyles.para}>Rullande över {state.numberOfWeeks} v.</p>
                {/* <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 9.5C13.3807 9.5 14.5 10.6193 14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5Z" fill="rgb(83, 149, 255)"/>
                </svg> */}
                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8Z" fill="rgb(83, 149, 255)"/>
                </svg>
                {/* <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <g id="_24x24_On_Light_Arrow-Bottom" data-name="24x24/On Light/Arrow-Bottom" transform="translate(0 24) rotate(-90)">
                    <rect id="view-box" width="24" height="24" fill="none"/>
                    <path id="Shape" d="M.22,10.22A.75.75,0,0,0,1.28,11.28l5-5a.75.75,0,0,0,0-1.061l-5-5A.75.75,0,0,0,.22,1.28l4.47,4.47Z" transform="translate(14.75 17.75) rotate(180)" fill="#141124"/>
                  </g>
                </svg> */}
                {/* <svg width="24px" height="24px" viewBox="0 0 24 24" id="_24x24_On_Light_Recent" data-name="24x24/On Light/Recent" xmlns="http://www.w3.org/2000/svg">
                  <rect id="view-box" width="24" height="24" fill="none"/>
                  <path id="Shape" d="M9.682,18.75a.75.75,0,0,1,.75-.75,8.25,8.25,0,1,0-6.189-2.795V12.568a.75.75,0,0,1,1.5,0v4.243a.75.75,0,0,1-.751.75H.75a.75.75,0,0,1,0-1.5H3a9.75,9.75,0,1,1,7.433,3.44A.75.75,0,0,1,9.682,18.75Zm2.875-4.814L9.9,11.281a.754.754,0,0,1-.22-.531V5.55a.75.75,0,1,1,1.5,0v4.889l2.436,2.436a.75.75,0,1,1-1.061,1.06Z" transform="translate(1.568 2.25)" fill="#141124"/>
                </svg> */}
                {/* <p style={{border: '1px solid #ccc', borderRadius:'20px', backgroundColor:'#fff', padding:'5px', cursor:'pointer', }}></p> */}

            </div>
              </button>
              
            </div>
          </div>
          
          <div style={{display:'flex', alignItems:'center',padding:'5px', gap:'5px'}}>
          {/* outline:'2px solid rgb(233, 233, 233)', borderRadius:'20px', backgroundColor:'#f3f3f6',  */}
          <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Navigation / House_03">
              <path id="Vector" d="M2 20.0001H4M4 20.0001H10M4 20.0001V11.4522C4 10.9179 4 10.6506 4.06497 10.4019C4.12255 10.1816 4.21779 9.97307 4.3457 9.78464C4.49004 9.57201 4.69064 9.39569 5.09277 9.04383L9.89436 4.84244C10.6398 4.19014 11.0126 3.86397 11.4324 3.73982C11.8026 3.63035 12.1972 3.63035 12.5674 3.73982C12.9875 3.86406 13.3608 4.19054 14.1074 4.84383L18.9074 9.04383C19.3095 9.39568 19.5102 9.57202 19.6546 9.78464C19.7825 9.97307 19.877 10.1816 19.9346 10.4019C19.9995 10.6506 20 10.9179 20 11.4522V20.0001M10 20.0001H14M10 20.0001V16.0001C10 14.8955 10.8954 14.0001 12 14.0001C13.1046 14.0001 14 14.8955 14 16.0001V20.0001M14 20.0001H20M20 20.0001H22" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
          </svg>
            <p style={{fontSize:'1.2em'}}>Avdelning</p>
            {/* <PersonDropdown></PersonDropdown> */}
            <DepartmentDropdown></DepartmentDropdown>
            {/* <DepartmentPicker></DepartmentPicker> */}
            
          </div>
          
          {/* <p>Schema för </p> */}
          {/* <WeekSelectorDropdown></WeekSelectorDropdown>
          <p>veckor</p> */}
          {/* <ItemSelector selectedItem={state.selectedDepartment} state={state} items={odv(state.departments)} onItemSelect={selectDepartment} areObjects={true} dispatch={dispatch}></ItemSelector>
          <AddDepartmentButton /> */}
        </div>
        {/* <div style={{backgroundColor: 'white', width:'100%', height:'6px', borderRadius:'0 0 8px 8px', position: 'relative',
          boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.1)',
          zIndex: 5, 
        }}>

        </div> */}
    </div>
  )
}