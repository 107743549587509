import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';


const SendToEditorConfirm = ({closePopup, goToHome}) => {
  const { state, dispatch } = useAppContext();


  return (
    <div>
      <p style={{fontSize:'24px'}}>Vill du gå till startskärmen med detta schema?
      </p>
      <br></br>
      <p style={{maxWidth:'500px'}}>Detta kommer att skriva över data som finns lagrad lokalt på beundraforskola.se</p>
      <div style={{display:'flex', justifyContent:'right'}}>

        <button className={buttonStyles['secondaryButton']} style={{marginLeft:'0px'}}onClick={closePopup}>
          Avbryt
        </button>
        <button 
          className={buttonStyles['button-30']}
          onClick={() => {closePopup();
                                goToHome();
                                }}>
          Ja
        </button>
      </div>
      
    </div>
  )
}

export default SendToEditorConfirm