import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';
import { odv, log, validateAndFormatTime, timeToFloat } from '../utils/Utils';
import ModalLight from './ModalLight';

export const CategoryViewer = ({setShowEditCategories}) => {
  const { state, dispatch } = useAppContext();
  const { categories } = state


  // const [showEditCategories, setShowEditCategories] = useState(false)
  // const [showRemoveCategory, setShowRemoveCategory] = useState(false)
  // const [showAddCategory, setShowAddCategory] = useState(false)
  // const [categoryToRemove, setCategoryToRemove] = useState('')
  // const [categoryValue, setCategoryValue] = useState('')


  const removeCategory = (category) => {
    // setCategoryToRemove(category)
    dispatch({
      type: 'TOGGLE_SHOW_REMOVE_CATEGORY',
      payload: {
        setToShow: true,
        categoryToRemove: category
      }
    })
    // setShowRemoveCategory(true)
  }

  // const removeCategoryActual = () => {
  //   const newCategories = categories.filter(category => category !== state.categoryToRemove)
  //   dispatch({
  //     type: 'SET_CATEGORIES',
  //     payload: {
  //       categories: newCategories,
  //     }
  //   })
  //   setShowRemoveCategory(false)
  // }

  // const addCategory = () => {
  //   const newCategories = [...categories, categoryValue]
  //   dispatch({
  //     type: 'SET_CATEGORIES',
  //     payload: {
  //       categories: newCategories,
  //     }
  //   })
  //   setShowAddCategory(false)
  //   setCategoryValue('')
  // }

  return (
    <div>
          <p style={{fontSize:'24px'}}>Kategorier för barnfri tid</p>
          <div style={{display:'flex', gap:'10px', marginTop:'10px',}}>
            {
              categories.map(category => <div style={{display:'flex', alignItems:'center', outline: '1px solid #ccc', flexShrink:'0', width:'fit-content', padding:'3px', borderRadius:'20px'}}>
                <p style={{marginLeft:'10px'}}>{category}</p>
                <button className={buttonStyles['secondaryButtonRemove']} onClick={() => removeCategory(category)}>
                  <svg height="15px" width="15px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlnsXlink="http://www.w3.org/1999/xlink" enableBackground="new 0 0 512 512">
                    <g>
                      <g fill="#f11" stroke="#f11" strokeWidth="3">
                        <path d="m450.9,73.5h-118.4v-52.1c0-5.8-4.7-10.4-10.4-10.4h-132.2c-5.8,0-10.4,4.7-10.4,10.4v52.1h-118.4c-5.8,0-10.4,4.7-10.4,10.4v64.9c0,5.8 4.7,10.4 10.4,10.4h24.6v264.7c0,42.5 34.6,77.1 77.1,77.1h186.4c42.5,0 77.1-34.6 77.1-77.1v-264.6h24.6c5.8,0 10.4-4.7 10.4-10.4v-65c-5.68434e-14-5.7-4.7-10.4-10.4-10.4zm-250.5-41.6h111.3v41.7h-111.3v-41.7zm205,392c0,31-25.2,56.2-56.2,56.2h-186.4c-31,0-56.2-25.2-56.2-56.2v-264.6h298.8v264.6zm35-285.5h-368.8v-44h368.9v44z"/>
                        <path d="m164.1,427c5.8,0 10.4-4.7 10.4-10.4v-193.7c0-5.8-4.7-10.4-10.4-10.4-5.8,0-10.4,4.7-10.4,10.4v193.7c0,5.7 4.7,10.4 10.4,10.4z"/>
                        <path d="M256,427c5.8,0,10.4-4.7,10.4-10.4V222.9c0-5.8-4.7-10.4-10.4-10.4s-10.4,4.7-10.4,10.4v193.7    C245.6,422.3,250.2,427,256,427z"/>
                        <path d="m347.9,427c5.8,0 10.4-4.7 10.4-10.4v-193.7c0-5.8-4.7-10.4-10.4-10.4-5.8,0-10.4,4.7-10.4,10.4v193.7c-0.1,5.7 4.6,10.4 10.4,10.4z"/>
                      </g>
                    </g>
                  </svg>
                </button>
              </div>)
            }
          </div>
          <div style={{display:'flex', justifyContent:'right', marginTop:'10px'}}>
            <button className={buttonStyles['secondaryButton']} onClick={() => dispatch({ type: 'TOGGLE_SHOW_EDIT_CATEGORIES' })}>Stäng</button>
            <button className={buttonStyles['button-30']} onClick={() => dispatch({ type: 'TOGGLE_SHOW_ADD_CATEGORY', payload:{setToShow: true}})}>Ny kategori</button>
          </div>
          {/* <p>Skapa ny</p> */}


        {/* <ModalLight isOpen={showRemoveCategory} onClose={() => setShowRemoveCategory(false)}>
          <p style={{fontSize:'24px'}}>Är du säker att du vill ta bort kategorin {categoryToRemove}?</p>
          <div style={{display:'flex', justifyContent:'right', marginTop:'10px'}}>
            <button className={buttonStyles['secondaryButton']} onClick={() => setShowRemoveCategory(false)}>Avbryt</button>
            <button className={buttonStyles['button-30']} onClick={removeCategoryActual}>Ta bort</button>
          </div>
        </ModalLight>
        {* <ModalLight isOpen={showAddCategory} onClose={() => setShowAddCategory(false)}>
          <p style={{fontSize:'24px'}}>Skapa ny kategori</p>
          <div style={{display:'flex', alignItems:'center', marginTop:'10px'}}>
            <p style={{'whiteSpace': 'nowrap', marginRight:'10px'}}>Namn</p>
            <input
              className={inputStyles['input-30']}
              style={{width:'100px'}}
              value={categoryValue}
              onChange={(e) => setCategoryValue(e.target.value)}
              placeholder="Kategori"
            />
          </div>

          <div style={{display:'flex', justifyContent:'right', marginTop:'10px'}}>
            <button className={buttonStyles['secondaryButton']} onClick={() => setShowAddCategory(false)}>Avbryt</button>
            <button className={buttonStyles['button-30']} onClick={addCategory}>Skapa</button>
          </div>
        </ModalLight> */}


    </div>
  )

}