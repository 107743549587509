import React, { useState, useEffect, useRef } from 'react';
import styles from './BurgerMenu.module.css';
import { useAppContext } from '../../../contexts/AppContext';
import { checkNoModalOpen } from '../../../utils/Utils';

const BurgerMenu = () => {
  const { state, dispatch } = useAppContext();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef();
  const buttonRef = useRef();

  const toggleMenu = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current && 
      !menuRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target) &&
      checkNoModalOpen(state)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [state]);


  return (
    <div className={styles.container}>
      <button
        ref={buttonRef}
        onClick={toggleMenu}
        className={`${styles.button} ${isOpen ? styles.open : ''}`}
        title='Meny'
      >
        <span className={styles.burgerIcon}></span>
      </button>
      {isOpen && (
        <div ref={menuRef} className={styles.menu}>
          <ul className={styles.menuList}>
            <li><a onClick={() => {dispatch({ type: 'TOGGLE_SHOW_INFO', payload: {
                    setToShow: true
                  } });  }} className={styles.menuItem}>Hur gör man?</a></li>
            <li><a onClick={() => {dispatch({ type: 'TOGGLE_SHOW_HELP', payload: {
                    setToShow: true
                  } });  }} className={styles.menuItem}>Hjälp</a></li>
            <li><a onClick={() => {dispatch({ type: 'TOGGLE_SHOW_EXPORT', payload: {
                    setToShow: true
                  } });  }} className={styles.menuItem}>Export Excel</a></li>
            <li><a onClick={() => {dispatch({ type: 'TOGGLE_SHOW_SAVE', payload: {
                    setToShow: true
                  } });  }} className={styles.menuItem}>Spara/ladda upp</a></li>
            <li><a onClick={() => {dispatch({ type: 'TOGGLE_SHOW_LOGIN', payload: {
                    setToShow: true
                  } });  }} className={styles.menuItem}>Logga in</a></li>
            <li><a onClick={() => {dispatch({ type: 'TOGGLE_SHOW_PUBLICIZE', payload: {
                    setToShow: true
                  } });  }} className={styles.menuItem}>Publicera</a></li>
            {/* <li><a onClick={() => {dispatch({ type: 'TOGGLE_SHOW_PUBLICIZE', payload: {
                    setToShow: true
                  } });  }} className={styles.menuItem}>Demodata, rensa data</a></li>
            <li><a onClick={() => {dispatch({ type: 'TOGGLE_SHOW_STATISTICS', payload: {
                    setToShow: true
                  } });  }} className={styles.menuItem}>Statistik</a></li> */}
          </ul>
        </div>
      )}
    </div>
  );
};

export default BurgerMenu;