import React, { useState, useRef, useEffect } from 'react';
import DepartmentPicker from "../department/DepartmentPicker"

// import AddDepartmentButton from "../people/AddDepartmentButton"
import { useAppContext } from '../contexts/AppContext';
import { odv, timeToFloat, weekDays, log } from "../utils/Utils";
import ItemSelector from "../assets/assets/item selector/ItemSelector";
import itemStyles from '../assets/assets/item selector/ItemSelector.module.css'
import styles from './DepartmentIsland.module.css'
import PersonDropdown from '../assets/assets/dropdowns/person dropdown/PersonDropdown';
import WeekSelectorDropdown from '../assets/assets/dropdowns/week selector dropdown/WeekSelectorDropdown';
import personDropdownStyles from '../assets/assets/dropdowns/person dropdown/PersonDropdown.module.css';
import buttonStyles from '../styles/StyledButton.module.css';
import weekSelectorStyles from '../assets/assets/dropdowns/week selector dropdown/WeekSelectorDropdown.module.css';
import selectStyles from '../styles/ThreeWaySelect.module.css';
import Switch from '../assets/assets/settings icon/Switch';




const AddDepartmentButton = () => {
  const { state, dispatch } = useAppContext();
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const baseStyle = {
    // marginLeft:'10px',
    fontSize: '19px',
    padding:'0px',
    // color: '#fff',
    backgroundColor: '#fff',
    borderRadius: '0px 20px 20px 0px',
    width: '50px',
    height: '50px',
    textAlign: 'center',
    borderWidth: '0px',
    cursor: 'pointer',
    // boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.1)',
    
  };

  const hoverStyle = {
    // backgroundColor: '#555555', // Change to your desired hover color
    // boxShadow: '3px 4px 11px -4px rgb(99, 99, 99)', 
    backgroundColor:'rgb(221, 221, 221)'
  };

  const pressedStyle = {
    backgroundColor: 'rgb(221, 221, 221)' // Change to your desired pressed color
  };

  const combinedStyle = {
    ...baseStyle,
    ...(isHovered ? hoverStyle : {}),
    ...(isPressed ? pressedStyle : {})
  };
  // style={combinedStyle}

  return (
    <>
      <button 
        // onMouseEnter={() => setIsHovered(true)}
        // onMouseLeave={() => setIsHovered(false)}
        // onMouseDown={() => setIsPressed(true)}
        // onMouseUp={() => setIsPressed(false)}
        className={`${itemStyles.item} ${itemStyles.rightMostItem} ${itemStyles.plusButton}` }
        onClick={() => dispatch({ type: 'TOGGLE_ADD_DEPARTMENT', payload: {
          setToShow: true
        } })}
        title='Lägg till avdelning'>
        <span>+</span>
      </button>
    </>
  )
}


export const ScheduleSettings = () => {
  const { state, dispatch } = useAppContext();
  const { selectedDepartment, selectedWeekNr, selectedDay, departments, people, days, weeks, workHours, weekDaySettings, numberOfWeeks } = state;
  // const [numberOfScheduleWeeks, setNumberOfScheduleWeeks] = useState(state.numberOfWeeks)
  const [selectedWeek, setSelectedWeek] = useState(state.numberOfWeeks)
  const [isOpen, setIsOpen] = useState(false);
  const [newAutofill30, setNewAutofill30] = useState(state.autofillThirty)
  const [localWeekDaySettings, setLocalWeekDaySettings] = useState(weekDaySettings || {
    'Måndag': true,
    'Tisdag': true,
    'Onsdag': true,
    'Torsdag': true,
    'Fredag': true,
});
  const dropdownRef = useRef(null);


  const selectDepartment = (departmentId) => {
    dispatch({
      type: 'SELECT_DEPARTMENT',
      payload: { departmentId: departmentId},
      meta: { localOnly: true }
    });
  };

const toggleShowScheduleSettings = () => {
  dispatch({
    type: 'TOGGLE_SHOW_SCHEDULE_SETTINGS',
    payload: { setToShow: true},
    meta: { localOnly: true }
  });
}

const pickWeek = () => {
  dispatch({
    type: 'ADD_WEEK',
    meta: { localOnly: true }
  });
}

const saveSettings = () => {
  // Set number of weeks, add/remove weeks based on difference...
  dispatch({
      type: 'SET_WEEKDAY_SETTINGS',
      payload: { weekDaySettings: localWeekDaySettings},
    });

  const currentNumberOfWeeks = numberOfWeeks

  if (selectedWeek === currentNumberOfWeeks) {
    // Do nothing, already correct
  } else if (selectedWeek >= currentNumberOfWeeks) {
    [...Array(selectedWeek - currentNumberOfWeeks)].forEach((_, i) => {
      dispatch({
        type: 'ADD_WEEK',
        meta: { localOnly: true }
      });
    })
  } else {
    [...Array(currentNumberOfWeeks - selectedWeek)].forEach((_, i) => {
      dispatch({
        type: 'REMOVE_WEEK',
        meta: { localOnly: true }
      });
    })
  }

  dispatch({ type: 'SET_AUTOFILL_THIRTY', payload: {newValue: newAutofill30} })
  dispatch({ type: 'TOGGLE_SHOW_SCHEDULE_SETTINGS' })
}

const handleWeekSelect = (week) => {
  setSelectedWeek(week);
  setIsOpen(false);
  // onChange(week)
};

const getWeekdaySetting = (weekDay) => {
  return localWeekDaySettings[weekDay]
}

const setDaySetting = (weekDay, newValue) => {
  // dispatch({
  //   type: 'SET_WEEKDAY_SETTINGS',
  //   payload: { weekDaySettings: {...weekDaySettings, [weekDay]: newValue}},
  // });
  setLocalWeekDaySettings({...localWeekDaySettings, [weekDay]: newValue})
}

// Compare current settings with initial values
const hasUnsavedChanges = () => {
  return (
    state.numberOfWeeks !== selectedWeek ||
    state.autofillThirty !== newAutofill30 ||
    JSON.stringify(state.weekDaySettings) !== JSON.stringify(localWeekDaySettings)
  );
};

const weeksArray = Array.from({ length: 8 }, (_, index) => index + 1);

return (
  <div>
    {/* boxShadow: '0 1px 1px rgba(0, 0, 0, 0.24)' */}

    <p style={{fontSize:'1.5em'}}>Inställningar schema</p>
    {/* <p>Välj över hur många veckor schemat ska vara rullande samt vilka dagar <br /> som ska ha samma timmar varje dag och vilka dagar som ska skilja sig åt.</p> */}
        <div style={{display:'flex', alignItems:'center', gap:'10px', marginBottom:'20px', marginTop:'10px'}}>
        {/* backgroundColor:'#F3F3F6', borderRadius:'20px', outline: '2px solid rgb(233, 233, 233)', padding:'7px' */}
        <p style={{fontSize:'1.2em'}}>Hur många veckor ska schemat rulla över?</p>
        {/* <WeekSelectorDropdown onChange={pickWeek} initialSelectedWeekNr={numberOfScheduleWeeks}></WeekSelectorDropdown> */}
        <div className={weekSelectorStyles.daySelector} ref={dropdownRef} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
          <button className={weekSelectorStyles.selectorButton} onClick={() => setIsOpen(true)}>
            <p className={weekSelectorStyles.para}>{selectedWeek || 'Välj vecka'}</p>
          </button>
          {isOpen && (
            <ul className={weekSelectorStyles.dropdown}>
              {weeksArray.map((week) => (
                <li key={week} onClick={() => handleWeekSelect(week)}>
                  {week}
                </li>
              ))}
            </ul>
          )}
        </div>
        </div>

        
        <p style={{marginBottom:'10px'}}>Välj dagar som ska vara samma varje vecka och vilka som ska vara unika varje vecka:</p>
        {
          odv(weekDays).map(weekDay => {
            return (
              <div style={{display:'flex', marginBottom:'5px'}}>
                <p style={{width:'100px'}}>{weekDay}</p>
                <div className={`${selectStyles.selectOption}
                                  ${getWeekdaySetting(weekDay) ? selectStyles.selected : ''}
                                  ${selectStyles.leftItem}`}
                        onClick={() => setDaySetting(weekDay, true)}>
                    <p>Samma varje vecka</p>
                  </div>
                  <div className={`${selectStyles.selectOption}
                                  ${!getWeekdaySetting(weekDay) ? selectStyles.selected : ''}
                                  ${selectStyles.rightItem}`}
                        onClick={() => setDaySetting(weekDay, false)}>
                    <p>Olika varje vecka</p>
                  </div>
              </div>
            )
          })
        }

        <div style={{display:'flex', alignItems:'center', marginTop:'20px'}}>
          <Switch checked={newAutofill30} onChange={() => setNewAutofill30(!newAutofill30)}>

          </Switch>
          <p style={{cursor:'pointer'}} onClick={() => setNewAutofill30(!newAutofill30)}>Fyll i 30 minuter rast automatiskt när dagens längd är 8.5h eller längre</p>
        </div>

        <div style={{display:'flex', justifyContent:'right', marginTop:'10px'}}>
          <button className={buttonStyles['secondaryButton']} onClick={() => dispatch({ type: 'TOGGLE_SHOW_SCHEDULE_SETTINGS', payload: {
            setToShow: false
          } })}>
            Avbryt
          </button>
          <button className={`${buttonStyles['button-30']} ${hasUnsavedChanges() ? buttonStyles.spara : ''}`} onClick={saveSettings}>Spara {hasUnsavedChanges() && <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8Z" fill="rgb(92, 155, 255)"/>
                </svg>}</button>
        </div>

        
    </div>
  )
}