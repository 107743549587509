import React from 'react';
import { calculateOverlap } from './DataCalculator';
import seedrandom from 'seedrandom';

export function timeToFloat(time) {
  if (time) {
    if (typeof time !== 'string' || !time.includes(':')) {
      throw new Error(`Input must be a string containing ":" (recieved ${time}`);
    }
    const [hours, minutes] = time.split(':').map(Number);
    return hours + minutes / 60;
  } else return null
}

export function floatToTime(timeFloat) {
  if (timeFloat) {
    if (typeof timeFloat !== 'number') {
      throw new Error(`Input must be a float (recieved ${timeFloat}`);
    }

    const hours = Math.floor(timeFloat);
    const minutes = Math.round((timeFloat - hours) * 60);
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  } else return '00:00'
}

export function floatToTimeWithZeroBefore(timeFloat) {
  if (timeFloat) {
    if (typeof timeFloat !== 'number') {
      throw new Error(`Input must be a float (received ${timeFloat})`);
    }

    const hours = String(Math.floor(timeFloat)).padStart(2, '0'); // Ensures 2 digits for the hours
    const minutes = String(Math.round((timeFloat - Math.floor(timeFloat)) * 60)).padStart(2, '0'); // Ensures 2 digits for the minutes
    return `${hours}:${minutes}`;
  } else {
    return '00:00';
  }
}

export const weekDays = {
    'Må': 'Måndag',
    'Ti': 'Tisdag',
    'On': 'Onsdag',
    'To': 'Torsdag',
    'Fr': 'Fredag',
}

export const threeLetterWeekDays = {
    'Måndag': 'Mån',
    'Tisdag': 'Tis',
    'Onsdag': 'Ons',
    'Torsdag': 'Tor',
    'Fredag': 'Fre',
}

export const validateAndFormatTime = (input) => {
  let hours, minutes;

  // First of all check if the input is 30 (=30 minutes, 0:30)
  if (input === '30'){
    return '00:30'
  } else if (input === '45'){
    return '00:45'
  } else if (input === '60'){
    return '01:00'
  } else if (input === '0.3' || input === '0,3') { // for convenience
    return '00:30'
  }

  // Check for time input like "HH:MM" first
  const colonMatch = input.match(/^(\d{1,2}):(\d{1,2})$/);
  if (colonMatch) {
    hours = parseInt(colonMatch[1], 10);
    minutes = parseInt(colonMatch[2], 10);
  } else {
    // Handle decimal time input like 6.25 or 10.30
    const dotMatch = input.match(/^(\d{1,2})\.(\d{1,2})$/);
    if (dotMatch) {
      hours = parseInt(dotMatch[1], 10);
      const fractionalPart = parseInt(dotMatch[2], 10);
      
      // Handle cases like 10.0, 10.00, 10.15, 10.30, 10.45
      if (fractionalPart === 0 || fractionalPart === 15 || fractionalPart === 30 || fractionalPart === 45) {
        minutes = fractionalPart;
      } else if (dotMatch[2].length === 1) {
        // Handle single digit after dot as tenths of an hour
        minutes = Math.round(fractionalPart / 10 * 60);
      } else {
        // Handle two digits after dot as hundredths of an hour
        minutes = Math.round(fractionalPart / 100 * 60);
      }
    } else {
      // Handle plain decimal input
      const floatTime = parseFloat(input);
      if (!isNaN(floatTime)) {
        hours = Math.floor(floatTime);
        minutes = Math.round((floatTime - hours) * 60);
      } else {
        return null; // Invalid input format
      }
    }
  }

  // Round minutes to the nearest 15-minute interval
  minutes = Math.round(minutes / 15) * 15;
  
  // Handle hour rollover if minutes become 60
  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  // Validate hours and minutes
  if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
    return null; // Invalid time
  }

  // Formatting the time properly, ensuring two-digit hours and minutes
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};
export const validateAndFormatTime2 = (input) => {
    let hours, minutes;
  
    // Check for time input like "HH:MM" first
    const match = input.match(/^(\d{1,2}):(\d{1,2})$/);
    if (match) {
      hours = parseInt(match[1], 10);
      minutes = parseInt(match[2], 10);
  
      // If minutes are not a multiple of 15, round down to the nearest hour
      if (minutes % 15 !== 0) {
        minutes = 0; // Set minutes to zero to round down to the nearest hour
      }
    } else {
      // Handle decimal time input like 6.25
      const floatTime = parseFloat(input);
      if (!isNaN(floatTime)) {
        hours = Math.floor(floatTime);
        minutes = Math.round((floatTime - hours) * 60);
        if (minutes % 15 !== 0) {
          minutes = 0; // Set minutes to zero to round down to the nearest hour
        }
      } else {
        return null; // Invalid input format
      }
    }
  
    // Formatting the time properly, ensuring two-digit minutes
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  };

export const log = (...stuff) => {
  console.log(...stuff)
}

export const getTextColor = (time) => time === '00:00' ? 'gray' : 'black';

function secureRandomInt(min, max) {
  const range = max - min;
  const randomArray = new Uint32Array(1);
  crypto.getRandomValues(randomArray);
  return min + (randomArray[0] % range);
}

export function getRandomGoodLookingColor(seed) {
  const rng = seedrandom(seed);

  function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }

  // Colors extracted from the gradient
  const gradientColors = [
    { h: 297, s: 46, l: 74 },  // #d7a1da
    { h: 291, s: 42, l: 78 },  // #d5aedf
    { h: 285, s: 37, l: 82 },  // #d5c7e3
    { h: 280, s: 21, l: 86 },  // #d8d2e3
    { h: 285, s: 25, l: 86 },  // #dfd5e4
    { h: 290, s: 29, l: 87 },  // #e6d9e6
    { h: 295, s: 36, l: 89 },  // #ecdde7
    { h: 349, s: 92, l: 92 },  // #fcd9df
    { h: 18, s: 100, l: 87 },  // #ffd8cd
    { h: 39, s: 100, l: 86 },  // #ffddb8
    { h: 54, s: 63, l: 87 }    // #eee6ac
  ];

  // Randomly select a color from the gradient
  const baseColor = gradientColors[Math.floor(rng() * gradientColors.length)];

  // Adjust saturation and lightness
  const saturation = Math.min(100, baseColor.s + Math.floor(rng() * 20) + 10); // Increase saturation
  const lightness = Math.max(50, Math.min(90, baseColor.l - Math.floor(rng() * 20))); // Decrease lightness for more vibrant colors
  const hueVariation = Math.floor(rng() * 21) - 10; // Allow slight hue variation
  const hue = (baseColor.h + hueVariation + 360) % 360; // Ensure hue is within 0-359 range

  return hslToHex(hue, saturation, lightness);
}

export function getUniqueNames (people) {return [...new Set(Object.values(people).map(person => person.name))]}

export function odv (stuff) {return Object.values(stuff)}

export const selectedColor = '#414141'
export const notSelectedColor = '#999'

export const calculateTimeOutside = (wHStart, wHEnd, meetingStart, meetingLength) => {
  const meetingEnd = meetingStart + meetingLength

  let start = 0
  let stop = 0

  if (meetingStart < wHStart || meetingStart > wHEnd) {
    start = meetingStart

    if ( meetingEnd < wHStart || meetingEnd > wHEnd ) {
      stop = meetingEnd
    } else {
      stop = wHStart
    } 
  } else if ( meetingEnd > wHEnd ) {
    start = wHEnd
    stop = meetingEnd
  } // Else means meeting is fully within work hour if my calculations are correct..

  return stop - start
}

export const findPeopleWithSameName = (state, personn) => {
  return odv(state.people).filter(person => person.name === personn.name)
}

export const calculateDayTotalHours = (state, dayId) => {
  // This is just a wrapper that iterates over all people with the same name (in different departments)
  const day = state.days[dayId]
  const week = state.weeks[day.weekId]
  const person = state.people[week.personId]
  const peopleObjectsWithSameName = findPeopleWithSameName(state, person)

  const relevantDays = odv(state.days).filter(dayy => dayy.weekDay === day.weekDay && state.weeks[dayy.weekId]?.weekNr === week.weekNr && Object.values(peopleObjectsWithSameName).includes(state.people[state.weeks[dayy.weekId].personId]))


  let sum = 0
  relevantDays.forEach(day => sum += calculateDayTotalHoursActual(state, day.id))

  return sum
  
}

export const calculateDayTotalHoursActual = (state, dayId) => {
  if (!dayId) {log("recieved null in cDTHA: ", dayId); return 0}
  
  const { meetings, days, weeks, people, workHours, breakHours, additionalHours } = state

  const day = days[dayId]
  if (!day) {
    log("Day is null in cDTHA", day, dayId)
    return 0
  }
  const week = weeks[day.weekId]
  let workHourStart = odv(workHours).find(wh => wh.dayId === day.id && wh.type === 'start')?.time
  let workHourEnd = odv(workHours).find(wh => wh.dayId === day.id && wh.type === 'end')?.time

  // if (dayId === 48 || dayId === 13 ) {
  //   log("48 and 13: ", dayId, workHourStart, workHourEnd, state)
  // }

  if (!(workHourStart && workHourEnd)) {
    // If there is no work hours placed that day, still do calculation for potential meetings
    let totalHours = 0

    const relevantMeetings = odv(meetings).filter(meeting => 
      meeting.weekDay === days[dayId].weekDay 
      && meeting.weekNr === week.weekNr
      && meeting.peopleIds.includes(people[week.personId].id)
    )
  
  
    relevantMeetings.forEach(meeting => {
      // This does the crucial assumption that the same person can not be in two different different meetings at the same time
      totalHours += calculateTimeOutside(0, 0, timeToFloat(meeting.time), timeToFloat(meeting.length))
    })
    // if (dayId === 48 || dayId === 13 ) {
    //   log("recieved this dayid and days: ", dayId, days[dayId], totalHours)
    // }
    return totalHours
  }

  workHourStart = timeToFloat(workHourStart)
  workHourEnd = timeToFloat(workHourEnd)


  const breakHourStart = odv(breakHours).find(bh => bh.dayId === day.id && bh.type === 'start')?.time
  const breakHourLength = odv(breakHours).find(bh => bh.dayId === day.id && bh.type === 'length')?.time

  let totalHours = 0

  const workHourLength = workHourEnd-workHourStart

  totalHours += workHourLength

  if (breakHourLength) {totalHours -= timeToFloat(breakHourLength)}

  const relevantMeetings = odv(meetings).filter(meeting => 
    meeting.weekDay === days[dayId].weekDay 
    && meeting.weekNr === week.weekNr
    && meeting.peopleIds.includes(people[week.personId].id)
  )


  relevantMeetings.forEach(meeting => {
    // This does the crucial assumption that the same person can not be in two different different meetings at the same time
    totalHours += calculateTimeOutside(workHourStart, workHourEnd, timeToFloat(meeting.time), timeToFloat(meeting.length))
  })

  // if (dayId === 48 || dayId === 13 ) {
  //   log("recieved this dayid and days: ", dayId, days[dayId], totalHours)
  // }
  return totalHours    
}

export const autofillThirty = (state, dispatch, dayId) => {
  log("starting autofill 30")
  if (state.autofillThirty) {
    log("is autofill")

    const weekDaySettings = state.weekDaySettings || {
        'Måndag': true,
        'Tisdag': true,
        'Onsdag': true,
        'Torsdag': true,
        'Fredag': true,
    }

    const daySetting = weekDaySettings[state.days[dayId].weekDay]
    
    const workHourStart = Object.values(state.workHours).find(wH => wH.dayId === dayId && wH.type === 'start')?.time
    const workHourEnd = Object.values(state.workHours).find(wH => wH.dayId === dayId && wH.type === 'end')?.time

    if (workHourStart && workHourEnd) {
      log("is both")
      const dayLength = timeToFloat(workHourEnd) - timeToFloat(workHourStart)

      if (dayLength >= 8.5){
        const breakHourLength = Object.values(state.breakHours).find(bH => bH.dayId === dayId && bH.type === 'length')?.time

        if (!breakHourLength){
          if (daySetting) {
            const relevantDays = Object.values(state.days).filter(day => state.weeks[day.weekId].personId === state.weeks[state.days[dayId].weekId].personId && day.weekDay === state.days[dayId].weekDay)
              Object.values(relevantDays).forEach(day => {
                log("dispatching for : ", day)
                dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: '00:30', dayId: day.id, type: 'length'} });
                // dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: 'Ja', dayId: day.id, type: 'start'} });
            })
          } else {
            dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: '00:30', dayId: dayId, type: 'length'} });
            // dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: 'Ja', dayId: dayId, type: 'length'} });
          }
        }
      }
    } else {
      log("is not both")
    }
    
  }
}

export const sortDictionary = (dictionary, sortKey) => {
  log ("dictionary currently: ", dictionary)
  const sortedEntries = Object.entries(dictionary).sort(([, a], [, b]) => timeToFloat(a[sortKey]) - timeToFloat(b[sortKey]));
  
  log ("sorted entries: ", sortedEntries)
  const sortedById = sortedEntries.reduce((acc, [, value]) => {
    acc[value.id] = value;
    return acc;
  }, {});
  
  log ("dictionary after: ", sortedById)
  return sortedById
}


const getSortKey = (object) => {
  if (object && object.hasOwnProperty('time')) {
    return 'time';
  } else if (object && object.hasOwnProperty('start')) {
    return 'start';
  } else if (object && object.hasOwnProperty('weekNr')) {
    return 'weekNr'
  }
  return null;
};

const sortObjects = (objects) => {
  const valuesArray = Object.values(objects);  // Convert the object of objects into an array
  const sortKey = getSortKey(valuesArray[0]);  // Detect the sort key based on the first object

  if (!sortKey) return valuesArray;  // If no `time` or `start` key exists, return objects unsorted

  return valuesArray.sort((a, b) => {
    return timeToFloat(a[sortKey]) - timeToFloat(b[sortKey]);
  });
};

const sortWeekObjects = (objects) => {
  const valuesArray = Object.values(objects);  // Convert the object of objects into an array
  const sortKey = getSortKey(valuesArray[0]);  // Detect the sort key based on the first object

  if (!sortKey) return valuesArray;  // If no `time` or `start` key exists, return objects unsorted

  return valuesArray.sort((a, b) => {
    return a[sortKey] - b[sortKey];
  });
};

export const odvs = (objects) => {
  return odv(sortObjects(objects))
}

export const odvsw = (objects) => {
  return odv(sortWeekObjects(objects))
}

export const getUniquePeopleObjets = (data) => {
  const seenNames = new Set();
  const uniqueObjects = Object.keys(data).reduce((acc, key) => {
    const obj = data[key];
    if (!seenNames.has(obj.name)) {
      seenNames.add(obj.name);
      acc[key] = obj;
    }
    return acc;
  }, {});

  // Sort by object names
  return Object.fromEntries(
    Object.entries(uniqueObjects).sort(([, a], [, b]) => a.name.localeCompare(b.name))
  );
}



// Helper function to close the top-most open modal

//Yes i know this is horrible and it should be remade into a modal stack component or something but its wasted time if this works
export const closeTopModal = (state, dispatch) => {
  // Array of modals in rendering order (last in this array is top-most)
  const modals = [
    { isOpen: state.showAddDepartment, action: 'TOGGLE_ADD_DEPARTMENT' },
    { isOpen: state.showAddPerson, action: 'TOGGLE_ADD_PERSON' },
    { isOpen: state.showAddCategory, action: 'TOGGLE_SHOW_ADD_CATEGORY' },
    { isOpen: state.showRemoveCategory, action: 'TOGGLE_SHOW_REMOVE_CATEGORY' },
    { isOpen: state.showEditCategories, action: 'TOGGLE_SHOW_EDIT_CATEGORIES' },
    { isOpen: state.showEditPersonName, action: 'TOGGLE_SHOW_EDIT_PERSON_NAME' },
    { isOpen: state.showWorkHourEditor, action: 'TOGGLE_SHOW_WORKHOUR_EDITOR' },
    { isOpen: state.showRemovePerson, action: 'TOGGLE_SHOW_REMOVE_PERSON' },
    { isOpen: state.showRemoveAdditionalHour, action: 'TOGGLE_SHOW_REMOVE_ADDITIONAL_HOUR' },
    { isOpen: state.showRemoveMeeting, action: 'TOGGLE_SHOW_REMOVE_MEETING' },
    { isOpen: state.showMeetingAHPopup, action: 'TOGGLE_SHOW_MEETINGAH_POPUP' },
    { isOpen: state.showDepartmentInfo, action: 'TOGGLE_SHOW_DEPARTMENT_INFO' },
    { isOpen: state.showPersonSettings, action: 'TOGGLE_SHOW_PERSON_SETTINGS' },
    { isOpen: state.showPersonInfo, action: 'TOGGLE_SHOW_PERSON_INFO' },
    { isOpen: state.showEditDepartmentName, action: 'TOGGLE_SHOW_EDIT_DEPARTMENT_NAME' },
    { isOpen: state.showRemoveDepartment, action: 'TOGGLE_SHOW_REMOVE_DEPARTMENT' },
    { isOpen: state.showRemoveData, action: 'TOGGLE_SHOW_REMOVE_DATA' },
    { isOpen: state.showLogin, action: 'TOGGLE_SHOW_LOGIN' },
    { isOpen: state.showSave, action: 'TOGGLE_SHOW_SAVE' },
    { isOpen: state.showInfo, action: 'TOGGLE_SHOW_INFO' },
    { isOpen: state.showHelp, action: 'TOGGLE_SHOW_HELP' },
    { isOpen: state.showExport, action: 'TOGGLE_SHOW_EXPORT' },
    { isOpen: state.showAddMeetingAH, action: 'TOGGLE_SHOW_ADD_MEETINGAH' },
    { isOpen: state.showMeetingAndAHAndWH, action: 'TOGGLE_SHOW_MEETINGANDAHANDWH' },
    { isOpen: state.showStatistics, action: 'TOGGLE_SHOW_STATISTICS' },
    { isOpen: state.showPublicize, action: 'TOGGLE_SHOW_PUBLICIZE' },
    { isOpen: state.showAllCharts, action: 'TOGGLE_SHOW_ALL_CHARTS' },
    { isOpen: state.showScheduleSettings, action: 'TOGGLE_SHOW_SCHEDULE_SETTINGS' },
  ];

  // Find the first open modal from the top (reverse order)
  const topModal = modals.find(modal => modal.isOpen);

  log("found top modal..", topModal)

  // If a modal is open, close it
  if (topModal) {
    dispatch({ type: topModal.action });
  }
};

export const checkNoModalOpen = (state) => {
  const modals = [
    { isOpen: state.showAddCategory, action: 'TOGGLE_SHOW_ADD_CATEGORY' },
    { isOpen: state.showRemoveCategory, action: 'TOGGLE_SHOW_REMOVE_CATEGORY' },
    { isOpen: state.showEditCategories, action: 'TOGGLE_SHOW_EDIT_CATEGORIES' },
    { isOpen: state.showEditPersonName, action: 'TOGGLE_SHOW_EDIT_PERSON_NAME' },
    { isOpen: state.showWorkHourEditor, action: 'TOGGLE_SHOW_WORKHOUR_EDITOR' },
    { isOpen: state.showRemovePerson, action: 'TOGGLE_SHOW_REMOVE_PERSON' },
    { isOpen: state.showRemoveAdditionalHour, action: 'TOGGLE_SHOW_REMOVE_ADDITIONAL_HOUR' },
    { isOpen: state.showRemoveMeeting, action: 'TOGGLE_SHOW_REMOVE_MEETING' },
    { isOpen: state.showMeetingAHPopup, action: 'TOGGLE_SHOW_MEETINGAH_POPUP' },
    { isOpen: state.showDepartmentInfo, action: 'TOGGLE_SHOW_DEPARTMENT_INFO' },
    { isOpen: state.showPersonSettings, action: 'TOGGLE_SHOW_PERSON_SETTINGS' },
    { isOpen: state.showPersonInfo, action: 'TOGGLE_SHOW_PERSON_INFO' },
    { isOpen: state.showEditDepartmentName, action: 'TOGGLE_SHOW_EDIT_DEPARTMENT_NAME' },
    { isOpen: state.showRemoveDepartment, action: 'TOGGLE_SHOW_REMOVE_DEPARTMENT' },
    { isOpen: state.showRemoveData, action: 'TOGGLE_SHOW_REMOVE_DATA' },
    { isOpen: state.showLogin, action: 'TOGGLE_SHOW_LOGIN' },
    { isOpen: state.showSave, action: 'TOGGLE_SHOW_SAVE' },
    { isOpen: state.showInfo, action: 'TOGGLE_SHOW_INFO' },
    { isOpen: state.showHelp, action: 'TOGGLE_SHOW_HELP' },
    { isOpen: state.showExport, action: 'TOGGLE_SHOW_EXPORT' },
    { isOpen: state.showAddMeetingAH, action: 'TOGGLE_SHOW_ADD_MEETINGAH' },
    { isOpen: state.showMeetingAndAHAndWH, action: 'TOGGLE_SHOW_MEETINGANDAHANDWH' },
    { isOpen: state.showAddDepartment, action: 'TOGGLE_ADD_DEPARTMENT' },
    { isOpen: state.showAddPerson, action: 'TOGGLE_ADD_PERSON' },
    { isOpen: state.showStatistics, action: 'TOGGLE_SHOW_STATISTICS' },
    { isOpen: state.showPublicize, action: 'TOGGLE_SHOW_PUBLICIZE' },
    { isOpen: state.showAllCharts, action: 'TOGGLE_SHOW_ALL_CHARTS' }
  ];

  // Find the first open modal from the top (reverse order)
  const topModal = modals.find(modal => modal.isOpen);

  log ("returning :", topModal)
  // If a modal is open, close it
  if (topModal) {
    return false
  } else { return true }
}



export const PersonIcon = ({ size = 18, color = 'rgb(83, 149, 255)', showPersonSettings }) => (
  <div  style={{cursor:'pointer', display:'flex', alignItems:'center'}} onClick={showPersonSettings}>
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="7" r="4.5" stroke={color} strokeWidth="2" />
      <path
        d="M4 19C4 15.5 7.5 14 12 14C16.5 14 20 15.5 20 19"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </div>
  
);