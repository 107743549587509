import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';
import DaySelectorDropdown from '../assets/assets/dropdowns/day selector dropdown/DaySelectorDropdown';
import WeekSelectorDropdown from '../assets/assets/dropdowns/week selector dropdown/WeekSelectorDropdown';
import MultiSelect from '../assets/assets/dropdowns/multiselect dropdown/Multiselect';
import { odv, log, validateAndFormatTime, timeToFloat, getUniquePeopleObjets } from '../utils/Utils';
import PersonDropdown from '../assets/assets/dropdowns/person dropdown/PersonDropdown';
import AHDropdown from '../assets/assets/dropdowns/AH dropdown/AHDropdown';

const MeetingAndAHPopup = () => {
  const { state, dispatch } = useAppContext();
  const { weeks, days, meetings, objectId, additionalHours, selectedPerson, weekDaySettings} = state
  const [newPersonName, setNewPersonName] = useState('');
  const inputRef = useRef(null);

  const isMeeting = state.selectedButton === 'Möten'
  const isAH = state.selectedButton === 'Barnfri tid' // Unneccesary extra logic incase I add more later
  
  const [selectedPeople, setSelectedPeople] = useState(isMeeting ? meetings[objectId]?.peopleIds : additionalHours[objectId])
  const [showError, setShowError] = useState(false)
  const [selectedWeekNr, setSelectedWeekNr] = useState(state.selectedWeekNr)
  const [selectedDay, setSelectedDay] = useState(state.pressedDayButton)

  const [meetingStartTime, setMeetingStartTime] = useState(isMeeting ? meetings[objectId]?.time : additionalHours[objectId]?.start);
  const [meetingLength, setMeetingLength] = useState(isMeeting ? meetings[objectId]?.length : additionalHours[objectId]?.length);
  const [finalMeetingStartTime, setFinalMeetingStartTime] = useState(isMeeting ? meetings[objectId]?.time : additionalHours[objectId]?.start);
  const [finalMeetingLength, setFinalMeetingLength] = useState(isMeeting ? meetings[objectId]?.length : additionalHours[objectId]?.length);

  const [selectedCategory, setSelectedCategory] = useState(isAH ? additionalHours[objectId]?.category : 'PUT');
  const [selectedPersonId, setSelectedPersonId] = useState(weeks[days[additionalHours[objectId]?.dayId]?.weekId]?.personId || selectedPerson)


  const daySetting = weekDaySettings[selectedDay]

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     inputRef.current.focus();
  //   }, 10); // Delay the focus by 100 milliseconds
  
  //   return () => clearTimeout(timer); // Clean up the timer
  // }, []);

  // const handleKeyDown = (event) => {
  //   if (event.key === 'Enter') {
  //     addPerson();
  //   }
  // };

  const handleKeyDown = (event, type) => {
    if (event.key === 'Enter' || event.type === 'blur') {
      const formattedTime = validateAndFormatTime(event.target.value);
      if (formattedTime) {
        if (type === 'start') {
          setFinalMeetingStartTime(formattedTime);
          setMeetingStartTime(formattedTime);
        } else {
          setFinalMeetingLength(formattedTime);
          setMeetingLength(formattedTime);
        }
      }
    } else {
      let newTime = null
      if (type === 'start') {
        setFinalMeetingStartTime(newTime)
      } else {
        setFinalMeetingLength(newTime)
      }
    }
  };

  const addPerson = () => {
    if (newPersonName){
      dispatch({
        type: 'ADD_PERSON',
        payload: { name: newPersonName}
      });
    }
    dispatch({ type: 'TOGGLE_ADD_PERSON', payload: {
      setToShow: false
    } })
    setNewPersonName('');
  };

  const titleWording = isMeeting ? 'möte' : (isAH ? 'barnfri tid' : '')
  const middleWording = isMeeting ? 'möte' : (isAH ? 'barnfri tid' : '')

  const options = [
    { id: 1, name: 'Electronics & Computers' },
    { id: 2, name: 'Home, Garden & Tools' },
    { id: 3, name: 'Books' },
    { id: 4, name: 'Movies, Music & Games' },
    { id: 5, name: 'Health & Beauty' },
    { id: 6, name: 'Toys, Kids & Baby' },
    { id: 7, name: 'Clothing & Jewelry' },
  ];

  const handleChange = (selectedOptions) => {
    let selectedPeopleIds = []

    selectedOptions.forEach(person => selectedPeopleIds.push(person.id))

    setSelectedPeople(selectedPeopleIds)
    setShowError(false)
  };

  const close = () => {
    setShowError(false)
    dispatch({ type: 'TOGGLE_SHOW_ADD_MEETINGAH', payload: {
      setToShow: false
    } })
  }

  const removeObject = () => {
    if (isMeeting){
      dispatch({ type: 'TOGGLE_SHOW_REMOVE_MEETING', payload: {
        setToShow: true,
        meetingId: objectId,
      } })
    } else if (isAH) {
      dispatch({ type: 'TOGGLE_SHOW_REMOVE_ADDITIONAL_HOUR', payload: {
        setToShow: true,
        additionalHourId: objectId,
      } })
    }
    

    // dispatch({
    //   type: 'REMOVE_MEETING',
    //   payload: {
    //     meetingId: objectId}
    // })
  }

  const addMeetingAH = () => {
    if (odv(selectedPeople).length === 0) {
      setShowError(true)
    } else {
      if (isMeeting) {
        if (!(finalMeetingStartTime && finalMeetingLength)) {
          setShowError('Fyll först i start och längd på den barnfria tiden')
        } else {
          let peopleIds = []

          odv(selectedPeople).forEach(person => peopleIds.push(person.id))
    
          if (daySetting) {
            const weeksArray = Array.from({ length: state.numberOfWeeks }, (_, index) => index + 1);
            odv(weeksArray).forEach(weekNr => {
              dispatch({ // Create replacement meeting
                type: 'CREATE_MEETING',
                payload: {
                  weekNr: weekNr,
                  weekDay: selectedDay,
                  peopleIds: selectedPeople, // sum aint right with the logic here but whatever xd
                  time: finalMeetingStartTime, 
                  length: finalMeetingLength}
              })
    
              // Remove old meeting
              const editedMeeting = meetings[objectId]

              const relevantOldMeeting = odv(meetings).find(
                  meeting => meeting.weekNr === weekNr 
                && meeting.weekDay === editedMeeting.weekDay 
                && meeting.time === editedMeeting.time 
                && meeting.length === editedMeeting.length 
                && meeting.peopleIds === editedMeeting.peopleIds )

              if (relevantOldMeeting){
                dispatch({
                  type: 'REMOVE_MEETING',
                  payload: {
                    meetingId: relevantOldMeeting.id}
                })
              }
              
    
              dispatch({
                type: 'TOGGLE_SHOW_MEETINGAH_POPUP', payload: {
                setToShow: false,
                }
              })
        
              setShowError(false)
              dispatch({ type: 'TOGGLE_SHOW_ADD_MEETINGAH', payload: {
                setToShow: false
              } })
  
            })
          } else {
            dispatch({
              type: 'CREATE_MEETING',
              payload: {
                weekNr: selectedWeekNr,
                weekDay: selectedDay,
                peopleIds: selectedPeople, // sum aint right with the logic here but whatever xd
                time: finalMeetingStartTime, 
                length: finalMeetingLength}
            })
  
            dispatch({
              type: 'REMOVE_MEETING',
              payload: {
                meetingId: objectId}
            })
  
            dispatch({
              type: 'TOGGLE_SHOW_MEETINGAH_POPUP', payload: {
              setToShow: false,
              }
            })
      
            setShowError(false)
            dispatch({ type: 'TOGGLE_SHOW_ADD_MEETINGAH', payload: {
              setToShow: false
            } })
          }
        }
      } else if (isAH) {

        if (!(finalMeetingStartTime && finalMeetingLength)) {
          setShowError('Fyll först i start och längd på den barnfria tiden')
        } else {

          if (daySetting) {
            const relevantDays = odv(days).filter(day => weeks[day.weekId].personId === selectedPersonId && day.weekDay === selectedDay)

            

            odv(relevantDays).forEach(day => {
              // Remove old AH
              const editedAH = additionalHours[objectId]

              const relevantOldAH = odv(additionalHours).find(
                  aH => aH.dayId === day.id 
                && aH.start === editedAH.start 
                && aH.length === editedAH.length 
                && aH.category === editedAH.category )
                
              if (relevantOldAH){
                dispatch({
                  type: 'REMOVE_ADDITIONAL_HOUR',
                  payload: {
                  additionalHourId: relevantOldAH.id}
                })
              }
              
              dispatch({
                type: 'ADD_ADDITIONAL_HOUR',
                payload: { category: selectedCategory, dayId: day.id, start:finalMeetingStartTime, lengthh:finalMeetingLength}
              }); // Do for all days
            })

            dispatch({
              type: 'TOGGLE_SHOW_MEETINGAH_POPUP', payload: {
              setToShow: false,
              }
            });
            close()

          } else {
            const dayId = odv(days).find(day => weeks[day.weekId].personId === selectedPersonId 
              && weeks[day.weekId].weekNr === selectedWeekNr
              && day.weekDay === selectedDay).id
    
            dispatch({
              type: 'REMOVE_ADDITIONAL_HOUR',
              payload: {
              additionalHourId: objectId}
            })
  
            dispatch({
              type: 'ADD_ADDITIONAL_HOUR',
              payload: { category: selectedCategory, dayId: dayId, start:finalMeetingStartTime, lengthh:finalMeetingLength}
            });
  
            dispatch({
              type: 'TOGGLE_SHOW_MEETINGAH_POPUP', payload: {
              setToShow: false,
              }
            });
            close()
          }
          
        }
      }
    }
    
  }
  return (
    <div style={{maxWidth:'410px', zIndex:'1200'}}>
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <p style={{fontSize:'24px', marginBottom:'10px'}}>Redigera {titleWording}</p>
        <button className={buttonStyles['secondaryButtonRemove']} onClick={() => removeObject()}>
          <svg height="25px" width="25px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlnsXlink="http://www.w3.org/1999/xlink" enableBackground="new 0 0 512 512">
            <g>
              <g fill="#f11" stroke="#f11" strokeWidth="3">
                <path d="m450.9,73.5h-118.4v-52.1c0-5.8-4.7-10.4-10.4-10.4h-132.2c-5.8,0-10.4,4.7-10.4,10.4v52.1h-118.4c-5.8,0-10.4,4.7-10.4,10.4v64.9c0,5.8 4.7,10.4 10.4,10.4h24.6v264.7c0,42.5 34.6,77.1 77.1,77.1h186.4c42.5,0 77.1-34.6 77.1-77.1v-264.6h24.6c5.8,0 10.4-4.7 10.4-10.4v-65c-5.68434e-14-5.7-4.7-10.4-10.4-10.4zm-250.5-41.6h111.3v41.7h-111.3v-41.7zm205,392c0,31-25.2,56.2-56.2,56.2h-186.4c-31,0-56.2-25.2-56.2-56.2v-264.6h298.8v264.6zm35-285.5h-368.8v-44h368.9v44z"/>
                <path d="m164.1,427c5.8,0 10.4-4.7 10.4-10.4v-193.7c0-5.8-4.7-10.4-10.4-10.4-5.8,0-10.4,4.7-10.4,10.4v193.7c0,5.7 4.7,10.4 10.4,10.4z"/>
                <path d="M256,427c5.8,0,10.4-4.7,10.4-10.4V222.9c0-5.8-4.7-10.4-10.4-10.4s-10.4,4.7-10.4,10.4v193.7    C245.6,422.3,250.2,427,256,427z"/>
                <path d="m347.9,427c5.8,0 10.4-4.7 10.4-10.4v-193.7c0-5.8-4.7-10.4-10.4-10.4-5.8,0-10.4,4.7-10.4,10.4v193.7c-0.1,5.7 4.6,10.4 10.4,10.4z"/>
              </g>
            </g>
          </svg>
        </button>
      </div>
      
      {
        isMeeting && <div style={{display:'flex', flexDirection:'column', gap: '10px'}}>
        <div style={{display:'flex', alignItems:'center', gap:'5px'}}>
          {/* <p>Lägg till {middleWording} på </p> */}
          {/* <AHDropdown onChange={setSelectedCategory} initialCategory={selectedCategory}></AHDropdown> */}
          <p style={{whiteSpace:'nowrap'}}>Möte på {selectedDay.toLowerCase()}</p>
          {/* <DaySelectorDropdown onChange={setSelectedDay}></DaySelectorDropdown>
          <p> vecka </p>
          <WeekSelectorDropdown onChange={setSelectedWeekNr}></WeekSelectorDropdown>
          <p>för</p> */}
        </div>
        <MultiSelect selectedPeople={odv(state.people).filter(person => selectedPeople?.includes(person.id))} options={odv(getUniquePeopleObjets(state.people))} onChange={handleChange} />
        <div style={{display:'flex', alignItems:'center', gap:'5px'}}>
            <p>Klockan</p>
            <input
                className={inputStyles['input-30']}
                value={meetingStartTime}
                onChange={e => setMeetingStartTime(e.target.value)}
                onKeyDown={e => handleKeyDown(e, 'start')}
                onBlur={(e) => handleKeyDown(e, 'start')}
                placeholder="hh:mm"
            />
            <p style={{whiteSpace:'nowrap'}}>med längd</p>
            <input
                className={inputStyles['input-30']}
                value={meetingLength}
                onChange={e => setMeetingLength(e.target.value)}
                onKeyDown={e => handleKeyDown(e, 'end')}
                onBlur={(e) => handleKeyDown(e, 'end')}

                placeholder="hh:mm"
                // style={{width:'90px'}}
            />
        </div>
</div>
      }
      {
        isAH && <div style={{display:'flex', flexDirection:'column', gap: '10px'}}>
                  <div style={{display:'flex', alignItems:'center', gap:'5px'}}>
                    {/* <p>Lägg till {middleWording} på </p> */}
                    <AHDropdown onChange={setSelectedCategory} initialCategory={selectedCategory}></AHDropdown>
                    <p>på {selectedDay.toLowerCase()} för</p>
                    {/* <DaySelectorDropdown onChange={setSelectedDay}></DaySelectorDropdown>
                    <p> vecka </p>
                    <WeekSelectorDropdown onChange={setSelectedWeekNr}></WeekSelectorDropdown>
                    <p>för</p> */}
                  </div>
                  <div style={{display:'flex', alignItems:'center', gap:'5px'}}>
                  
            
                      <PersonDropdown onChange={setSelectedPersonId}></PersonDropdown>

                      <p>Klockan</p>
                      <input
                          className={inputStyles['input-30']}
                          value={meetingStartTime}
                          onChange={e => setMeetingStartTime(e.target.value)}
                          onKeyDown={e => handleKeyDown(e, 'start')}
                          onBlur={(e) => handleKeyDown(e, 'start')}
                          placeholder="hh:mm"
                      />
                      <p style={{whiteSpace:'nowrap'}}>med längd </p>
                      <input
                          className={inputStyles['input-30']}
                          value={meetingLength}
                          onChange={e => setMeetingLength(e.target.value)}
                          onKeyDown={e => handleKeyDown(e, 'end')}
                          onBlur={(e) => handleKeyDown(e, 'end')}

                          placeholder="hh:mm"
                          // style={{width:'90px'}}
                      />
                  </div>
          </div>
      }
      {
        showError && <div style={{width:'350px'}}>
            <p>
              {showError}
            </p>
          </div>
      } 
      <div style={{display:'flex', justifyContent:'right', marginTop:'10px'}}>
        <button className={buttonStyles['secondaryButton']} onClick={() => dispatch({ type: 'TOGGLE_SHOW_MEETINGAH_POPUP', payload: {
          setToShow: false
        } })}>
          Avbryt
        </button>
        <button className={buttonStyles['button-30']} onClick={addMeetingAH}>Spara</button> 
      </div>
    
    </div>
  )
}

export default MeetingAndAHPopup