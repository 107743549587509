import React, { useState, useReducer, useEffect } from 'react';
import ExcelExport from '../excel/ExportComponent2';
import { useAppContext } from '../contexts/AppContext';
import { useLocation, useNavigate  } from 'react-router-dom';
import axios from 'axios';
import { DecompressAndDecodeState } from '../utils/Compressor';
import { calculateDayTotalHours, findPeopleWithSameName, log, odv, timeToFloat, weekDays } from '../utils/Utils';
import RoundedDiv from '../assets/assets/rounded div/RoundedDiv';
import Switch from '../assets/assets/settings icon/Switch';
import buttonStyles from '../styles/StyledButton.module.css';
import styles from './PublicizeApp.module.css'
import ModalLight from '../modal/ModalLight';
import IndividualStatisticsPublicize from './IndividualStatisticsPublicize';

export const PersonSummary = ({personId}) => {
    const { state, dispatch } = useAppContext();
    const { people, weeks, days, departments, selectedPerson, selectedWeekId, selectedWeekNr, workHours, meetings, additionalHours,
        selectedDepartment, selectedDay, selectedStatisticsButton } = state

    return (
        <div>
            <IndividualStatisticsPublicize selectedPersonId={personId}></IndividualStatisticsPublicize>
        </div>
    )

}
