import React, { createContext, useContext, useMemo } from 'react';
import { useAppContext } from './AppContext';

const DepartmentContext = createContext();

export const DepartmentProvider = ({ departmentId, children }) => {
  const { state, dispatch } = useAppContext();
  const contextValue = useMemo(() => ({ state, dispatch }), [state]);

  const department = state.departments[departmentId]; //TODO change to {department, dispatch}

  return (
    <DepartmentContext.Provider value={contextValue}> 
      {children}
    </DepartmentContext.Provider>
  );
};

export const useDepartmentContext = () => {
  return useContext(DepartmentContext);
};

export const useDepartment = () => useContext(DepartmentContext);

