import React, { useEffect, createContext, useReducer, useContext, useMemo, useRef, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { log, getRandomGoodLookingColor, odv, weekDays } from '../utils/Utils';
import AdditionalHour from '../day/AdditionalHour';
import { clear } from '@testing-library/user-event/dist/clear';
import { sendStateToServer } from '../modal/LoginForm';

const addDefaultDays = (weekId, state, dayNr) => {
    const weekDayList = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag'];
    const newDays = {};
    let dayNrr = dayNr;
    weekDayList.forEach(weekDay => {
        const newDayId = dayNrr + 1;
        dayNrr++;
        newDays[newDayId] = {
            id: newDayId,
            weekId: weekId,
            weekDay: weekDay,
        };
    });
    return {defaultDays: newDays, newDayNr: dayNrr};
};



export const initialStatee = {
    departments: {
      1: {
        id: 1,
        name: 'Kotten',
        color: getRandomGoodLookingColor(),
      }
    },
    people: {
      1: {
        id: 1,
        name: 'Helena',
        departmentId: 1,
        targetHours: [
          [
            'workHours', 40, 1   // 1 = hours per week, 2 = hours in total
          ],
          [
            'meetings', 40, 1   // 1 = hours per week, 2 = hours in total
          ],
          [
            'PUT', 40, 1   // 1 = hours per week, 2 = hours in total
          ],
        ]
      }
    },
    weeks: {
      1: {
        id: 1,
        personId: 1,
        weekNr: 1,
      }
    },
    days: {
      
      1: {
        id: 1,
        weekId: 1,
        weekDay: 'Måndag',
      },
      2: {
        id: 2,
        weekId: 1,
        weekDay: 'Tisdag',
      },
      3: {
        id: 3,
        weekId: 1,
        weekDay: 'Onsdag',
      },
      4: {
        id: 4,
        weekId: 1,
        weekDay: 'Torsdag',
      },
      5: {
        id: 5,
        weekId: 1,
        weekDay: 'Fredag',
      },
    },
    workHours: {
        // 0: {
        //     id: 0,
        //     time: "07:00",
        //     dayId: 1,
        //     type: 'start'
        // },
        // 1: {
        //     id: 1,
        //     time: "15:30",
        //     dayId: 1,
        //     type: 'end'
        // },
    },
    breakHours: {
        // 1: {
        //     id: 1,
        //     time: "10:00",
        //     dayId: 1,
        //     type: 'start'
        // },
        // 2: {
        //     id: 2,
        //     time: "00:30",
        //     dayId: 1,
        //     type: 'length'
        // },
    },
    // putHours: {
    //     '121212121': {
    //         id: '121212121',
    //         time: "15:00",
    //         dayId: 'day1',
    //         type: 'start'
    //     },
    //     '123123123': {
    //         id: '123123123',
    //         time: "00:30",
    //         dayId: 'day1',
    //         type: 'length'
    //     },
    // },
    meetings: {
      // '8889-123-123-123': {
      //   id: '8889-123-123-123',
      //   weekDay: 'Måndag', //Think this is changed to 'Må'
      //   weekNr: 1,
      //   time: "18:00",
      //   length: "1:00",
      //   peopleIds: [
      //     'person1'
      //   ]
      // }
    },
    categories: [
      'PUT', 'Bf. t', 'Övr.'
    ],
    additionalHours: {
      // '123413241234': {
      //       id: '123413241234',
      //       dayId: 'day1',
      //       start: "15:30",
      //       length: "00:30",
      //       category: 'Bf. t',
      //   },
    },
    selectedDepartment: 1,
    selectedPerson: 1,
    selectedWeekId: 1,
    selectedWeekNr: 1,
    numberOfWeeks: 1,
    showAddPerson: false,
    showAddDepartment: false,
    showExport: false,
    showSave: false,
    showHelp: false,
    showInfo: false,
    showLogin: false,
    showPersonInfo: false,
    showDepartmentInfo: false,
    showRemoveData: false,
    showRemoveMeeting: false,
    showRemoveAdditionalHour: false,
    showRemovePerson: false,
    showPersonSettings: false,
    showRemoveDepartment: false,
    showAddMeetingAH: false,
    showMeetingAHPopup: false,
    showStatistics: false,
    showWorkHourEditor: false,
    showPublicize: false,
    showAllCharts: false,
    showEditDepartmentName: false,
    showEditPersonName: false,
    showMeetingAndAHAndWH: false, //todo compress
    showAddCategory: false, //todo compress
    showRemoveCategory: false, //todo compress
    showEditCategories: false, //todo compress
    showScheduleSettings: false,//todo compress
    selectedDay: 'Måndag',
    selectedButton:'Möten',
    removeMeetingId: null,
    removeAdditionalHourId: null,
    removeDepartmentId: null,
    deptNr: 1,
    peopleNr: 1,
    weekNr: 1,
    dayNr: 5,
    whNr: 3,
    bhNr: 3,
    ahNr: 1,
    mNr: 1,
    userId: null,
    token: null,
    autosync: 'av',
    loggedIn: false,
    autofillThirty: false,
    pressedDayButton: null,
    selectedStatisticsButton: 'Förskola',
    selectedSchoolStatisticsButton: 'Timmar',
    editWorkHourDayId: null,
    editDepartmentId: null,
    editPersonNameId: null,
    publicizedLinks: [],
    categoryToRemove: null,
    weekDaySettings: {
        'Måndag': true,
        'Tisdag': true,
        'Onsdag': true,
        'Torsdag': true,
        'Fredag': true,
    }
  };
  

// Initial state
const initialState = JSON.parse(localStorage.getItem('appState')) || initialStatee

const newDepartmentId = (state) => {
  return odv(state.departments).length
}
// Reducer function to handle state transitions
const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_DEPARTMENT':
      const departmentId = state.deptNr + 1;
      return {
        ...state,
        selectedDepartment: departmentId,
        selectedPerson: null,
        selectedWeekId: null,
        deptNr: state.deptNr +1,
        departments: {
          ...state.departments,
          [departmentId]: {
            id: departmentId,
            name: action.payload.name,
            color: getRandomGoodLookingColor(),
          }
        }
      };
      case 'REMOVE_DEPARTMENT': {
        const departmentId = action.payload?.departmentId;
        log("got dept id: ", departmentId)
    
        // Get the people in the department
        const peopleInDepartment = Object.values(state.people).filter(person => person.departmentId === departmentId);
        
        // Collect all personIds to be removed
        const removedPersonIds = peopleInDepartment.map(person => person.id);

        log("peopleindepartment: ", peopleInDepartment, "removed personids: ", removedPersonIds)
    
        // Remove people in the department from state
        const remainingPeople = Object.fromEntries(
            Object.entries(state.people).filter(([key, person]) => !removedPersonIds.includes(person.id))
        );

        log("remaining people: ", remainingPeople)
    
        // Collect all weekIds associated with the people in the department
        const removedWeekIds = Object.keys(state.weeks).filter(weekId => 
            removedPersonIds.includes(state.weeks[weekId].personId)
        );
    
        // Remove weeks associated with the removed personIds
        const remainingWeeks = Object.fromEntries(
            Object.entries(state.weeks).filter(([weekId]) => !removedWeekIds.includes(weekId))
        );
    
        // Collect all dayIds associated with the removed weeks
        const removedDayIds = Object.keys(state.days).filter(dayId => 
            removedWeekIds.includes(String(state.days[dayId].weekId))
        );
    
        // Remove days associated with the removed weeks
        const remainingDays = Object.fromEntries(
            Object.entries(state.days).filter(([dayId]) => !removedDayIds.includes(dayId))
        );
    
        // Remove work hours associated with the removed days
        const remainingWorkHours = Object.fromEntries(
            Object.entries(state.workHours).filter(([_, workHour]) => !removedDayIds.includes(String(workHour.dayId)))
        );
    
        // Remove break hours associated with the removed days
        const remainingBreakHours = Object.fromEntries(
            Object.entries(state.breakHours).filter(([_, breakHour]) => !removedDayIds.includes(String(breakHour.dayId)))
        );
    
        // Remove additional hours associated with the removed days
        const remainingAdditionalHours = Object.fromEntries(
            Object.entries(state.additionalHours).filter(([_, additionalHour]) => !removedDayIds.includes(String(additionalHour.dayId)))
        );
    
        // Update meetings to remove the personIds from peopleIds array
        const remainingMeetings = Object.fromEntries(
            Object.entries(state.meetings).map(([meetingId, meeting]) => {
                const updatedPeopleIds = meeting.peopleIds.filter(id => !removedPersonIds.includes(id));
                return [meetingId, { ...meeting, peopleIds: updatedPeopleIds }];
            })
        );
    
        // Filter out empty meetings (with no people left)
        const updatedMeetings = Object.fromEntries(
            Object.entries(remainingMeetings).filter(([_, meeting]) => meeting.peopleIds.length > 0)
        );
    
        // Remove the department itself
        const remainingDepartments = Object.fromEntries(
            Object.entries(state.departments).filter(([id]) => id !== String(departmentId))
        );

        log("remainin gdeparmtents is: ", remainingDepartments)
    
        const newSelectedDepartment = odv(remainingDepartments).find(department => department)?.id
        const newSelectedPerson = odv(remainingPeople).find(person => person.departmentId === newSelectedDepartment)?.id
        const newSelectedWeekId = odv(remainingWeeks).find(week => 
          week.weekNr === state.selectedWeekNr && week.personId === newSelectedPerson)
        return {
            ...state,
            people: remainingPeople,
            weeks: remainingWeeks,
            days: remainingDays,
            workHours: remainingWorkHours,
            breakHours: remainingBreakHours,
            additionalHours: remainingAdditionalHours,
            meetings: updatedMeetings,
            departments: remainingDepartments, // Updated departments without the removed one
            selectedDepartment: newSelectedDepartment,
            selectedPerson: newSelectedPerson,
            selectedWeekId: newSelectedWeekId,
        }
      }
    
    case 'ADD_PERSON':{
      let newDays = { ...state.days };
      const personId = state.peopleNr +1;
      function generateNewWeeks (personId) {
        const newWeeks = { ...state.weeks };
        let dayNr = state.dayNr;
        for (let i = 1; i <= state.numberOfWeeks; i++) {
            const weekId = state.weekNr + i;
            const {defaultDays, newDayNr} = addDefaultDays(weekId, state, dayNr)
            dayNr = newDayNr
            Object.values(defaultDays).map(day => { newDays[day.id]= day });
            // addDefaultDays(weekId, state);
            newWeeks[weekId] = {
                id: weekId,
                personId: personId,
                weekNr: i,
                // name: `Vecka ${i}`,
            }
        }
        return {generatedNewWeeks: newWeeks, newDayNr: dayNr};
      }

      const {generatedNewWeeks, newDayNr} = generateNewWeeks(personId)
      
      const newSelectedPerson =  personId; //state.selectedPerson ? state.selectedPerson :
      // const newSelectedWeekId = state.selectedWeekId ? state.selectedWeekId : Object.values(generatedNewWeeks).find(week => week.personId === newSelectedPerson);
      const newSelectedWeekId =  Object.values(generatedNewWeeks).find(week => (week.personId === newSelectedPerson && week.weekNr == state.selectedWeekNr))?.id
      return {
        ...state,
        selectedPerson: newSelectedPerson,
        selectedWeekId: newSelectedWeekId,
        weeks: generatedNewWeeks,
        days: newDays,
        peopleNr: state.peopleNr +1,
        weekNr: state.weekNr + state.numberOfWeeks,
        dayNr: newDayNr,
        people: {
          ...state.people,
          [personId]: {
            id: personId,
            name: action.payload.name,
            departmentId: state.selectedDepartment
          }
        },
        // showPersonSettings: true,
      };
    }
    case 'REMOVE_PERSON': {
      const personId = action.payload.personId;
    
      // Remove the person from the state
      const { [personId]: _, ...remainingPeople } = state.people;
    
      // Remove weeks associated with the person
      const remainingWeeks = Object.fromEntries(
        Object.entries(state.weeks).filter(([_, week]) => week.personId !== personId)
      );
    
      // Collect all weekIds that are going to be removed
      const removedWeekIds = Object.keys(state.weeks).filter(weekId => state.weeks[weekId].personId === personId);
    
      // Remove days associated with the removed weeks
      const remainingDays = Object.fromEntries(
        Object.entries(state.days).filter(([_, day]) => !removedWeekIds.includes(String(day.weekId)))
      );

      // Collect all dayIds that are going to be removed
      const removedDayIds = Object.keys(state.days).filter(dayId => removedWeekIds.includes(String(state.days[dayId].weekId)));

      // Remove work hours associated with the removed days
      const remainingWorkHours = Object.fromEntries(
        Object.entries(state.workHours).filter(([_, workHour]) => !removedDayIds.includes(String(workHour.dayId)))
      );

      // Remove break hours associated with the removed days
      const remainingBreakHours = Object.fromEntries(
        Object.entries(state.breakHours).filter(([_, breakHour]) => !removedDayIds.includes(String(breakHour.dayId)))
      );

      // Remove additional hours associated with the removed days
      const remainingAdditionalHours = Object.fromEntries(
        Object.entries(state.additionalHours).filter(([_, additionalHour]) => !removedDayIds.includes(String(additionalHour.dayId)))
      );
    
      // Update meetings to remove the personId from peopleIds array
      const remainingMeetings = Object.fromEntries(
        Object.entries(state.meetings).map(([meetingId, meeting]) => {
          const updatedPeopleIds = meeting.peopleIds.filter(id => id !== personId);
          return [meetingId, { ...meeting, peopleIds: updatedPeopleIds }];
        })
      );

      // Update meetings to remove the person's id from peopleIds, and remove empty meetings
      const updatedMeetings = Object.fromEntries(
        Object.entries(state.meetings)
          .map(([meetingId, meeting]) => [
            meetingId,
            {
              ...meeting,
              peopleIds: meeting.peopleIds?.filter(id => id !== personId)
            }
          ])
          .filter(([_, meeting]) => meeting.peopleIds.length > 0) // Filter out empty meetings
      );

      return {
        ...state,
        people: remainingPeople,
        weeks: remainingWeeks,
        days: remainingDays,
        workHours: remainingWorkHours,
        breakHours: remainingBreakHours,
        additionalHours: remainingAdditionalHours,
        meetings: updatedMeetings,
        selectedPerson: odv(state.people).find(person => person.departmentId === state.selectedDepartment)?.id,
        selectedWeekId: odv(state.weeks).find(week => week.weekNr === state.selectedWeekNr
                                                      && state.people[week.personId].departmentId === state.selectedDepartment
        )?.id
      };
    }
    // case 'REMOVE_PERSON':
    //   const { [action.payload.personId]: __, ...remainingPeople } = state.people;
    //   return {
    //     ...state,
    //     people: remainingPeople
    //   };
    case 'SELECT_NEXT_WEEK':
      {
        const currentWeekNr = state.selectedWeekNr
        const numberOfWeeks = state.numberOfWeeks
        let newWeekNr
      
        if (action.payload.backwards) {
          // Decrement week
          newWeekNr = currentWeekNr === 1 ? numberOfWeeks : currentWeekNr - 1
        } else {
          // Increment week
          newWeekNr = currentWeekNr === numberOfWeeks ? 1 : currentWeekNr + 1
        }
      
        const newWeekId = odv(state.weeks).find(week => week.weekNr === newWeekNr ).id
        // && week.personId === state.selectedPerson
        return {
          ...state,
          selectedWeekNr: newWeekNr,
          selectedWeekId: newWeekId
        }
      }
    case 'SELECT_NEXT_DAY':
      {
          const days = odv(weekDays);
          const currentIndex = days.indexOf(state.selectedDay);
          
          if (currentIndex === -1) {
              return {
                  ...state,
                  selectedDay: days[0] // If the input is invalid, return Monday
              };
          }
          
          let nextIndex;

          if (action.payload.backwards) {
              nextIndex = (currentIndex - 1 + days.length) % days.length;
          } else {
              nextIndex = (currentIndex + 1) % days.length;
          }
      
          log ("selecting next day:", action.payload.backwards)
          return {
              ...state,
              selectedDay: days[nextIndex]
          };
      }
        

    case 'ADD_WEEK':
        let newWeeks = { ...state.weeks };
        let newDays = { ...state.days };
        const newWeekNr = state.numberOfWeeks + 1;
        let dayNr = state.dayNr
        let weekNr = state.weekNr
        Object.values(state.people).forEach(person => {
          const weekId = weekNr + 1;
          weekNr++;
          const {defaultDays, newDayNr } = addDefaultDays(weekId, state, dayNr)
          dayNr = newDayNr
          Object.values(defaultDays).map(day => { newDays[day.id]= day });
          newWeeks[weekId] = {
            id: weekId,
            personId: person.id,
            weekNr: newWeekNr
          }
        });

        if (newWeekNr === 1){ // this means that there were no weeks prior to button press
          const newWeekId = odv(newWeeks).find(week => week.personId === state.selectedPerson).id
          return {
            ...state,
            dayNr: dayNr,
            weekNr: weekNr,
            weeks: newWeeks,
            days: newDays,
            numberOfWeeks: newWeekNr,
            selectedWeekNr: 1,
            selectedWeekId: newWeekId
          }; 
        } else {
          return {
            ...state,
            dayNr: dayNr,
            weekNr: weekNr,
            weeks: newWeeks,
            days: newDays,
            numberOfWeeks: newWeekNr
          };  
        }
    case 'REMOVE_WEEK':
        const highestWeekNr = Math.max(...Object.values(state.weeks).map(week => week.weekNr));
        const weeksToRemove = Object.values(state.weeks).filter(week => week.weekNr === highestWeekNr).map(week => week.id)
        const remainingWeeks = Object.values(state.weeks).reduce((acc, week) => {
          if (week.weekNr !== highestWeekNr) {
            acc[week.id] = week;
          }
          return acc;
        }, {});
  
        const newDaysAfterWeekRemoval = Object.values(state.days).reduce((acc, day) => {
          if (!(weeksToRemove.includes(day.weekId))) {
            acc[day.id] = day;
          }
          return acc;
        }, {});
  
        const newWorkHoursAfterWeekRemoval = Object.values(state.workHours).reduce((acc, hour) => {
          log("workhour is: ", hour, "acc iss: ", acc)
          log("Object.keys(newDaysAfterWeekRemoval: ", Object.keys(newDaysAfterWeekRemoval))
          if (Object.keys(newDaysAfterWeekRemoval).includes(String(hour.dayId))) {
            acc[hour.id] = hour;
          }
          return acc;
        }, {});
  
        const newBreakHoursAfterWeekRemoval = Object.values(state.breakHours).reduce((acc, hour) => {
          if (Object.keys(newDaysAfterWeekRemoval).includes(String(hour.dayId))) {
            acc[hour.id] = hour;
          }
          return acc;
        }, {});
  
        // const newPutHoursAfterWeekRemoval = Object.values(state.putHours).reduce((acc, hour) => {
        //   if (Object.keys(newDaysAfterWeekRemoval).includes(hour.dayId)) {
        //     acc[hour.id] = hour;
        //   }
        //   return acc;
        // }, {});
  
        let newSelectedWeekId = 0
        let newSelectedWeekNr = 0

        const newNumberOfWeeks = state.numberOfWeeks - 1;

        if (newNumberOfWeeks === 0) {
          newSelectedWeekId = null
          newSelectedWeekNr = null
        } else {
          newSelectedWeekId = state.selectedWeekNr === state.numberOfWeeks ? Object.values(state.weeks).find(week => week.weekNr === newNumberOfWeeks).id : state.selectedWeekId;
          newSelectedWeekNr = state.selectedWeekNr === state.numberOfWeeks ? Object.values(state.weeks).find(week => week.weekNr === newNumberOfWeeks).weekNr : state.selectedWeekNr;
        }

        log("original wh: ", state.workHours)
        log("new wh: ", newWorkHoursAfterWeekRemoval)
        return {
          ...state,
          weeks: remainingWeeks,
          days: newDaysAfterWeekRemoval,
          workHours: newWorkHoursAfterWeekRemoval,
          breakHours: newBreakHoursAfterWeekRemoval,
          // putHours: newPutHoursAfterWeekRemoval,
          numberOfWeeks: newNumberOfWeeks,
          selectedWeekId: newSelectedWeekId,
          selectedWeekNr: newSelectedWeekNr,
          weekNr: state.weekNr - weeksToRemove.length
        };
    case 'SELECT_DEPARTMENT':
        const newDepartmentId = action.payload.departmentId
        let newSelectedPerson = Object.values(state.people).find(person => person.departmentId === newDepartmentId)
        
        // Check the current selected person has a corresponding person in the other department
        const currentPerson = state.people[state.selectedPerson]
        const personWithSameName = odv(state.people).find(person => person.departmentId === newDepartmentId && person?.name === currentPerson?.name)
        if (personWithSameName) {
          newSelectedPerson = personWithSameName
        }

        // if (state.selectedPerson)
        return {
            ...state,
            selectedDepartment: newDepartmentId,
            selectedPerson: newSelectedPerson?.id,
            selectedWeekId: Object.values(state.weeks).find(week => week.weekNr === state.selectedWeekNr && week.personId === newSelectedPerson?.id)?.id
        }
    case 'SELECT_PERSON':
        return {
            ...state,
            selectedPerson: action.payload.personId,
            selectedWeekId: Object.values(state.weeks).find(week => (week.personId === action.payload.personId && week.weekNr == state.selectedWeekNr))?.id
        }
    case 'SELECT_WEEK':
      return {
        ...state,
        selectedWeekId: action.payload.weekId,
        selectedWeekNr: action.payload.weekNr,
      };
    case 'SET_WORK_HOUR_TIME':

      const oldWorkHourId  = Object.values(state.workHours).find(workHour => ((workHour.dayId === action.payload.dayId) && (workHour.type === action.payload.type)))?.id
      let whNr = state.whNr
      let workHourId = null
      if (oldWorkHourId) {
        workHourId = oldWorkHourId
      } else {
        workHourId = whNr + 1
        whNr++
      }
      // const workHourId = oldWorkHourId ? oldWorkHourId : uuidv4();

      const newWorkHours = {
        ...state.workHours,
        [workHourId]: {
            id: workHourId,
            dayId: action.payload.dayId,  
            type: action.payload.type, 
            time: action.payload.time,
        }
      }
      return {
        ...state,
        whNr: whNr,
        workHours: newWorkHours
      };
    case 'SET_BREAK_HOUR_TIME':
      const oldBreakHourId = Object.values(state.breakHours).find(breakHour => ((breakHour.dayId === action.payload.dayId) && (breakHour.type === action.payload.type)))?.id
      let bhNr = state.bhNr
      let breakHourId = null
      if (oldBreakHourId) {
        breakHourId = oldBreakHourId
      } else {
        breakHourId = bhNr + 1
        bhNr++
      }
      // const breakHourId = oldBreakHourId ? oldBreakHourId : uuidv4();

      const newBreakHours = {
        ...state.breakHours,
        [breakHourId]: {
            id: breakHourId,
            dayId: action.payload.dayId,  
            type: action.payload.type, 
            time: action.payload.time,
        }
      }
      return {
        ...state,
        bhNr: bhNr,
        breakHours: newBreakHours
      };
    case 'SET_PUT_HOUR_TIME':
      const oldPutHourId = Object.values(state.putHours).find(putHour => ((putHour.dayId === action.payload.dayId) && (putHour.type === action.payload.type)))?.id
      const putHourId = oldPutHourId ? oldPutHourId : uuidv4();
      const newPutHours = {
        ...state.putHours,
        [putHourId]: {
            id: putHourId,
            dayId: action.payload.dayId,  
            type: action.payload.type, 
            time: action.payload.time,
        }
      }
      return {
        ...state,
        putHours: newPutHours
      };
    case 'CREATE_MEETING':
      {log("creating meeting: ", action.payload)
      const meetingId = state.mNr + 1;
      const { time, length, weekDay, peopleIds } = action.payload; //weekNr
      const weekNr = action.payload?.weekNr || state.selectedWeekNr
      return {
        ...state,
        mNr: state.mNr + 1,
        meetings: {
          ...state.meetings,
          [meetingId]: {
            id: meetingId,
            weekNr: weekNr,
            weekDay: weekDay,
            peopleIds: peopleIds,
            time: time,
            length: length,
          }
        }
      }}
    case 'REMOVE_MEETING':
      const { [action.payload.meetingId]: removedMeeting, ...remainingMeetings } = state.meetings;
      log ("---------removing meeting: ", action.payload.meetingId)
      return {
        ...state,
        meetings: remainingMeetings,
      };
    case 'REMOVE_ADDITIONAL_HOUR':
      const { [action.payload.additionalHourId]: removedAdditionalHour, ...remeiningAdditionalhours } = state.additionalHours;
      return {
        ...state,
        additionalHours: remeiningAdditionalhours,
      };
    case 'ADD_ADDITIONAL_HOUR':
      const new_id = state.ahNr + 1;
      const { dayId, category, start, lengthh } = action.payload
      return {
        ...state,
        ahNr: state.ahNr + 1,
        additionalHours: {
          ...state.additionalHours,
          [new_id]: {
            id: new_id,
            dayId: dayId,
            category: category,
            start: start,
            length: lengthh,
          }
        }
      }
      case 'MODIFY_ADDITIONAL_HOUR':
        const { additionalHourId } = action.payload
        return {
          ...state,
          additionalHours: {
            ...state.additionalHours,
            [additionalHourId]: {
              ...state.additionalHours[additionalHourId],
              start: action.payload.start ? action.payload.start : state.additionalHours[additionalHourId].start,
              length: action.payload.length ? action.payload.length : state.additionalHours[additionalHourId].length,
            }
          }
        }
      case 'TOGGLE_ADD_PERSON':
        const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showAddPerson: newState
        }
      case 'TOGGLE_ADD_DEPARTMENT':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showAddDepartment: newState
        }}
      case 'TOGGLE_SHOW_EXPORT':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showExport: newState
        }}case 'TOGGLE_SHOW_SAVE':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showSave: newState
        }}
      case 'TOGGLE_SHOW_HELP':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showHelp: newState
        }}
      case 'TOGGLE_SHOW_INFO':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showInfo: newState
        }}
      case 'TOGGLE_SHOW_REMOVE_MEETING':
        {const setToShow = action.payload?.setToShow
        const meetingId = action.payload?.meetingId
        const newState = setToShow ? true : false
        return {
          ...state,
          showRemoveMeeting: newState,
          removeMeetingId: meetingId
        }}
      case 'TOGGLE_SHOW_REMOVE_ADDITIONAL_HOUR':
        {const setToShow = action.payload?.setToShow
        const additionalHourId = action.payload?.additionalHourId
        const newState = setToShow ? true : false
        return {
          ...state,
          showRemoveAdditionalHour: newState,
          removeAdditionalHourId: additionalHourId
        }}
      case 'TOGGLE_SHOW_REMOVE_DATA':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showRemoveData: newState,
        }}
      case 'TOGGLE_SHOW_REMOVE_PERSON':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showRemovePerson: newState,
        }}
      case 'TOGGLE_SHOW_LOGIN':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showLogin: newState,
        }}
      case 'TOGGLE_SHOW_PERSON_INFO':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showPersonInfo: newState,
        }}
      case 'TOGGLE_SHOW_DEPARTMENT_INFO':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showDepartmentInfo: newState,
        }}
      case 'TOGGLE_SHOW_PERSON_SETTINGS':
        {const setToShow = action.payload?.setToShow
        const newSelectedPerson = action.payload?.personId || state.selectedPerson
        const newState = setToShow ? true : false
        return {
          ...state,
          showPersonSettings: newState,
          selectedPerson: newSelectedPerson
        }}
      case 'TOGGLE_SHOW_ADD_MEETINGAH':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showAddMeetingAH: newState,
        }}
      case 'TOGGLE_SHOW_MEETINGAH_POPUP':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showMeetingAHPopup: newState,
          objectId: action.payload?.objectId
        }}
      case 'TOGGLE_SHOW_STATISTICS':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showStatistics: newState,
        }}
      case 'TOGGLE_SHOW_WORKHOUR_EDITOR':
        {const setToShow = action.payload?.setToShow
        const dayId = action.payload?.dayId || null
        const newState = setToShow ? true : false
        return {
          ...state,
          showWorkHourEditor: newState,
          editWorkHourDayId: dayId
        }}
      case 'TOGGLE_SHOW_STATISTICS':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showStatistics: newState,
        }}
      case 'TOGGLE_SHOW_ALL_CHARTS':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showAllCharts: newState,
        }}
      case 'TOGGLE_SHOW_PUBLICIZE':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showPublicize: newState,
        }}
      case 'TOGGLE_SHOW_REMOVE_DEPARTMENT':
        {const setToShow = action.payload?.setToShow
          const departmentId = action.payload?.departmentId
        const newState = setToShow ? true : false
        return {
          ...state,
          showRemoveDepartment: newState,
          removeDepartmentId: departmentId,
        }}
      case 'TOGGLE_SHOW_EDIT_DEPARTMENT_NAME':
        {const setToShow = action.payload?.setToShow
          const departmentId = action.payload?.departmentId
        const newState = setToShow ? true : false
        return {
          ...state,
          showEditDepartmentName: newState,
          editDepartmentId: departmentId,
        }}
      case 'EDIT_DEPARTMENT_NAME':
        const { deptId, newName } = action.payload;
        
        return {
          ...state,
          departments: {
            ...state.departments,
            [deptId]: {
              ...state.departments[deptId],
              name: newName,
            },
          },
        };
      case 'TOGGLE_SHOW_EDIT_PERSON_NAME':
        {const setToShow = action.payload?.setToShow
          const personId = action.payload?.personId
        const newState = setToShow ? true : false
        return {
          ...state,
          showEditPersonName: newState,
          editPersonNameId: personId,
        }}
      case 'TOGGLE_SHOW_MEETINGANDAHANDWH':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showMeetingAndAHAndWH: newState,
        }}
      case 'TOGGLE_SHOW_EDIT_CATEGORIES':
        {const setToShow = action.payload?.setToShow
          const newState = setToShow ? true : false
          log("toggling show edit categories to: ", newState)
        return {
          ...state,
          showEditCategories: newState,
        }}
      case 'TOGGLE_SHOW_REMOVE_CATEGORY':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        const categoryToRemove = action.payload?.categoryToRemove
        log("toggling show remove categories to: ", newState)

        return {
          ...state,
          showRemoveCategory: newState,
          categoryToRemove: categoryToRemove || null
        }}
      case 'TOGGLE_SHOW_ADD_CATEGORY':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showAddCategory: newState,
        }}
      case 'TOGGLE_SHOW_SCHEDULE_SETTINGS':
        {const setToShow = action.payload?.setToShow
        const newState = setToShow ? true : false
        return {
          ...state,
          showScheduleSettings: newState,
        }}
      case 'EDIT_PERSON_NAME':
        {const { personId, newName } = action.payload;
        
        return {
          ...state,
          people: {
            ...state.people,
            [personId]: {
              ...state.people[personId],
              name: newName,
            },
          },
        };}

      
      case 'SELECT_DAY':
        return {
          ...state,
          selectedDay: action.payload.day,
        }
      case 'SELECT_BUTTON':
        return {
          ...state,
          selectedButton: action.payload.button,
        }
      case 'SELECT_STATISTICS_BUTTON':
        return {
          ...state,
          selectedStatisticsButton: action.payload.button,
        }
      case 'SELECT_SCHOOLSTATISTICS_BUTTON':
        return {
          ...state,
          selectedSchoolStatisticsButton: action.payload.button,
        }
      case 'SET_USERID':
        return {
          ...state,
          userId: action.payload.userId,
        }
      case 'SET_TOKEN':
        return {
          ...state,
          token: action.payload.token,
        }
      case 'SET_AUTOSYNC':
        return {
          ...state,
          autosync: action.payload.autosync,
        }
      case 'SET_LOGGEDIN':
        return {
          ...state,
          loggedIn: action.payload.loggedIn,
        }
      case 'SET_AUTOFILL_THIRTY':
        return {
          ...state,
          autofillThirty: action.payload.newValue,
        }
      case 'SET_PRESSED_DAY_BUTTON':
        log("setting new day button: ", action.payload.newValue )
        return {
          ...state,
          pressedDayButton: action.payload.newValue,
        }
      case 'LOG_IN':
        const { userId, token } = state
        const url = 'https://eg2ch5owxsfpz66bxdoayeoswe0jqelt.lambda-url.eu-north-1.on.aws/'

      case 'SET_STATE':
        return action.payload.state
        // return {
        //   ...action.payload.state
        // }
      case 'SET_CATEGORIES':
        return {
          ...state,
          categories: action.payload.categories
        }
        // return {
        //   ...action.payload.state
        // }
      case 'ADD_PUBLICIZED_LINK':
        return {
          ...state,
          publicizedLinks: [...state.publicizedLinks, action.payload.publicizedLink]
        }
      case 'SET_TARGET_HOURS':
        // log("setting target horus: ", action.payload)
        const { personId, targetType, newHours, newMode } = action.payload;

        if (state.people[personId].targetHours?.find(target => target[0] === targetType)) { // This is if the target already exists, then modify it
          return {
            ...state,
            people: {
            ...state.people,
            [personId]: {
              ...state.people[personId],
              targetHours: state.people[personId].targetHours.map((target) =>
                target[0] === targetType
                  ? [targetType, newHours ?? target[1], newMode ?? target[2]]
                  : target
                ),
              },
            },
          }
        } else { // This is if the target doesnt already exist, then create it
          if (state.people[personId].targetHours) { //Check if the person has any target hours at all to not cause crash
            const { personId, targetType, newHours, newMode } = action.payload;
          
            return {
              ...state,
              people: {
                ...state.people,
                [personId]: {
                  ...state.people[personId],
                  targetHours: [...state.people[personId]?.targetHours, [targetType, newHours, newMode]],
                },
              },
            };  
          } else {
            const { personId, targetType, newHours, newMode } = action.payload;
          
            return {
              ...state,
              people: {
                ...state.people,
                [personId]: {
                  ...state.people[personId],
                  targetHours: [[targetType, newHours, newMode]],
                },
              },
            };
  
          }
        }
        
      case 'ADD_TARGET_HOUR': {
        const { personId, targetType, hours, type } = action.payload;
        return state
      }

      case 'SET_WEEKDAY_SETTINGS': {
        return {
          ...state,
          weekDaySettings: action.payload.weekDaySettings
        }
      }
  
      case 'REMOVE_TARGET_HOUR': {
        const { personId, targetType } = action.payload;
        return {
          ...state,
          people: {
            ...state.people,
            [personId]: {
              ...state.people[personId],
              targetHours: state.people[personId].targetHours.filter(
                (target) => target[0] !== targetType
              ),
            },
          },
        };
      }
    
    default:
      return state;
  }
};
// Create context
const AppContext = createContext();

// Context provider component

export const AppProvider = ({ children }) => {
  const [state, originalDispatch] = useReducer(reducer, initialState);
  const debounceTimerRef = useRef(null);
  const latestStateRef = useRef(state);

  // Update latestStateRef whenever state changes
  useEffect(() => {
    latestStateRef.current = state;
  }, [state]);

  const debouncedSendStateToServer = useCallback(() => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => {
      if (latestStateRef.current.autosync === 'på') {
        sendStateToServer(
          latestStateRef.current,
          latestStateRef.current.userId,
          latestStateRef.current.token
        );
      }
    }, 2000); // 1 second debounce time
  }, []);

  const dispatch = useCallback((action) => {
    const isLocalOnlyAction = action.meta && action.meta.localOnly;

    originalDispatch(action);
    const updatedState = reducer(latestStateRef.current, action);
    latestStateRef.current = updatedState;

    if (!isLocalOnlyAction) {
      debouncedSendStateToServer(updatedState);
    }
    // debouncedSendStateToServer();
  }, [debouncedSendStateToServer]);

  // useEffect(() => {
  //   localStorage.setItem('appState', JSON.stringify(state));
  // }, [state]);

  useEffect(() => { // Try to make it so that publicize doesnt overwrite the regular app
    log("token is: ", state.token)
    if (!state.token) {
      localStorage.setItem('appState', JSON.stringify(state));
    }
  }, [state, state.token]);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  function printState() {
    console.log(state)
  }

  function clearState() {
    localStorage.setItem('appState', JSON.stringify(initialStatee));
    dispatch({
      type: 'SET_STATE',
      payload: { state: initialStatee},
      meta: { localOnly: true }
    });
  }
  
  window.printState = printState
  window.clearState = clearState

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

// export const AppProvider = ({ children }) => {
//   // const [state, dispatch] = useReducer(reducer, initialState);

//   const [state, originalDispatch] = useReducer(reducer, initialState);

//   // Define your server function here or import it
//   // const sendStateToServer = (newState) => {
//   //   console.log('Sending new state to the server...', newState);
//   // };

//   // Create a custom dispatch that wraps the original dispatch
//   const dispatch = (action) => {
//     originalDispatch(action); // Dispatch the action to update the state
//     const updatedState = reducer(state, action); // Get the new state
//     if (state.autosync === 'på') {
//       sendStateToServer(updatedState, state.userId, state.token); // Send the new state to the server
//     }
//   };


//   useEffect(() => {
//     localStorage.setItem('appState', JSON.stringify(state));
//   }, [state]);

//   const contextValue = useMemo(() => ({ state, dispatch }), [state]);

//   return (
//     <AppContext.Provider value={contextValue}>
//       {children}
//     </AppContext.Provider>
//   );
// };

// Custom hook to use context
export const useAppContext = () => {
  return useContext(AppContext);
};



