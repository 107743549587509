import React, { useState, useRef, useEffect } from 'react';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import { useAppContext } from '../contexts/AppContext';
import { findPeopleWithSameName, odv, log, weekDays, timeToFloat, floatToTime} from '../utils/Utils';
import { getEffectiveWorkHour } from '../utils/DataCalculator';
import { calculateDayTotalHours } from '../utils/Utils';
import buttonStyles from '../styles/StyledButton.module.css';
import inputStyles from '../styles/StyledInput.module.css';
import ExcelPreview from '../excel/ExcelPreview';



const defaultStyle = {
  font: { size: 7, color: { argb: 'FF414141' } },
  alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
  border: {
    top: { style: 'medium' },
    left: { style: 'medium' },
    bottom: { style: 'medium' }, //thick, medium
    right: { style: 'medium' }
  }
};

const biggerColStyle = {
  font: { size: 9, color: { argb: 'FF000000' } },
  alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
  border: {
    top: { style: 'medium' },
    left: { style: 'medium' },
    bottom: { style: 'medium' }, //thick, medium
    right: { style: 'medium' }
  }
};

const thiccStyle = {
  font: { size: 9, color: { argb: 'FF000000' }, bold: true },
  alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
  border: {
    top: { style: 'thick' },
    left: { style: 'medium' },
    bottom: { style: 'medium' },
    right: { style: 'medium' }
  }
};

const filledStyle = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'ffff6699' } // Red background color
};


const biggerFontCols = ['A', 'B', 'E', 'H', 'K', 'N', ]



const fillStyle = (color) => {
  return {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: color } // Red background color
  }
}

const overheadList = ["", "Måndag", "Rast", "Tim", "Tisdag", "Rast", "Tim", "Onsdag", "Rast", "Tim", "Torsdag", "Rast", "Tim", "Fredag", "Rast", "Tim", "Totalt\ntim/\nvecka"]

const ExportExcel = () => {
  const { state, dispatch } = useAppContext();
  const { workHours, breakHours, additionalHours, days, weeks, people, numberOfWeeks, departments, meetings } = state

  const [fileName, setFileName] = useState('SchemaVT25');
  const [workbook, setWorkbook] = useState(null);
  const inputRef = useRef(null);


  const createWorkbook = () => {
    log("försöker förhandsvisa..")
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Your existing code for populating the worksheet goes here
    // Define data
    const overheadRows = [
      ["Min Förskola"],
    ]

    const rows = [[], []]

    for (let weekNr = 1; weekNr <= numberOfWeeks; weekNr++) {
      rows.push([`Vecka: ${weekNr}`])
      rows.push(overheadList)
      
      odv(departments).forEach(department => {
        rows.push([department.name])
        
        odv(people).filter(person => person.departmentId === department.id).forEach(person => {
          const week = odv(weeks).find(week => week.personId === person.id && week.weekNr === weekNr)
          let newRow = [person.name]
          let weekTotalHours = 0
          
          odv(weekDays).forEach(weekDay => {
              // if (!week){
              //   log("x-x-x-x-x-x-x-x couldnt find week: ", week, department)
              // }
              const day = odv(days).find(day => day.weekId === week.id && day.weekDay === weekDay)
              // log("day: ", day)
              // if (!day){
              //   log("x-x-x-x-x-x-x-x couldnt find day: ", weekDay, week, department)
              // }
              const workHourStart = odv(workHours).find(wh => wh.dayId === day.id && wh.type === 'start')?.time
              const workHourEnd = odv(workHours).find(wh => wh.dayId === day.id && wh.type === 'end')?.time

              if ((workHourStart && workHourEnd)) {
                
              let cellString = `${workHourStart}-${workHourEnd}`

              odv(additionalHours).filter(aH => aH.dayId === day.id).forEach(aH => {
                cellString = `${cellString}\n(${aH.category} ${aH.start}-${floatToTime(timeToFloat(aH.start) + timeToFloat(aH.length))})`
              })

              const peopleObjectsWithSameName = findPeopleWithSameName(state, person)
              odv(meetings).filter(meeting => 
                meeting.weekDay === day.weekDay 
                && meeting.weekNr === week.weekNr
                && peopleObjectsWithSameName.some(personObject => meeting.peopleIds.includes(personObject.id))
                // && meeting.peopleIds.some(peopleId => peopleObjectsWithSameName.some(personObject => personObject.id === peopleId))
                // && peopleObjectsWithSameName.some(personObject => meeting.peopleIds.includes(personObject.id))
                // && meeting.peopleIds.includes(people[week.personId].id)
              ).forEach(meeting => {
                cellString = `${cellString}\n(Personalmöte ${meeting.time}-${floatToTime(timeToFloat(meeting.time) + timeToFloat(meeting.length))})`
              })

              peopleObjectsWithSameName.forEach(personn => {
                if (personn.id !== person.id) { // Dont double count the same person
                  const personDay = odv(days).find(day => day.weekDay === weekDay && people[weeks[day.weekId].personId].id === personn.id)
                  const personWorkHourStart = odv(workHours).find(wh => wh.dayId === personDay.id && wh.type === 'start')?.time
                  const personWorkHourEnd = odv(workHours).find(wh => wh.dayId === personDay.id && wh.type === 'end')?.time
  
                  if (personWorkHourStart && personWorkHourEnd) {
                    cellString = `${cellString}\n(${departments[personn.departmentId].name} ${personWorkHourStart}-${personWorkHourEnd})`
                  }
                }
              })

              // This is in the day column and shows work hours + additional hours
              newRow.push(cellString)

              const breakHourStart = odv(breakHours).find(bh => bh.dayId === day.id && bh.type === 'start')?.time
              const breakHourLength = odv(breakHours).find(bh => bh.dayId === day.id && bh.type === 'length')?.time

              // This is in the break column
              newRow.push(`${breakHourStart ? `${breakHourStart}-${floatToTime(timeToFloat(breakHourStart) + timeToFloat(breakHourLength))}` : `${breakHourLength ? timeToFloat(breakHourLength) : '-'}`}`)

              // This is the Tim column
              const dayTotalHours = calculateDayTotalHours(state, day.id)
              weekTotalHours += dayTotalHours
              newRow.push(`${dayTotalHours}`)

            } else {
              newRow.push("", "", "");
            }
          })
          // This is the totalt tim/vecka column
          newRow.push(`${weekTotalHours}`)

          // Push all this person to the sheet
          rows.push(newRow)
        })
      })
      rows.push([])
    }

    // Add rows to the worksheet
    rows.forEach((row, rowIndex) => {
      const excelRow = worksheet.addRow(row);

      let maxLines = 1;

      // Set row height based on the content (approximation)
      excelRow.eachCell((cell, colNumber) => {
        cell.style = { ...defaultStyle}

        const cellColumn = cell.address.match(/[A-Z]+/)[0]; // Dont know what the heck this is but it works
        if (biggerFontCols.includes(cellColumn)) {
          cell.style = { ... biggerColStyle}
        }

        if (odv(state.departments).some(department => department.name === cell.value)) {
          cell.fill = fillStyle('ffff6699')
        } else if (odv(state.people).some(person => person.name === cell.value)) {
          cell.fill = fillStyle('fff4b083')
        } else if (["Måndag", "Rast", "Tim", "Tisdag", "Rast", "Tim", "Onsdag", "Rast", "Tim", "Torsdag", "Rast", "Tim", "Fredag", "Rast", "Tim", "Totalt\ntim/\nvecka"].includes(cell.value)) {
          cell.style = { ... thiccStyle}
        }

        try {
          if (cell.value.includes('\n')) {
            const lines = cell.value.split('\n').length;
            if (lines > maxLines) {
              maxLines = lines;
            }
            // Adjust the multiplier as needed
          }
        } catch(e) {
         //log(e)
        }
      });


      excelRow.height = maxLines * 16
    });

    // Apply styles and text wrapping
    const styles = {
      font: { size: 14, color: { argb: 'FFFF0000' } },
      alignment: { wrapText: true, vertical: 'top', horizontal: 'left' }
    };

    // worksheet.getCell('A1').style = styles;
    // worksheet.getCell('B1').style = { ...styles, font: { size: 12, color: { argb: 'FF000000' } } };
    // worksheet.getCell('A2').style = { ...styles, font: { size: 12, color: { argb: 'FFFF0000' } } };
    // worksheet.getCell('B2').style = { ...styles, font: { size: 12, color: { argb: 'FF00FF00' } } };

    // Set column width to auto-fit the content
    worksheet.columns.forEach(column => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, cell => {
        const cellValue = cell.value ? cell.value.toString().split('\n').reduce((a, b) => (a.length > b.length ? a : b)) : '';
        const columnLength = cellValue.length; // Add extra padding for better fit
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 4 ? 4 : maxLength;
    });

    setWorkbook(workbook);
  };

  const handleExport2 = async () => {
    // if (!workbook) {
    createWorkbook();
    // }
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
  };


  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Define data
    const overheadRows = [
      ["Min Förskola"],
    ]

    const rows = [[], []]

    for (let weekNr = 1; weekNr <= numberOfWeeks; weekNr++) {
      rows.push([`Vecka: ${weekNr}`])
      rows.push(overheadList)
      
      odv(departments).forEach(department => {
        rows.push([department.name])
        
        odv(people).filter(person => person.departmentId === department.id).forEach(person => {
          const week = odv(weeks).find(week => week.personId === person.id && week.weekNr === weekNr)
          let newRow = [person.name]
          let weekTotalHours = 0
          
          odv(weekDays).forEach(weekDay => {
              const day = odv(days).find(day => day.weekId === week.id && day.weekDay === weekDay)
              const workHourStart = odv(workHours).find(wh => wh.dayId === day.id && wh.type === 'start')?.time
              const workHourEnd = odv(workHours).find(wh => wh.dayId === day.id && wh.type === 'end')?.time

              if ((workHourStart && workHourEnd)) {
                
              let cellString = `${workHourStart}-${workHourEnd}`

              odv(additionalHours).filter(aH => aH.dayId === day.id).forEach(aH => {
                cellString = `${cellString}\n(${aH.category} ${aH.start}-${floatToTime(timeToFloat(aH.start) + timeToFloat(aH.length))})`
              })

              const peopleObjectsWithSameName = findPeopleWithSameName(state, person)
              odv(meetings).filter(meeting => 
                meeting.weekDay === day.weekDay 
                && meeting.weekNr === week.weekNr
                && peopleObjectsWithSameName.some(personObject => meeting.peopleIds.includes(personObject.id))
                // && meeting.peopleIds.some(peopleId => peopleObjectsWithSameName.some(personObject => personObject.id === peopleId))
                // && peopleObjectsWithSameName.some(personObject => meeting.peopleIds.includes(personObject.id))
                // && meeting.peopleIds.includes(people[week.personId].id)
              ).forEach(meeting => {
                cellString = `${cellString}\n(Personalmöte ${meeting.time}-${floatToTime(timeToFloat(meeting.time) + timeToFloat(meeting.length))})`
              })

              peopleObjectsWithSameName.forEach(personn => {
                if (personn.id !== person.id) { // Dont double count the same person
                  const personDay = odv(days).find(day => day.weekDay === weekDay && people[weeks[day.weekId].personId].id === personn.id)
                  const personWorkHourStart = odv(workHours).find(wh => wh.dayId === personDay.id && wh.type === 'start')?.time
                  const personWorkHourEnd = odv(workHours).find(wh => wh.dayId === personDay.id && wh.type === 'end')?.time
  
                  if (personWorkHourStart && personWorkHourEnd) {
                    cellString = `${cellString}\n(${departments[personn.departmentId].name} ${personWorkHourStart}-${personWorkHourEnd})`
                  }
                }
              })

              // This is in the day column and shows work hours + additional hours
              newRow.push(cellString)

              const breakHourStart = odv(breakHours).find(bh => bh.dayId === day.id && bh.type === 'start')?.time
              const breakHourLength = odv(breakHours).find(bh => bh.dayId === day.id && bh.type === 'length')?.time

              // This is in the break column
              newRow.push(`${breakHourStart ? `${breakHourStart}-${floatToTime(timeToFloat(breakHourStart) + timeToFloat(breakHourLength))}` : `${breakHourLength ? timeToFloat(breakHourLength) : '-'}`}`)

              // This is the Tim column
              const dayTotalHours = calculateDayTotalHours(state, day.id)
              weekTotalHours += dayTotalHours
              newRow.push(`${dayTotalHours}`)

            } else {
              newRow.push("", "", "");
            }
          })
          // This is the totalt tim/vecka column
          newRow.push(`${weekTotalHours}`)

          // Push all this person to the sheet
          rows.push(newRow)
        })
      })
      rows.push([])
    }

    // Add rows to the worksheet
    rows.forEach((row, rowIndex) => {
      const excelRow = worksheet.addRow(row);

      let maxLines = 1;

      // Set row height based on the content (approximation)
      excelRow.eachCell((cell, colNumber) => {
        cell.style = { ...defaultStyle}

        const cellColumn = cell.address.match(/[A-Z]+/)[0]; // Dont know what the heck this is but it works
        if (biggerFontCols.includes(cellColumn)) {
          cell.style = { ... biggerColStyle}
        }

        if (odv(state.departments).some(department => department.name === cell.value)) {
          cell.fill = fillStyle('ffff6699')
        } else if (odv(state.people).some(person => person.name === cell.value)) {
          cell.fill = fillStyle('fff4b083')
        } else if (["Måndag", "Rast", "Tim", "Tisdag", "Rast", "Tim", "Onsdag", "Rast", "Tim", "Torsdag", "Rast", "Tim", "Fredag", "Rast", "Tim", "Totalt\ntim/\nvecka"].includes(cell.value)) {
          cell.style = { ... thiccStyle}
        }

        try {
          if (cell.value.includes('\n')) {
            const lines = cell.value.split('\n').length;
            if (lines > maxLines) {
              maxLines = lines;
            }
            // Adjust the multiplier as needed
          }
        } catch(e) {
          log(e)
        }
        


      });

      excelRow.height = maxLines * 16
    });

    // Apply styles and text wrapping
    const styles = {
      font: { size: 14, color: { argb: 'FFFF0000' } },
      alignment: { wrapText: true, vertical: 'top', horizontal: 'left' }
    };

    // worksheet.getCell('A1').style = styles;
    // worksheet.getCell('B1').style = { ...styles, font: { size: 12, color: { argb: 'FF000000' } } };
    // worksheet.getCell('A2').style = { ...styles, font: { size: 12, color: { argb: 'FFFF0000' } } };
    // worksheet.getCell('B2').style = { ...styles, font: { size: 12, color: { argb: 'FF00FF00' } } };

    // Set column width to auto-fit the content
    worksheet.columns.forEach(column => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, cell => {
        const cellValue = cell.value ? cell.value.toString().split('\n').reduce((a, b) => (a.length > b.length ? a : b)) : '';
        const columnLength = cellValue.length; // Add extra padding for better fit
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 4 ? 4 : maxLength;
    });

    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
  };

  return (
    <div>
      <p style={{fontSize:'24px'}}>Ladda ner som Excelfil</p>
      <p style={{marginTop:'5px', marginBottom:'10px'}}>Namn på fil</p>
      <div style={{marginBottom:'15px', display:'flex', alignItems:'center'}}>
        <input
          ref={inputRef}
          className={inputStyles['input-30']}
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
          placeholder="Välj namn"
          style={{width:'150px', backgroundColor:'#e8e8e8'}}
        />
        <p>.xlsx</p>
      </div>
      <button className={buttonStyles['button-30']} style={{marginLeft:'0'}} onClick={handleExport}>Ladda ner</button>
      <button className={buttonStyles['button-30']} style={{marginBottom:'10px',}} onClick={createWorkbook}>Förhandsvisa</button>
      {workbook && <ExcelPreview workbook={workbook} />}
    </div>
  );
};

export default ExportExcel;
