import React, { useState, useCallback, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getMaxStaff } from './StaffChart';
import { debounce } from 'lodash';
import { timeToFloat, floatToTime, log } from '../utils/Utils';
import { useAppContext } from '../contexts/AppContext';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);



export const SynchronizedBarCharts = ({ chartsData, width = '500px', height = '200px', displayName = true }) => {
  const { state, dispatch } = useAppContext();

  const [activeIndex, setActiveIndex] = useState(null);
  const chartRef = useRef(null);

  const handleHover = useCallback(
    debounce((event, elements) => {
      if (elements.length > 0) {
        setActiveIndex(elements[0].index);
      } else {
        setActiveIndex(null);
      }
    }, 100),
    []
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          autoSkip: true,
          maxTicksLimit: 12,
          callback: function(value, index, values) {
            const time = this.getLabelForValue(value);
            if (time.endsWith(':00') || time.endsWith(':30')) {
              return time;
            }
            return '';
          },
          color: '#666',
          font: {
            family: 'Axiforma', // Set font family for x-axis labels
            size: '12px'
          },
        },
        afterDraw: (axis) => {
          const ctx = axis.chart.ctx;
          ctx.save();
          ctx.strokeStyle = '#ddd';
          ctx.lineWidth = 1;
          axis.ticks.forEach((tick, index) => {
            if (tick.label) {
              const x = tick.x;
              const y = axis.bottom;
              ctx.beginPath();
              ctx.moveTo(x, y);
              ctx.lineTo(x, y + 5);
              ctx.stroke();
            }
          });
          ctx.restore();
        },
        
      },
      y: {
        beginAtZero: true,
        max: Math.max(...chartsData.map(data => getMaxStaff(data.chartData))),
        ticks: {
          stepSize: 1,
          color: '#666',
          font: {
            family: 'Axiforma', // Set font family for x-axis labels
            size: 13
          },
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.05)',
        },
        
      },
    },
    plugins: {
      legend: {
        display: false,
        font: {
          family: 'Axiforma', // Set font family for x-axis labels
        },
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        titleColor: '#333',
        bodyColor: '#666',
        borderColor: '#ddd',
        borderWidth: 1,
        // Set the font size
        titleFont: {
          size: 16, // Title font size
          weight: '500', // Title font weight
          family: 'Axiforma', // Set font family for x-axis labels
        },
        bodyFont: {
          size: 16, // Body font size
          family: 'Axiforma', // Set font family for x-axis labels
        },
        
        usePointStyle: true, // Enables use of the custom point style instead of the colored box
        callbacks: {
          // Customize the tooltip title
          title: function(tooltipItems) {
            // `tooltipItems` is an array; here we take the first item
            const item = tooltipItems[0];
            return `${item.label} - ${floatToTime(timeToFloat(item.label) + 0.25)}`;
          },
          // Customize the tooltip label (data display)
          label: function(tooltipItem) {
            const dataValue = tooltipItem.raw;
            return `Personal i barngrupp: ${dataValue}`; // Modify this as needed
          }
        }
  
      },
    },
    elements: {
      line: {
        tension: 0.1,
      },
      point: {
        radius: 0,
        hoverRadius: 6,
      },
    },
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      {chartsData.map((data, chartIndex) => {
        const chartData = {
          labels: data.chartData.map(item => item.time),
          datasets: [
            {
              label: 'Personal',
              data: data.chartData.map(item => item.personal),
              borderColor: function(context) {
                const chart = context.chart;
                const {ctx, chartArea} = chart;
                if (!chartArea) {
                  return null;
                }
                return getGradient(ctx, chartArea, 'line');
              },
              backgroundColor: function(context) {
                const chart = context.chart;
                const {ctx, chartArea} = chart;
                if (!chartArea) {
                  return null;
                }
                return getGradient(ctx, chartArea, 'fill');
              },
              fill: 'start',
              pointBackgroundColor: 'rgba(83, 149, 255, 0.3)',
              pointBorderColor: 'rgba(83, 149, 255, 1)',
              pointHoverBackgroundColor: 'rgba(83, 149, 255, 1)',
              pointHoverBorderColor: '#fff',
            },
          ],
        };

        const getGradient = (ctx, chartArea, type) => {
          const gradientBg = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
          if (type === 'line') {
            gradientBg.addColorStop(1, 'rgba(83, 149, 255, 1)');
            gradientBg.addColorStop(0, 'rgba(199, 166, 228, 1)');
            // gradientBg.addColorStop(0, 'rgba(255, 201, 222, 1)');
          } else {
            gradientBg.addColorStop(0, 'rgba(164, 199, 255, 0.05)');
            gradientBg.addColorStop(1, 'rgba(164, 199, 255, 0.7)');
          }
          return gradientBg;
        };

        const plugins = [{
          afterDraw: (chart) => {
            const ctx = chart.ctx;
            const xAxis = chart.scales.x;
            const yAxis = chart.scales.y;
        
            ctx.save();
            ctx.strokeStyle = 'rgba(0, 0, 0, 0.1)'; // Adjust the color and opacity of the vertical lines
            ctx.lineWidth = 1;
        
            // Draw vertical lines at each tick where a label is displayed
            xAxis.ticks.forEach((tick, index) => {
              const x = xAxis.getPixelForTick(index);
              if (tick.label) { // Only draw line if there is a label
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom + 5);
                ctx.stroke();
              }
            });
        
            ctx.restore();
          }
        }];
        

        return (
          <div key={chartIndex} style={{ width, height, margin: '0px 0', padding: '10px', }}>
            {/* boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' */}
            {/* <h2 style={{ textAlign: 'center', marginBottom: '0px', fontSize: '20px', color: '#333' }}>
              Personal i barngrupp på {data.title}
            </h2> */}
            {
              displayName && <div style={{display:'flex', justifyContent:'center'}}>
              <p>{data.title}</p>
            </div>
            }
            
            <Line
              ref={chartRef}
              options={{
                ...options,
                onHover: handleHover,
              }}
              data={chartData}
              plugins={plugins}
            />
          </div>
        );
      })}
    </div>
  );

  
  // return (
  //   <div style={{  }}>
  //     {chartsData.map((data, chartIndex) => (
  //       <div key={chartIndex} style={{ width, height, margin: '0px', padding:'0px' }}>
  //         <ResponsiveContainer width="100%" height="100%" margin='0' padding='0'>
  //           <BarChart
  //             data={data.chartData}
  //             onMouseMove={handleMouseMove}
  //             onMouseLeave={handleMouseLeave}
  //             syncId="anyId"
  //             style={{backgroundColor: '#fff', borderRadius: '15px'}}
  //             margin={{left:0}}
  //             padding={{left:0}}
  //           >
  //             <CartesianGrid strokeDasharray="3 3" />
  //             <XAxis dataKey="time" />
  //             <YAxis domain={[0, getMaxStaff(data.chartData)]} allowDecimals={false} />
  //             {/* <Tooltip /> */}
  //             <Tooltip
  //             contentStyle={{ backgroundColor: '#fff', border: '1px solid #ccc', color: '#000' }}
  //             itemStyle={{ color: '#000' }} // Ensures that the tooltip text remains black
  //             labelStyle={{ color: '#000' }}
  //           />
  //             <Bar dataKey="personal" fill={'#afc9fa'} />  {/* data.color */}
  //             <text x="50%" y="20" textAnchor="middle" dominantBaseline="central">
  //               {/* <tspan fontSize="20">{data.title}</tspan> */}
  //               <tspan fontSize="20">Personal i barngrupp på {data.title}</tspan>
  //             </text>
  //           </BarChart>
  //         </ResponsiveContainer>
  //       </div>
  //     ))}
  //   </div>
  // );
};

export default React.memo(SynchronizedBarCharts);

// Parent component that uses SynchronizedBarCharts
export const Dashboard = () => {
  const chartsData = [
    {
      title: "Revenue",
      color: "#8884d8",
      chartData: [
        { time: "2021", value: 4000 },
        { time: "2022", value: 3000 },
        { time: "2023", value: 2000 },
        { time: "2023", value: 2000 },
        { time: "2023", value: 2000 },
        { time: "2023", value: 2000 },
        { time: "2023", value: 2000 },
        { time: "2023", value: 2000 },
        { time: "2023", value: 2000 },
        { time: "2023", value: 2000 },
      ]
    },
    {
      title: "Profit",
      color: "#82ca9d",
      chartData: [
        { time: "2021", value: 2400 },
        { time: "2022", value: 1398 },
        { time: "2023", value: 9800 },
        { time: "2023", value: 9800 },
        { time: "2023", value: 9800 },
        { time: "2023", value: 9800 },
        { time: "2023", value: 9800 },
        { time: "2023", value: 9800 },
        { time: "2023", value: 9800 },
      ]
    },
    {
      title: "Customers",
      color: "#ffc658",
      chartData: [
        { time: "2021", value: 2400 },
        { time: "2022", value: 3398 },
        { time: "2022", value: 3398 },
        { time: "2022", value: 3398 },
        { time: "2022", value: 3398 },
        { time: "2022", value: 3398 },
        { time: "2022", value: 3398 },
        { time: "2022", value: 3398 },
        { time: "2023", value: 4800 },
      ]
    },
  ];

  return (
    <div>
      <h1>Company Performance Dashboard</h1>
      <SynchronizedBarCharts chartsData={chartsData} />
    </div>
  );
};
