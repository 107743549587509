import React, { useState, useRef, useEffect } from 'react';
import { useAppContext, initialStatee } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';
import selectStyles from '../styles/StyledSelect.module.css';
import { log } from '../utils/Utils';
import { CompressState, DecompressState } from '../utils/Compressor';
// import { encode, decode } from '@msgpack/msgpack';
// import pako from 'pako';
import CryptoJS from 'crypto-js';
import { shortenContext } from '../utils/Compressor';
// import * as msgpack from 'msgpack-lite';
// import lzma from 'lzma/js';
// var my_lzma = require("lzma");
// var my_lzma = require("lzma").LZMA();
// import { compress, decompress } from 'compress-json'

import * as msgpack from 'msgpack-lite';
import pako from 'pako';
import { encode as base64Encode, decode as base64Decode } from 'base64-arraybuffer';

export const compressAndEncodeState = (state) => {
  // const shortened = JSON.stringify(shortenContext(state))
  // const encoded = new TextEncoder().encode(JSON.stringify(state));
  const encoded = new TextEncoder().encode(JSON.stringify(CompressState(state)));
  const compressed = pako.deflate(encoded);
  const base64 = btoa(String.fromCharCode.apply(null, compressed));
  navigator.clipboard.writeText(base64)
  // navigator.clipboard.writeText(JSON.stringify(state))
  // navigator.clipboard.writeText(JSON.stringify(CompressState(state)))
  return base64
}

const SaveForm = () => {
  const { state, dispatch } = useAppContext();
  const [fileName, setFileName] = useState('');
  const inputRef = useRef(null);

  const compressState = (stateToCompress) => {
    const serialized = msgpack.encode(stateToCompress);
    const compressed = pako.deflate(serialized);
    return base64Encode(compressed);
  };
  // var compressJSON = require('compress-json')

  const decompressState = (compressedState) => {
    const compressed = base64Decode(compressedState);
    const decompressed = pako.inflate(compressed);
    return msgpack.decode(decompressed);
  };

  const handleCompress = () => {
    navigator.clipboard.writeText(compressAndEncodeState(state))
  }

  const handleDecode = () => {
    const compressed = Uint8Array.from(atob(fileName), c => c.charCodeAt(0));
    const decompressed = pako.inflate(compressed);
    const decoded = new TextDecoder().decode(decompressed);
    const dejsoned = JSON.parse(decoded);
    // navigator.clipboard.writeText(dejsoned)

    const RestructuredState = DecompressState(dejsoned)
    dispatch({ type: 'SET_STATE', payload: {
      state: RestructuredState
    } })

  }


  const handleDeleteData = () => {

  }

  return (
    <div>
      <p style={{fontSize:'24px'}}>Spara schemat som text eller ladda upp sparad data</p>
      <br></br>
      <p>Om du vill spara det du jobbat på och fortsätta på det senare kan du göra följande:</p>
      <br/>
      <p>1. Tryck nedan för att få all data i form av en komprimerad text sparad i urklipp. 
      </p>
      <button style= {{marginBottom:'20px', marginLeft:'0px', marginTop:'10px'}}className={buttonStyles['button-30']} onClick={() => handleCompress()
}>Kopiera data till urklipp</button>
      <p>
      2. Spara sedan texten på valfri plats, exempelvis i en textfil på datorn, på Google Drive eller i ett mejlutkast till någon. <br/>
      3. När du vill fortsätta från vart du var så tar du hela denna text och klistrar in i rutan nedan och trycker på knappen. Datan laddas då in i systemet.</p>
      
      {/* <p>Klistra in data nedan för att fortsätta där du var</p> */}
      <div style={{marginBottom:'30px', marginTop:'20px', display:'flex', alignItems:'center'}}>
        <input
          ref={inputRef}
          className={inputStyles['input-30']}
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
          placeholder="Klistra in data"
          style={{width:'300px', backgroundColor:'#e8e8e8'}}
        />
        <button className={buttonStyles['button-30']} onClick={() => handleDecode()
}>Ladda in data</button>

      </div>
      <p>Om något skulle gå fel med inmatningen av data så kan du manuellt rensa allt som finns sparat i systemet med knappen nedan.</p>
      <p>OBS! Detta går inte att ångra</p>
      <button style={{marginLeft:'0px', marginTop:'10px'}}className={buttonStyles['button-30']}  onClick={() => dispatch({ type: 'TOGGLE_SHOW_REMOVE_DATA', payload: {
      setToShow: true,
    } })}>Rensa all data</button>
    {/* <button style={{marginLeft:'0px', marginTop:'10px'}}className={buttonStyles['button-30']}  onClick={() => dispatch({ type: 'SET_STATE', payload: {
      state: initialStatee
    } })}>Rensa all data</button> */}
      
{/* 
      <p>Format</p>
      <div style={{marginBottom:'10px', display:'flex', alignItems:'center'}}>
        <select className={selectStyles['selector-30']}
          onChange={selectFormat}
          style={{marginRight:'10px', fontSize:'18px'}}>
          <option value="Tranås">Tranås kommun</option>
          <option value="Lomma">Lomma kommun</option>

        </select>
        <button className={buttonStyles['button-30']} onClick={exportData}>Exportera</button>
      </div> */}

    </div>
  )
}

export default SaveForm