import React from 'react';
import styles from './RoundedDiv.module.css';
import { log } from '../../../utils/Utils';

const RoundedDiv = ({ children, width, height, layer, minWidth, padding='15px', backgroundColor = '#fff', topLeftRound = true}) => {
  return (
    <>
      <div className={styles.roundedDiv} style={{ width, height, minWidth}}>
        <div className={`${styles.roundedBackground} ${styles[layer]}`} style={{ backgroundColor, borderRadius: (topLeftRound ? '15px' : '0px 15px 15px 15px') }}/>
          <div className={styles.content} style={{padding}}>
            {children}
          </div>
      </div>
    </>
  );
};

export default RoundedDiv;
