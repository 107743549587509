import React, { useState, useRef, useEffect } from 'react';
import styles from './DepartmentDropdown.module.css';
import { useAppContext } from '../../../../contexts/AppContext';
import { weekDays, log, odv, getUniquePeopleObjets } from '../../../../utils/Utils';


const DepartmentDropdown = ({onChange, initialSelectedPerson = null, showAllPeople = false}) => {
  const { state, dispatch } = useAppContext();
  const {additionalHours, objectId, people, days, weeks, selectedPerson, selectedDepartment, departments} = state
  const [selectedPersonName, setSelectedPersonName] = useState(initialSelectedPerson ||  departments[selectedDepartment]?.name)
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (person) => {
    setSelectedPersonName(person.name);
    // onChange(person.id);
    dispatch({
      type: 'SELECT_DEPARTMENT',
      payload: { departmentId: person.id},
      meta: { localOnly: true }
    })
    // setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    log("persond ropdown: initialSelectedPerson", initialSelectedPerson, selectedPersonName)

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, []);

  const relevantPeople = showAllPeople ? odv(getUniquePeopleObjets(people)) : odv(state.departments)

  return (
    <div className={styles.daySelector} ref={dropdownRef} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <button className={styles.selectorButton} onClick={() => setIsOpen(true)} >  
        <div style={{display:'flex', alignItems:'center', justifyContent:'center', paddingLeft:'5px', paddingRight:'5px'}}>

          <p style={{overflow:'hidden'}} className={styles.para}>{selectedPersonName || 'Välj typ'}</p>
          <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g id="_24x24_On_Light_Arrow-Bottom" data-name="24x24/On Light/Arrow-Bottom" transform="translate(0 24) rotate(-90)">
              <rect id="view-box" width="24" height="24" fill="none"/>
              <path id="Shape" d="M.22,10.22A.75.75,0,0,0,1.28,11.28l5-5a.75.75,0,0,0,0-1.061l-5-5A.75.75,0,0,0,.22,1.28l4.47,4.47Z" transform="translate(14.75 17.75) rotate(180)" fill="#141124"/>
            </g>
          </svg>
        </div>
      </button>
      {isOpen && (
        <ul className={styles.dropdown}>
          {relevantPeople.map((person) => (
            <li key={person.id} onClick={() => handleSelect(person)} style={{height:'100%'}}>
              <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', height:'100%', paddingLeft:'5px' }}>
              
                <div style={{display:'flex',alignItems:'center',}}>
                  {
                    person.id === selectedDepartment ? <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8Z" fill="rgb(83, 149, 255)"/>
                  </svg> : <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8Z" fill="rgba(83, 149, 255, 0)"/>
                  </svg>
                  }
                  
                  <p style={{overflow:'hidden'}}>{person.name}</p>
                </div>

                <div style={{display:'flex', height:'100%',}}>
                  <div className={styles.editButton} onClick={() => dispatch({ type: 'TOGGLE_SHOW_EDIT_DEPARTMENT_NAME', payload: {
                    setToShow: true,
                    departmentId: person.id,
                  } })}
                  title='Byt namn'>
                    <svg width="25px" height="18px" viewBox="0 0 24 24" id="_24x24_On_Light_Edit" data-name="24x24/On Light/Edit" xmlns="http://www.w3.org/2000/svg">
                      <rect id="view-box" width="24" height="24" fill="none"/>
                      <path id="Shape" d="M.75,17.5A.751.751,0,0,1,0,16.75V12.569a.755.755,0,0,1,.22-.53L11.461.8a2.72,2.72,0,0,1,3.848,0L16.7,2.191a2.72,2.72,0,0,1,0,3.848L5.462,17.28a.747.747,0,0,1-.531.22ZM1.5,12.879V16h3.12l7.91-7.91L9.41,4.97ZM13.591,7.03l2.051-2.051a1.223,1.223,0,0,0,0-1.727L14.249,1.858a1.222,1.222,0,0,0-1.727,0L10.47,3.91Z" transform="translate(3.25 3.25)" fill="#141124"/>
                    </svg>
                  </div>

                  <div className={styles.editButton} onClick={() => dispatch({ type: 'TOGGLE_SHOW_REMOVE_DEPARTMENT', payload: {
                    setToShow: true,
                    departmentId: person.id,
                  } })}
                  title='Ta bort'>
                    <svg width="25px" height="18px" viewBox="0 0 24 24" id="_24x24_On_Light_Media" data-name="24x24/On Light/Media" xmlns="http://www.w3.org/2000/svg">
                      <rect id="view-box" width="24" height="24" fill="none"/>
                      <path id="Shape" d="M4.466,19.5a2.737,2.737,0,0,1-2.718-2.332L.009,5.864A.75.75,0,0,1,.751,5h.3a2.444,2.444,0,0,1,.474-2.075,2.551,2.551,0,0,1,2.369-.87A2.416,2.416,0,0,1,5.6.382,2.6,2.6,0,0,1,7.961.945,2.561,2.561,0,0,1,10.5.076a2.281,2.281,0,0,1,1.724,1.762A2.513,2.513,0,0,1,14.4,3.5,2.63,2.63,0,0,1,14.468,5h.283a.75.75,0,0,1,.742.864l-1.74,11.3A2.735,2.735,0,0,1,11.034,19.5ZM11.034,18A1.243,1.243,0,0,0,12.27,16.94L13.876,6.5H11.012L10.489,18ZM8.988,18,9.51,6.5H5.989L6.512,18ZM3.23,16.94A1.244,1.244,0,0,0,4.466,18H5.01L4.488,6.5H1.625ZM12.905,5A1.465,1.465,0,0,0,13,4.04c-.207-.54-.606-.766-1.378-.725a.75.75,0,0,1-.789-.714c-.031-.661-.242-.956-.695-1.072s-.925.146-1.5.949a.751.751,0,0,1-1.174.057c-.55-.628-1.016-.823-1.47-.7-.438.117-.694.467-.788,1.212a.751.751,0,0,1-.975.618C3.466,3.417,3,3.51,2.661,3.9A1.221,1.221,0,0,0,2.578,5Z" transform="translate(4.25 2.251)" fill="#141124"/>
                    </svg>
                  </div>

                </div>

              </div>
            </li>
            
          ))}
          <li style={{}}>
            <div style={{display:'flex', alignItems:'center',  height:'40px', borderTop:'1px solid #ccc', paddingLeft:'10px' }} onClick={() => dispatch({ type: 'TOGGLE_ADD_DEPARTMENT', payload: {
          setToShow: true
        } })}>

              <p style={{marginRight:'5px'}}>Skapa ny</p>
              <svg width="24px" height="24px" viewBox="0 0 24 24" id="_24x24_On_Light_Plus" data-name="24x24/On Light/Plus" xmlns="http://www.w3.org/2000/svg">
                <rect id="view-box" width="24" height="24" fill="#141124" opacity="0"/>
                <path id="Shape" d="M5,10.75V6.5H.75A.75.75,0,0,1,.75,5H5V.75a.75.75,0,0,1,1.5,0V5h4.25a.75.75,0,1,1,0,1.5H6.5v4.25a.75.75,0,1,1-1.5,0Z" transform="translate(6 6)" fill="#141124"/>
              </svg>
            </div>
          </li>
        </ul>
      )}
    </div>
  );
};

export default DepartmentDropdown;