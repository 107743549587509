import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';

const HelpForm = () => {
  const { state, dispatch } = useAppContext();


  return (
    <div style={{maxWidth:'600px'}}>
      <p style={{fontSize:'24px'}}>Går det inte så bra?</p>
      <br></br>
      <p>Då ringer du till 070 483 15 92.</p>
      <br></br>

      <p>Om du inte får svar direkt så ringer du tills du får svar.</p>
      <br></br>

      <p>Föredrar du mejl så går det bra på kontakt@beundraab.se.</p>
    </div>
  )
}

export default HelpForm