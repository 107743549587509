import React, { useState } from 'react';
import { useWeekContext } from '../contexts/WeekContext';
import styles from '../styles/Dayselector.module.css';
import buttonStyles from '../styles/StyledButton.module.css';
import { odv, log } from '../utils/Utils';
import { useAppContext } from '../contexts/AppContext';
import ItemSelector from '../assets/assets/item selector/ItemSelector';

const DaySelector = () => {
  const { state, dispatch } = useAppContext();
  const { weeks, days, selectedWeekId, selectedDay } = state
  
  // const relevantDays = Object.values(days).filter(day => day.weekId === weeks[selectedWeekId].id)
  const [hoveredDay, setHoveredDay] = useState(null);

  const handleMouseEnter = (day) => {
    setHoveredDay(day);
  };

  const handleMouseLeave = () => {
    setHoveredDay(null);
  };

  function selectDay(weekDay) {
      // const buttonId = event.target.id;
      // const buttonId = event.target.value;
      dispatch({
          type: 'SELECT_DAY',
          payload: { day: weekDay}
        });
  }

  // return (
  //   <div style={{display:'flex', flexDirection:'row'}}>
  //     {/* {relevantDays && Object.values(relevantDays).map((day) => { */}
  //     {['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag'].map ( day => {
  //       const selected = selectedDay === day
  //       const isHovered = hoveredDay === day
  //       return (
  //         <div
  //           style={{backgroundColor: selected ? '#414141' : (isHovered ? '#b3b3b38f' : '#eee'),
  //             // border:'solid black',
  //             // borderLeft: '1.5px solid #414141',
  //             // borderTop: '1.5px solid #414141',
  //             // borderBottom: '1.5px solid #414141',
  //             // borderRight: `${day === 'Fredag' ? '1.5px solid #414141' : ''}`,
  //             // borderTop: week.weekNr === 1 ? '1.5px solid black' : '',
  //             // borderBottom: week.weekNr === state.numberOfWeeks ? '1.5px solid black' : '1px solid #fff',
  //             // borderRadius: week.weekNr === 1 ? '15px 15px 0 0' : week.weekNr === state.numberOfWeeks ? '0 0 15px 15px'  : '',
  //             height:'30px',
  //             cursor: 'pointer',
  //             display:'flex',
  //             justifyContent:'center',
  //             alignItems:'center',
  //             padding:'5px',
  //             paddingLeft:'7px',
  //             paddingRight:'7px',
  //             borderRadius: `${day === 'Måndag' ? '15px 0px 0px 15px' : (day === 'Fredag' ? '0px 15px 15px 0px' : '')}`
  //           }}
  //           onMouseEnter={() => handleMouseEnter(day)}
  //           onMouseLeave={handleMouseLeave}
  //           onClick={() => selectDay(day)}
  //           >
  //             <span style={{color:`${selected ? '#fff' : '#414141'}`, fontSize:'18px', fontWeight:'400'}}>{day}</span>
  //         </div>
  //       )})
  //     }      
  //   </div>
  // );
  return (
    <div className={styles.container}>
      {/* {['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag'].map((day) => {
        const isSelected = selectedDay === day;
        const dayClass = `${styles.dayButton} ${isSelected ? styles.selected : ''} ${
          day === 'Måndag' ? styles.monday : day === 'Fredag' ? styles.friday : ''
        }`;

        return (
          <div
            key={day}
            className={dayClass}
            onClick={() => selectDay(day)}
          >
            <span className={styles.dayText}>{day}</span>
          </div>
        );
      })} */}
      <ItemSelector selectedItem={state.selectedDay} items={['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag']} onItemSelect={selectDay} state={state}></ItemSelector>
    </div>
  );

};

export default DaySelector;
