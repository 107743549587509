import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';
import { odv, log } from '../utils/Utils';

const RemoveMeetingForm = ({id: meetingId}) => {
  const { state, dispatch } = useAppContext();
  const { meetings, days, weeks, people, selectedDay, removeMeetingId} = state
  const weekDaySettings = state.weekDaySettings || {
      'Måndag': true,
      'Tisdag': true,
      'Onsdag': true,
      'Torsdag': true,
      'Fredag': true,
  }
  const daySetting = weekDaySettings[selectedDay]


  const findAndRemoveMeetings = () => {
    log(1)
    if (daySetting) {
      const weeksArray = Array.from({ length: state.numberOfWeeks }, (_, index) => index + 1);
      log(2)
      const editedMeeting = meetings[removeMeetingId]

      odv(weeksArray).forEach(weekNr => {
        // Remove old meeting
        log(3, editedMeeting)

        const relevantOldMeeting = odv(meetings).find(
            meeting => meeting.weekNr === weekNr 
          && meeting.weekDay === editedMeeting.weekDay 
          && meeting.time === editedMeeting.time 
          && meeting.length === editedMeeting.length 
          && meeting.peopleIds === editedMeeting.peopleIds )

    log(4, relevantOldMeeting)

        if (relevantOldMeeting){
          dispatch({
            type: 'REMOVE_MEETING',
            payload: {
              meetingId: relevantOldMeeting.id}
          })
        }
        
      })
    } else {
      dispatch({ type: 'REMOVE_MEETING', payload: {
        meetingId: state.removeMeetingId,
      } });
    }
    

  }
  return (
    <div>
      <p style={{fontSize:'24px'}}>Ta bort möte?</p>
      <br></br>
      <div style={{display:'flex', justifyContent:'right'}}>

        <button className={buttonStyles['secondaryButton']} style={{marginLeft:'0px'}}onClick={() => dispatch({
                                  type: 'TOGGLE_SHOW_REMOVE_MEETING', payload: {
                                  setToShow: false,
                                  }
                                })}>
          Avbryt
        </button>
        <button 
          className={buttonStyles['button-30']}
          onClick={() => { findAndRemoveMeetings();
            
                                dispatch({
                                  type: 'TOGGLE_SHOW_REMOVE_MEETING', payload: {
                                  setToShow: false,
                                  }
                                });
                                dispatch({
                                  type: 'TOGGLE_SHOW_MEETINGAH_POPUP', payload: {
                                  setToShow: false,
                                  }
                                });
                                }}>
          Ta bort
        </button>
      </div>
      
    </div>
  )
}

export default RemoveMeetingForm