import React, { useState, useReducer, useEffect } from 'react';
import ExcelExport from '../excel/ExportComponent2';
import { useAppContext } from '../contexts/AppContext';
import { useLocation, useNavigate  } from 'react-router-dom';
import axios from 'axios';
import { DecompressAndDecodeState } from '../utils/Compressor';
import { calculateDayTotalHours, findPeopleWithSameName, floatToTime, log, odv, timeToFloat, weekDays } from '../utils/Utils';
import RoundedDiv from '../assets/assets/rounded div/RoundedDiv';
import Switch from '../assets/assets/settings icon/Switch';
import buttonStyles from '../styles/StyledButton.module.css';
import styles from './PublicizeApp.module.css'
import ModalLight from '../modal/ModalLight';
import { PersonSummary } from './PersonSummary';
import SendToEditorConfirm from './SendToEditorConfirm';

const getAndSetData = async (dispatch, token, setTitle, setDescription, setFormattedTime) => {
  try {
    const result = await axios.post('https://l36v26m343jznahufyhbddwwya0afpee.lambda-url.eu-north-1.on.aws/', {
      body:'body',
      action: 'get_data',
      token: token
    });
    const data = result.data['data']
    const title = result.data['title']
    const description = result.data['description']
    const createdAt = result.data['createdAt']
    setTitle(title)
    setDescription(description)
    setFormattedTime(createdAt)

    const date = createdAt ? new Date(parseFloat(createdAt) * 1000) : null
    const formattedDate = date ? date.toISOString().split('T')[0] : ''

    dispatch({ type: 'SET_STATE', payload: {
      state: DecompressAndDecodeState(data)
    } })

  } catch (err) {
    console.log("error is: ", err)
  }
}

export const PublicizeApp = ({token}) => {
  const { state, dispatch } = useAppContext();
  const { people, weeks, days, departments, selectedPerson, selectedWeekId, selectedWeekNr, workHours, breakHours, meetings, additionalHours,
    selectedDepartment, selectedDay, numberOfWeeks } = state

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const weeksArray = Array.from({ length: numberOfWeeks }, (_, index) => index + 1);

  const [showWH, setShowWH] = useState(true)
  const [showBH, setShowBH] = useState(true)
  const [showAH, setShowAH] = useState(true)
  const [showMeetings, setShowMeetings] = useState(true)

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [formattedTime, setFormattedTime] = useState('')
  
  const date = formattedTime ? new Date(parseFloat(formattedTime) * 1000) : null
  const formattedDate = date ? date.toISOString().split('T')[0] : ''

  const [showPersonId, setShowPersonId] = useState('')
  const [showPersonSummary, setShowPersonSummary] = useState(false)

  const [showSendToEditor, setShowSendToEditor] = useState(false)
  


  useEffect(() => {
    if (token) {
      console.log("TOKEN IS: ", token);

      // Load the schedule based on the token
      // fetchSchedule(token);
      console.log("fetching schedule... ")
      const fetchData = async () => {getAndSetData(dispatch, token, setTitle, setDescription, setFormattedTime)}
      fetchData()
      // log("getted and setted state: ", state)


      // Once the schedule is loaded, you can remove the token from the URL
      log("query params is: ", queryParams)
      // queryParams.delete('token');
      // navigate(`${location.pathname}`, { replace: true }); // This will remove the query parameter from the URL
      // console.log("succesesfully setted state i think, ", state, title, description)
    }
  }, [token]);

  const goToHome = () => {
    queryParams.delete('token');
    navigate(`${location.pathname}`, { replace: true }); // This will remove the query parameter from the URL
  }

  return (
    <div >
      {/* <ExcelExport></ExcelExport> */}

      <ModalLight isOpen={showPersonSummary} onClose={() => setShowPersonSummary(false)}>
        <PersonSummary personId={showPersonId}></PersonSummary>
      </ModalLight>
      <ModalLight isOpen={showSendToEditor} onClose={() => setShowSendToEditor(false)}>
        <SendToEditorConfirm closePopup={() => setShowSendToEditor(false)} goToHome={goToHome}/>
      </ModalLight>


      <div style={{padding:'20px'}}>
        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <p style={{fontSize:'30px', fontWeight:'400', textAlign:'center'}}>Beundra Förskola</p>
          
          <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
            <div style={{display:'flex', marginTop:'0px', gap:'5px'}}>
              <Switch checked={showWH} onChange={() => setShowWH(!showWH)}></Switch>
              <p>Visa arbetstid</p>
            </div>
            <div style={{display:'flex', marginTop:'0px', gap:'5px', marginLeft:'10px'}}>
              <Switch checked={showBH} onChange={() => setShowBH(!showBH)}></Switch>
              <p>Visa rast</p>
            </div>
            <div style={{display:'flex', marginTop:'0px', gap:'5px', marginLeft:'10px'}}>
              <Switch checked={showMeetings} onChange={() => setShowMeetings(!showMeetings)}></Switch>
              <p>Visa möten</p>
            </div>
            <div style={{display:'flex', marginTop:'0px', gap:'5px', marginLeft:'10px'}}>
              <Switch checked={showAH} onChange={() => setShowAH(!showAH)}></Switch>
              <p>Visa barnfri tid</p>
            </div>
            <button onClick={() => setShowSendToEditor(true)}
            // <button onClick={goToHome}
            className={buttonStyles['button-30']}>
              Redigera schema
            </button>
          </div>
          
          
        </div>
        <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
          <p style={{
              fontSize: '1.5em', 
          }}>
              {title ? title : <p>Schema</p>} 
          </p>
          <p>
            {description}
          </p>
          <p>
            Skapat {formattedDate}
          </p>
        </div>
        
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%'}}>
        {
          weeksArray.map(weekNr => {
            return (
              <div style={{backgroundColor:'#fff', borderRadius:'15px', padding:'15px', marginTop:'10px', width:'84vw'}}>
                <p style={{fontSize:'25px', color:'rgb(53, 109, 255)', fontWeight:'500'}}>Vecka {weekNr}</p>
                {
                  odv(departments).map(department => {
                    const relevantPeople = odv(people).filter(person => person.departmentId === department.id)
                    return (
                      <div style={{marginLeft:'10px', marginTop:'10px'}}>
                        <div style={{display:'flex'}}>
                        <p style={{
                            fontSize: '20px', 
                            fontWeight: '500', 
                            width: '120px', 
                            whiteSpace: 'nowrap', 
                            overflow: 'hidden', 
                            textOverflow: 'ellipsis'
                        }}>
                            {department.name}
                        </p>
                        <div style={{display:'flex', marginLeft:'10px'}}>
                          {
                            odv(weekDays).map(weekDay => {

                              return (
                                <div style={{width:'15vw', display:'flex', alignItems:'end', justifyContent:'left', borderLeft: '2px solid #e1e1e1', borderBottom: '2px solid #e1e1e1',boxSizing:'border-box', paddingLeft:'10px', paddingBottom:'2px'}}>
                                  {weekDay}
                                  </div>
                              )
                            })
                          }
                        </div>
                        </div>
                        {
                          relevantPeople.map(person => {
                            const relevantDays = odv(days).filter(day => weeks[day.weekId].personId === person.id && weeks[day.weekId].weekNr === weekNr)
                            return (
                              <div style={{display:'flex', marginLeft:'10px'}}>
                                <p style={{
                                  // fontSize: '20px', 
                                  // fontWeight: '500', 
                                  width: '120px', 
                                  whiteSpace: 'nowrap', 
                                  overflow: 'hidden', 
                                  textOverflow: 'ellipsis'
                                }}
                                className={styles.personContainer}
                                onClick={() => {setShowPersonId(person.id);
                                  setShowPersonSummary(true)
                                }}>
                                    {person.name}
                                </p>
                                <div className={styles.weekContainer}>
                                  {
                                    odv(weekDays).map(weekDay => {
                                      const day = relevantDays.find(day => day.weekDay === weekDay)

                                      if (day){ // Failsafe that ideally should not be needed..
                                        const workHourStart = odv(workHours).find(wH => wH.dayId === day.id && wH.type === 'start')
                                        const workHourEnd = odv(workHours).find(wH => wH.dayId === day.id && wH.type === 'end')
  
                                        let displayText = ''

                                        let whText = ''
  
                                        if (workHourStart?.time != null && workHourEnd?.time != null) {
                                          whText = `${workHourStart.time}-${workHourEnd.time}`
                                        }

                                        if (showWH){
                                          displayText = whText
                                        }
  
                                        const breakHourStart = odv(breakHours).find(wH => wH.dayId === day.id && wH.type === 'start')
                                        const breakHourLength = odv(breakHours).find(wH => wH.dayId === day.id && wH.type === 'length')
  
                                        let bHText = ''
                                        let bhLengthText = ''
  
                                        if (breakHourLength?.time && timeToFloat(breakHourLength?.time) == 0.5){
                                          bhLengthText = '30m'
                                        } else if (breakHourLength?.time) {
                                          bhLengthText = `${timeToFloat(breakHourLength.time)}h`
                                        }
  
                                        if (breakHourStart?.time != null && breakHourLength?.time != null) {
                                          bHText = `Rast ${breakHourStart.time} (${bhLengthText})`
                                        } else if (breakHourLength?.time != null) {
                                          bHText = `(${bhLengthText} rast)`
                                        }

                                        if (showBH){
                                          if (displayText){
                                            if (bHText){
                                              displayText = `${displayText}, ${bHText}`
                                            }
                                          } else {
                                            displayText = bHText
                                          }
                                        }

                                        let aHText = ''
                                        
                                        
  
                                        const peopleObjectsWithSameName = findPeopleWithSameName(state, person)
  

                                        const relevantMeetings = []
                                        let meetingText = ''

                                        odv(peopleObjectsWithSameName).forEach(pO => {
                                          // const relevantDay = odv(days).find(day => day.weekDay === weekDay && weeks[day.weekId].weekNr === weekNr && weeks[day.weekId].personId === pO.id)
                                          relevantMeetings.push(...odv(meetings).filter(meeting => meeting.weekDay === weekDay && meeting.weekNr === weekNr && meeting.peopleIds.includes(pO.id)
                                          ))
                                        })

                                        relevantMeetings.forEach(meeting => {
                                          meetingText = `${meetingText} Möte ${meeting.time} - ${floatToTime(timeToFloat(meeting.time)+timeToFloat(meeting.length))}`
                                        })

                                        if (showMeetings){
                                          if (displayText){
                                            if (meetingText){
                                              displayText = `${displayText}, ${meetingText}`
                                            }
                                          } else {
                                            displayText = meetingText
                                          }
                                        }

                                        const relevantAdditionalHours = []
  
                                        odv(peopleObjectsWithSameName).forEach(pO => {
                                          const relevantDay = odv(days).find(day => day.weekDay === weekDay && weeks[day.weekId].weekNr === weekNr && weeks[day.weekId].personId === pO.id)
                                          relevantAdditionalHours.push(...odv(additionalHours).filter(aH => aH.dayId === relevantDay.id))
                                        })
                                        
                                        
                                        relevantAdditionalHours.forEach(aH => {
                                          aHText = `${aHText} ${aH.category} ${aH.start} - ${floatToTime(timeToFloat(aH.start)+timeToFloat(aH.length))}`
                                        })

                                        if (showAH){
                                          if (displayText){
                                            if (aHText){
                                              displayText = `${displayText}, ${aHText}`
                                            }
                                          } else {
                                            displayText = aHText
                                          }
                                        }

                                        

                                        return (
                                          <div style={{width:'15vw', border:'1px solid #e1e1e1', boxSizing:'border-box', minHeight:'25px'}}
                                          className={styles.dayContainer}
                                          onClick={() => {setShowPersonId(person.id);
                                            setShowPersonSummary(true)
                                          }}>
                                            <p style={{marginLeft:'5px', color:'#6b6b6b', fontSize:'1em'}}>
                                            {displayText}
                                            {/* {showWH && whText}{showWH && (showBH || showMeetings || showAH) && ', '} */}
                                            {/* {showWH && whText}{showWH && showBH && ', '}{showBH && bHText}{showMeetings && (showBH || showWH) && ', '}{showMeetingsAndAH && meetingAndAHText} */}
                                            </p>
                                          </div>
                                        )
                                      }
                                      
                                    })
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
        </div>

      </div>



      
    </div>
  )
}